import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy = localStorage.getItem('userId');
const updatedBy = localStorage.getItem('userId');
const mailRoomId = localStorage.getItem('mailRoomId');
const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const userFullName = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).firstName +' ' + JSON.parse(localStorage.userDetails).lastName : ""
const config = {
  headers: {
    'Authorization': token,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  }
};

const getLogById = async (id:Number) => {
  return await axios.get(API_URL + "inwardlog/" + id, config).then(
    (response) => {
      return response.data.message;
    }
  )
}

const inwardlogCreate = async (
  modeName: Number,
  inwardDate: string,
  inwardTime: string,
  frombranch: string,
  frombranchId: number,
  serviceProvider: number,
  noOfShipment: string,
  deliveryStaff: string,
  logRemarks: string,
  menifestNumber :string,
  selectedRecordList :any
) => {

  const bodyParamenters = {
    modeName, inwardDate,
    inwardTime,
    frombranch,
    frombranchId,
    serviceProvider,
    noOfShipment,
    deliveryStaff,
    logRemarks,
    tCode: Date.now().toString(),
    inwardlogStatus: 1,
    menifestNumber,
    outwardIds : selectedRecordList,
    mailRoomId,
    branch,
    createdBy
  }
  console.log("bodyParamenters", bodyParamenters)
  return await axios.post(API_URL + "inwardlog/register", bodyParamenters, config)
    .then((response) => {
      return response.data.message;
    }).catch((error) => {
      console.log(error)
    });
}
const inwardlogUpdate = async (
  modeName: Number,
  inwardDate: string,
  inwardTime: string,
  frombranch:string,
  frombranchId:number,
  serviceProvider: number,
  noOfShipment: string,
  deliveryStaff: string,
  logRemarks: string,
  menifestNumber :string,
  id : Number
) => {
  return await axios.put(API_URL + "inwardlog/"+id, {
    modeName, inwardDate,
    inwardTime,
    frombranch,
    frombranchId,
    serviceProvider,
    menifestNumber,
    noOfShipment,
    deliveryStaff,
    logRemarks,
    tCode: Date.now().toString(),
    inwardlogStatus: 1,
    updatedBy
  }, config)
    .then((response) => {
      return response.data.message;
    }).catch((error) => {
      console.log(error)
    });
}


const getInwardLogListdateWise = async (logDateSearch :string) => {
  const bodyParamter = {
    mailRoomId,
    branch,
    inwardDate : logDateSearch
  };
  return await axios.post(API_URL + "inwardlog/dateWise", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  ).catch(error => console.log(error))
}
const getInwardLogPendingList = async () => {
  const bodyParamter = {
    mailRoomId,
    branch
  };
  return await axios.post(API_URL + "inwardlog/pendinginwarddeliveries", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {       
        return response.data.message
      }
    }
  ).catch(error => console.log(error))
}
const getInwardLogList = async () => {
  const bodyParamter = {
    mailRoomId,
    branch
  };
  return await axios.post(API_URL + "inwardlog/", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {      
        return response.data.message
      }
    }
  ).catch(error => console.log(error))
}
// change by nk
const getDrs = async (drsNumber: any) => {
  return axios.get(API_URL + "drs/", config).then(
    (response) => {
      return response.data.message

    }
  )
    .catch(error => console.log(error))

}
const getDrsId = async (id: any) => {

  return axios.get(API_URL + "/drs/" + id, config).then(
    (response) => {
      //  console.log( response.data.message)
      return response.data.message

    }
  )
    .catch(error => console.log(error))

}
// post inward status



const postupdateinwarddetailstatus = async (inwarddetailid:any,
  inwarddetailStatus: any,
  receivedBy: String,
  remark: String,
  inwardDate: any, inwardTime: any, deliverySign: any, ReasonId : Number, ReasonLabel : String, drsId : Number,
  redirectempId:Number,redirectempName:String) => {
  const bodyParamter = {
    drsId : drsId,
    id:inwarddetailid,
    inwarddetailStatus: inwarddetailStatus,
    receivedBy: receivedBy,
    reasonId : ReasonId,
    remark: remark,
    deliveryBy :  userFullName,
    deliveryDateTime: inwardDate+' '+inwardTime,
    missRouteReason : ReasonLabel,
    updatedBy ,
    createdBy,  
    mailRoomId, branch,  
    deliverySign: deliverySign,
    redirectempId,
    redirectempName
  };
  console.log("bodyParamter", bodyParamter)
  return axios
    .post(API_URL + "inwarddetail/updatemultipleinwardstatus/", bodyParamter, config).then(
      (response) => {
    if(response.data.statuscode ==='200'){
        return response.data.message;
      }
      })
    .catch(error => console.log(error));

}
const PostInwardMail = async (title: any,
  signature: any,
  receiver: any,
  subject: any,
  content: any,
  to: any,
  fromDate: any,
  toDate: any,) => {
  const bodyParamter = {

    // "fromDate": "2023-06-23",
    // "toDate": "2023-06-29",
    // "to": "pratikvitkare007@gmail.com",
    title: title,
    // "branch": 2,
    // "receiver": "Sanyog Softwares",
    // "subject": "Daily MIS Report Test Mail",
    // "content": "Attaching herewith excel file of auto generated mis report for branch no. 2.",
    // "signature": "Regards,\nSanyog Softwares,\nPune - 412015.",



    fromDate: fromDate,
    toDate: toDate,
    to: to,

    // branch: branch,
    receiver: receiver,
    subject: subject,
    content: content,
    signature: signature


  };
  return axios
    .post(API_URL + "/misreport/getreportbymail", bodyParamter, config).then(
      (response) => {

        return response.data;
      })
    .catch(error => console.log(error));

}

const deleteinwardLog = (id:Number) => {
  return axios.delete(API_URL + "inwardlog/"+id,  config).then(
    (response) => {
        return response.data.message
    })
    .catch(error => console.log(error))

}

const generateinwardreport = async (modeId :number, fromDate:any, toDate:any, serviceProviderId :number,EmployeeId:number,RouteId:number,DepartmentId:number,ReportTypeId:number) => {
 
  const bodyParamenters = {
    modeId, 
    fromDate,
    toDate,
    serviceProviderId,
    EmployeeId,
    RouteId,
    DepartmentId,
    mailRoomId,
    branch,
   }
   var reportlink="";
    if(ReportTypeId === 1)
    {reportlink="inwardlog/generatereport/"}else if(ReportTypeId === 2)
    { reportlink="inwarddetail/generatereport/"}else if(ReportTypeId === 3)
    {reportlink="inwarddetail/generatedeliveryreport/"}else if(ReportTypeId === 4)
    { reportlink="inwarddetail/generatependingreport/"}
   return await axios.post(API_URL+reportlink, bodyParamenters, config).then(
     (data) => {
       if(data.data.statuscode === '200'){
       return data.data.message;
       }
     }
   ).catch(error =>console.log(error))
 }

 const getmenifestDetails = (manifestNumber : any, fromBranch : number) =>{
  const bodyParamter = {
    mailRoomId,
    branch :fromBranch, 
    manifestNumber
  }
  return axios
  .post(API_URL + "manifest/getmanifestbynumber", bodyParamter, config).then(
    (response) => {
      return response.data.message;
    })
  .catch(error => console.log(error));
 }

const InwardLogService = {
  getLogById,
  inwardlogCreate,
  inwardlogUpdate,
  deleteinwardLog,
  getInwardLogList,
  getInwardLogPendingList,
  getInwardLogListdateWise,
  getDrs,
  getDrsId,
  postupdateinwarddetailstatus,
  PostInwardMail,
generateinwardreport,
getmenifestDetails
}

export default InwardLogService;