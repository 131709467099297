import React, { useEffect, useState } from 'react'
import MasterDataService from '../../services/masterData.service'
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment';
import InwardLogService from '../../services/inwardlog.service';
import InwardContainerHeader from './InwardContainerHeader';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';

function InwardDrslist() {


  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [drsDate, setdrsDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allDrsList, setallDrsList] = useState([]);
  const [drsNumber, setdrsNumber] = useState("");
  const dateformater = (data: any, row: any) => {
    return moment(data).format('DD/MM/YYYY')
  }
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
      setdrsNumber(row.drsNumber)
    }
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'id',  align: 'center',sort: true},
    { dataField: 'drsDate', text: 'DRS Date', sort: true,
    //  formatter: dateformater
    },
    { dataField: 'drsNumber', text: 'DRS Number', sort: true },
    { dataField: 'deliveryStaff', text: 'Delivery Staff' },
    { dataField: 'totalQuantity', text: 'Quantity.' , align: 'center'},
    { dataField: 'atDeskCount', text: 'At Desk' , align: 'center' },
    { dataField: 'deliveredCount', text: 'Delivered' , align: 'center' },
    { dataField: 'undeliveredCount', text: 'Undeliverd' , align: 'center' },    
    { dataField: 'unclaimedCount', text: 'Unclaimed' , align: 'center' },
    { dataField: 'reassignedCount', text: 'Reassigned' , align: 'center' },
    { dataField: 'redirectedCount', text: 'Redirected'  , align: 'center'},
    { dataField: 'routeName', text: 'Route Name' }


  ];
  //Table End
  const getInwardDrsList = async () => {
    await InwardDeliverySheet.getDrsList(drsDate).then(
      (data) => {
        if (data) {
          console.log(data)
          setallDrsList(data);
        }
      }
    );
  }
  useEffect(() => {
    getInwardDrsList();
  }, [drsDate])
  useEffect(() => {
    setdrsDate((moment(new Date())).format('YYYY-MM-DD'))
  }, [])

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (selectedRecords !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          InwardDeliverySheet.deleteDrs(selectedRecords)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Inward DRS has been deleted",
          }).then(function () {
            window.location.reload()
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Inward DRS is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
    }
  }

  const navigate = useNavigate();
  const EditDelivery = () => {
    if (selectedRecords !== undefined) {
      navigate('../inwarddelivarysheet', { state: selectedRecords });
    }
  }

  const filteredData = Array.isArray(allDrsList)
    ? allDrsList.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];
  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Inward DRS List </p></div>

            <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }}>

              <div className='margincard1itams' style={{ marginLeft: "10px" }}>
                <label> DRS Date</label>
                <input className='margininutleft' type='Date' value={drsDate} max={moment().format("YYYY-MM-DD")} name="drsDate" onChange={(e) => { setdrsDate(e.target.value) }} />
              </div>
              <div className='margincard1itams'>
                <label >Branch Name :  {(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branchName : ""} </label>
              </div>
              <div className='col-6' style={{ display: "flex", height: "50px", justifyContent: "end" }}>
                <input
                  className='searchbar col-6'
                  type='text'
                  value={searchQuery}
                  placeholder='Search Here ...'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className='table-responsive tableContainer'>
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              // pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
</div>
          </div>
          <div className='row col-12 d-flex justify-content-center'>
            <Link to="../inwarddelivarysheet" className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
            <button className="btn2 col-2 col-sm-1" onClick={EditDelivery}><i className="fa fa-edit"></i> Edit</button>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} ><i className="fa fa-trash"></i> Delete</button>
            <a className="btn2 col-2 col-sm-1"  href={(drsNumber !=="") ? process.env.REACT_APP_BASE_UPLOAD_API+"drspdf/"+drsNumber+".pdf" :"#"}
        download={drsNumber}
        target="_blank"
        rel="noreferrer"><i className="fa fa-print"></i> Print</a>
            <button className="btn2 col-2 col-sm-1" > <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>

          </div>
        </div>
      </div>
    </>
  );
}

export default InwardDrslist