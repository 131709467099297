import { useEffect, useState } from 'react'
import Inwarddetailsercice from '../../services/inwarddetail.service'; 
import { useParams } from 'react-router-dom';  
import moment from 'moment';
import Headermaillink from '../headerfooter/Headermaillink';

function Mailfeedback() {
  const { param1, param2 } = useParams(); 
  const [allInwardDetails, setAllInwardDetails] = useState([]);
  const API_URL = process.env.REACT_APP_BASE_URL_API;

  const getInwardDetailList = async () => {
    await Inwarddetailsercice.getmailfeedbackdata(param1,param2).then(
      (data) => {
        if (data) {
          setAllInwardDetails(data);
        }
      }
    );
  }

 
  useEffect(() => {   
    console.log("paran 1:"+param1);
    console.log("paran 2:"+param2);
    getInwardDetailList();
  }, [])
   
 
  return (
    <>
   {localStorage.getItem('userDetails') ?  ''  : <Headermaillink />}  
     
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
        <form action={API_URL+"inwarddetail/updateinwardbymail"} method="POST">
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
           <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Update Status of Items Received</p></div>
                <table style={{border: "collapse",width: "100%"}}>
                  <thead>
                  <tr className='themeColor' style={{border: "1px solid #dddddd",padding: "8px"}}>
                  <th>Inward Date</th>
                  <th>Inward Time</th>
                  <th>AWB</th>
                  <th>Consigner</th>
                  <th>Quantity</th>
                  <th>Mail Content</th>
                  <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {allInwardDetails.map((row:any) => (
                  <tr key={row.id} >
                  <th>{(moment(row.inwardDate)).format('DD-MM-YYYY')}</th>
                  <th>{row.inwardTime }</th>
                  <th>{row.AWB}</th>
                  <th>{row.consignerName}</th>
                  <th>{row.inwardQuantity}</th>
                  <th>{row.mailContent}</th>
                  <th><input type="radio" name={row.id} value="8"/>&nbsp;Received&nbsp;&nbsp;
                      <input type="radio" name={row.id} value="91"/>&nbsp;Reject
                  </th>
                  </tr>
                  ))}
                  </tbody>
                 </table>
           </div>
          <div className='row col-12 d-flex justify-content-center'>
               <input type="submit" value="SUBMIT" className="btn2 col-2 col-sm-1"/>
          </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Mailfeedback