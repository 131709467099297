import { ChangeEvent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import MailroomService from '../../services/mailroom.service';
import SettingHeader from './settingHeader';
import Swal from 'sweetalert2';
const UPLOAD_URL = process.env.REACT_APP_BASE_UPLOAD_API;



const Primarysettings = () => {
    const [IntimationMail, setIntimationMail] = useState(0);
    const [intimationtoConsignor, setintimationtoConsignor] = useState(0);
    const [intimationtoConsignee, setintimationtoConsignee] = useState(0);

    const [DrsMail, setDrsMail] = useState(0);
    const [DrsMailtoemployee, setDrsMailtoemployee] = useState(0);
    const [DrsMailtodeliverystaff, setDrsMailtodeliverystaff] = useState(0);

    const [UpdationMail, setUpdationMail] = useState(0);
    const [UpdationMailtoconsignor, setUpdationMailtoconsignor] = useState(0);
    const [UpdationMailtoconsignee, setUpdationMailtoconsignee] = useState(0);

    const [outIntimationMail, setoutIntimationMail] = useState(0);
    const [outintimationtoConsignor, setoutintimationtoConsignor] = useState(0);
    const [outintimationtoConsignee, setoutintimationtoConsignee] = useState(0);

    const [ManifestMail, setManifestMail] = useState(0);
    const [ManifestMailtoemployee, setManifestMailtoemployee] = useState(0);
    const [ManifestMailtodeliverystaff, setManifestMailtodeliverystaff] = useState(0);

    const [outUpdationMail, setoutUpdationMail] = useState(0);
    const [outUpdationMailtoconsignor, setoutUpdationMailtoconsignor] = useState(0);
    const [outUpdationMailtoconsignee, setoutUpdationMailtoconsignee] = useState(0);

    const [ratechartbymailroom, setratechartbymailroom] = useState(0);
    const [ratechartnybranch, setratechartnybranch] = useState(0);

    const [selectedratechart, setselectedratechart] = useState(0);

    const [sendmailsfromapp, setsendmailsfromapp] = useState(0);
    



    const navigate = useNavigate();

    const handleIntimationMailChange = () => {
        setIntimationMail((prevSendMail) => (prevSendMail === 0 ? 1 : 0));
      };

    const handleDrsMailChange = () => {
        setDrsMail((prevSendMail) => (prevSendMail === 0 ? 1 : 0));
    };

    const handleUpdationMailChange = () => {
        setUpdationMail((prevSendMail) => (prevSendMail === 0 ? 1 : 0));
    };

    const handleoutIntimationMailChange = () => {
        setoutIntimationMail((prevSendMail) => (prevSendMail === 0 ? 1 : 0));
      };

    const handleManifestMailChange = () => {
        setManifestMail((prevSendMail) => (prevSendMail === 0 ? 1 : 0));
    };

    const handleoutUpdationMailChange = () => {
        setoutUpdationMail((prevSendMail) => (prevSendMail === 0 ? 1 : 0));
    };

    const handlesendmailsfromappChange = () => {
        setsendmailsfromapp((prevSendMail) => (prevSendMail === 0 ? 1 : 0));
    };

    const handleRatechartChange = (ratechart:any) => {
        setselectedratechart(ratechart);
      };

    const HandleSubmit=()=>{
        MailroomService.putprimarysettings(IntimationMail, intimationtoConsignor, intimationtoConsignee, DrsMail, DrsMailtoemployee, DrsMailtodeliverystaff,UpdationMail,UpdationMailtoconsignor,UpdationMailtoconsignee,outIntimationMail,
            outintimationtoConsignor,outintimationtoConsignee,ManifestMail,ManifestMailtoemployee,ManifestMailtodeliverystaff,outUpdationMail,outUpdationMailtoconsignor,outUpdationMailtoconsignee,selectedratechart).then(
            (data) => {
                if (data !="") {
                    Swal.fire({
                        title: "Success",
                        icon: 'success',
                        text: "Mailroom updated successfully",
                      }).then(function () {
                        navigate('../mailroom');
                      })
                    navigate('../mailroom');
                }
            }
        ).catch((error) => {
            console.log(error);
        })
    }

    useEffect(()=>{   
        handleGetSetting()
      },[])
    const handleGetSetting = () => {
     MailroomService.getuserDetails().then(async (data :any)=>{
        if(data){ 
            console.log(" data of users", data.intimationtoConsignor) 
            setIntimationMail(data.IntimationMail ?? 0);
            setDrsMail(data.DrsMail ?? 0);
            setUpdationMail(data.UpdationMail ?? 0);
            setoutIntimationMail(data.outIntimationMail ?? 0);
            setManifestMail(data.ManifestMail ?? 0);
            setoutUpdationMail(data.outUpdationMail ?? 0);
            setsendmailsfromapp(data.sendMailAlwaysOff ?? 0);
            setselectedratechart(data.selectedratechart ?? 0);
            setintimationtoConsignor(data.intimationtoConsignor ?? 0);
            setoutintimationtoConsignor(data.outintimationtoConsignor ?? 0);
            setoutintimationtoConsignee(data.outintimationtoConsignee ?? 0);
            setManifestMailtoemployee(data.ManifestMailtoemployee ?? 0);
            setManifestMailtodeliverystaff(data.ManifestMailtodeliverystaff ?? 0);
            setoutUpdationMailtoconsignor(data.outUpdationMailtoconsignor ?? 0);
            setoutUpdationMailtoconsignee(data.outUpdationMailtoconsignee ?? 0);

        }
     })   
    }
   
   
    
  
    return (
        <>
        <SettingHeader/>
            {/* < /> */}
            <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
                <div className='container col-11' style={{ paddingLeft: "0px" }}>
                    <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}>Primary Settings </p></div>
                    <div className='row inline'> 
                           <div className='col-12' style={{textAlign: 'center', paddingBottom : '10px'}}>
                            <h4 className='col-12'>Mail Settings</h4> 
                          <label className='col-12'>
                                    <input
                                    type="checkbox"
                                    checked={sendmailsfromapp  === 0}
                                    onChange={handlesendmailsfromappChange}
                                    />
                                   <span style={{fontWeight:'bold'}}> Allow to send mails from application</span>
                            </label>
                            </div> 
                        {sendmailsfromapp === 0 && (
                            <div className='col-12 row'>
                        <div className='col-6 pr-0'>
                           <div className='col-12'>
                            <h4 className='col-12' style={{paddingBottom : '10px'}}>Inward Email Settings</h4> 
                          <label className='col-12'>
                                    <input
                                    type="checkbox"
                                    checked={IntimationMail  === 1}
                                    onChange={handleIntimationMailChange}
                                    />
                                   <span style={{fontWeight:'bold'}}> Email Intimation after inward entry</span>
                            </label>
                            </div>
                            <div className='col-12'>
                            {IntimationMail === 1 && (
                                        <div>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={intimationtoConsignor  === 1}
                                            onChange={() => setintimationtoConsignor((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                           <span> Intimation to Consignor</span> 
                                        </label>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={intimationtoConsignee  === 1}
                                            onChange={() => setintimationtoConsignee((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                            <span> Intimation to Consignee</span>
                                        </label>
                                        </div>
                                    )}
                                    </div>

                                    <div className='col-12'>
                            <label className='col-12'>
                                    <input
                                    type="checkbox"
                                    checked={DrsMail === 1}
                                    onChange={handleDrsMailChange}
                                    />
                                   <span style={{fontWeight:'bold'}}> Email after DRS Generated</span> 
                            </label>
                            </div>
                            <div className='col-12'>
                            {DrsMail === 1 && (
                                        <div>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={DrsMailtodeliverystaff === 1}
                                            onChange={() => setDrsMailtodeliverystaff((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                            <span> Drs to Delivery Staff</span>
                                        </label>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={DrsMailtoemployee === 1}
                                            onChange={() => setDrsMailtoemployee((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                           <span> Email to employee</span> 
                                        </label>
                                        </div>
                                    )}
                                    </div>

                                    <div className='col-12'>
                            <label className='col-12'>
                                    <input
                                    type="checkbox"
                                    checked={UpdationMail === 1}
                                    onChange={handleUpdationMailChange}
                                    />
                                   <span style={{fontWeight:'bold'}}> Email after Status Updation</span> 
                            </label>
                            </div>
                            <div className='col-12'>
                            {UpdationMail === 1 && (
                                        <div>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={UpdationMailtoconsignor === 1}
                                            onChange={() => setUpdationMailtoconsignor((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                            <span> Updation Mail to consignor</span>
                                        </label>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={UpdationMailtoconsignee === 1}
                                            onChange={() => setUpdationMailtoconsignee((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                           <span> Updation Mail to consignee</span> 
                                        </label>
                                        </div>
                                    )}
                                    </div>
                        </div>

                        <div className='col-6 pr-0'>
                           <div className='col-12'>
                            <h4 className='col-12'  style={{paddingBottom : '10px'}}>Outward Email Settings</h4> 
                            <label className='col-12'>
                                    <input
                                    type="checkbox"
                                    checked={outIntimationMail === 1}
                                    onChange={handleoutIntimationMailChange}
                                    />
                                   <span style={{fontWeight:'bold'}}> Email Intimation after outward entry</span>
                            </label>
                            </div>
                            <div className='col-12'>
                            {outIntimationMail === 1 && (
                                        <div>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={outintimationtoConsignor === 1}
                                            onChange={() => setoutintimationtoConsignor((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                           <span> Intimation to Consignor</span> 
                                        </label>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={outintimationtoConsignee === 1}
                                            onChange={() => setoutintimationtoConsignee((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                            <span> Intimation to Consignee</span>
                                        </label>
                                        </div>
                                    )}
                                    </div>

                                    <div className='col-12'>
                            <label className='col-12'>
                                    <input
                                    type="checkbox"
                                    checked={ManifestMail === 1}
                                    onChange={handleManifestMailChange}
                                    />
                                   <span style={{fontWeight:'bold'}}> Email after Manifest Generated</span> 
                            </label>
                            </div>
                            <div className='col-12'>
                            {ManifestMail === 1 && (
                                        <div>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={ManifestMailtodeliverystaff === 1}
                                            onChange={() => setManifestMailtodeliverystaff((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                            <span> Manifest to Delivery Staff/Vendor/Branch</span>
                                        </label>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={ManifestMailtoemployee === 1}
                                            onChange={()=>setManifestMailtoemployee((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                           <span> Email to employee</span> 
                                        </label>
                                        </div>
                                    )}
                                    </div>

                                    <div className='col-12'>
                            <label className='col-12'>
                                    <input
                                    type="checkbox"
                                    checked={outUpdationMail === 1}
                                    onChange={handleoutUpdationMailChange}
                                    />
                                   <span style={{fontWeight:'bold'}}> Email after Status Updation</span> 
                            </label>
                            </div>
                            <div className='col-12'>
                            {outUpdationMail === 1 && (
                                        <div>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={outUpdationMailtoconsignor === 1}
                                            onChange={()=>setoutUpdationMailtoconsignor((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                            <span> Updation Mail to consignor</span>
                                        </label>
                                        <label className='col-5'>
                                            <input
                                            type="checkbox"
                                            checked={outUpdationMailtoconsignee === 1}
                                            onChange={()=>setoutUpdationMailtoconsignee((prevSendMail) => (prevSendMail === 0 ? 1 : 0))}
                                            />
                                           <span> Updation Mail to consignee</span> 
                                        </label>
                                        </div>
                                    )}
                                    </div>
                        </div>
                        </div>
                        )}
                  </div><br></br>
                    <div className='row inline'>
                    <div className='col-6 pr-0'>
                           <div className='col-12'>
                            <h4 className='col-12'>Account Settings</h4><br></br>
                          <label className='col-12'>
                                   <span style={{fontWeight:'bold'}}> Rate Contract</span>
                            </label>
                            </div>
                            <div className='col-12'>
                                   <div>
                                        <label className='col-5'>
                                        <input
              type="radio"
              name="ratechart"
              value="ratechartnybranch"
              checked={selectedratechart === 0}
              onChange={() => handleRatechartChange(0)}
            />
                                           
                                        <span> Different for all Branches</span> 
                                        </label>
                                        <label className='col-5'>
                                            
                                            <input
                                                type="radio"
                                                name="ratechart"
                                                value="ratechartbymailroom"
                                                checked={selectedratechart === 1}
                                                onChange={() => handleRatechartChange(1)}
                                                />
                                            <span> Same for all Branches</span>
                                        </label>
                                        </div>
                                  
                                    </div>
                    </div>
                    </div>
                </div>
                <div className='btn d-flex justify-content-right'>
                    <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""><i className="fa fa-save"></i> Save </Link>
               </div>
            </div>
        </>
    )
}

export default Primarysettings