import React, { useState } from 'react'
import Footer from '../headerfooter/Footer';
import '../Masters/master1.css'
import AccountHeader from './accountHeader';
function AccountHome() {
    return (
        <>
            <AccountHeader/>
            <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Account</p></div>
          </div>
            </div>
            <Footer />
        </>

    )
}

export default AccountHome