import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const Regulators = () => {

  const [rowData, setRowData] = useState([]);
  const [regulatorName, setregulatorName] = useState("");
  const [deliveryTime, setdeliveryTime] = useState("");

  const { state } = useLocation();



  const navigate = useNavigate();

  const HandleSubmit = () => {
    if(regulatorName !== ""){
    MasterDataService.PostRegulators(regulatorName, deliveryTime).then(
      (data) => {
        if (data.statuscode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Regulators Created Successfully",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              title: 'swal-fire-alert', // Add your custom CSS class here
            },
          }).then(
            ()=>{             
            navigate('/regulatorslist');
            }
          )
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  } else {
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: "Please Enter Regulators Name",
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    })
  }
  }
  // edit the selected data
  const HandleEdit = () => {
    MasterDataService.putRegulators(regulatorName, deliveryTime, state).then(
      (data) => {
        if (data.statuscode === 200) {
          navigate('./regulatorslist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  //  get Regulators by Id
  const getRegulators = async () => {
    try {
      const data = await MasterDataService.getRegulatoryId(state);
      if (data) {
        setregulatorName(data.regulatorName);
        setdeliveryTime(data.deliveryTime);
      } else {
        setregulatorName('');
        setdeliveryTime('');
        // Handle the case when data.message is undefined or null
      }
    } catch (error) {
      // Handle errors from the API call
      console.error(error);
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          MasterDataService.deleteRegulators(state).then(
            (data) => {
              if (data.statuscode === '200') {
                // navigate('/regulatorslist');                         
              }
            }
          ).catch((error) => {
            console.log(error);
            console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Regulators has been deleted",
          }).then(function () {
            navigate('/regulatorslist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Regulators is safe :)",
            }).then(function () {
              navigate('/regulatorslist');
            }
            )
        }
      })

    getRegulators();
  }

  const HandleCancel = () => {
    setregulatorName("");
    setdeliveryTime("");
  }
  useEffect(() => {
    if (state !== null) {
      getRegulators();
    }

  }, [])



  return (
    <>
      <MasterHeader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11'style={{paddingLeft:"0px"}} >
          <div className="themeColor width" >
            <p style={{ marginLeft: "10px" }}>Regulators </p></div>

          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b>Regulators</b></label>
            <input type='text' className='col-8 col-sm-4' value={regulatorName} name='regulatorName' onChange={e => setregulatorName(e.target.value)} />
          </div>

          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b> Route Time</b></label>
            <input type='time' className='col-8 col-sm-4' value={deliveryTime} name='deliveryTime' onChange={e => setdeliveryTime(e.target.value)} />
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to="../regulatorslist"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="../regulatorslist"> <i className="fa fa-edit"> </i>   Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i>   Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/regulatorslist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default Regulators
