import { useEffect, useState } from 'react'
import OutwardDetailService from '../../services/outwarddetail.sercice';
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment';
import OutwardContainerHeader from './Outcontainerheader';
import { read, utils } from 'xlsx';
import Select from 'react-select';
import MasterDataService from '../../services/masterData.service';
import AccountService from '../../services/accountData.service';


function OutwardDetailImport() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [selectedAWBNumber, setSelectedAWBNumber] = useState();
  const [inwardDateSearch, setinwardDateSearch] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allOutwardDetails, setAllOutwardDetails] = useState([]);

  const createdBy = localStorage.getItem('userId');
  const updatedBy = localStorage.getItem('userId');
  const mailRoomId = localStorage.getItem('mailRoomId');
  const [Todate, setTodate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [ModeList, setModeList] = useState([]);
  const [Mode, setMode] = useState('Select Mode');
  const [ModeId, setModeId] = useState(0);

  const [frombranch, setfrombranch] = useState('Select from Branch');
  const [frombranchId, setfrombranchId] = useState(0);
  const [fromBranchList, setfromBranchList] = useState([]);
  const [courierType, setcourierType] = useState(0);
  const [pincodeLable, setpincodeLable] = useState('PinCode');

  const [serviceProvider, setserviceProvider] = useState('Select Service Provider');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);


  const [product, setproduct] = useState(0);
  const [productName, setproductName] = useState('Select Product');
  const [allProduct, setallProduct] = useState([]);
const [vendorAccount, setVendoraccount] = useState('');
const [accountNo, setaccountNo] = useState(0);
const [allvendorAccount, setAllVendorAccount] = useState([])

const [outwardType, setoutwardType] = useState(0)

  const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
  const dateformater = (data: any, row: any) => {
    return (moment(data)).format('DD/MM/YYYY')
  }
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "none",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
      setSelectedAWBNumber(row.AWB);
    }
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };
  const timeFormatChange = (data: any, row: any) => {
    if (data.length > 8) {
      return (moment(data)).format('hh:mm:ss A')
    } else {
      return data;
    }
  }

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    {
      dataField: 'eid', formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      }, text: 'Sr. No.', align: 'center'
    },
    { dataField: 'AWB', text: 'AWB' },
    { dataField: 'employeeName', text: 'Employee Name' },
    { dataField: 'department', text: 'Department' },
    { dataField: 'costCenter', text: 'Cost Center' },
    { dataField: 'consigneeName', text: 'Consignee' },
    { dataField: 'consigneeEmailId', text: 'Consignee Emailid' },
    { dataField: 'consigneeCityName', text: 'To City' },
    { dataField: 'consigneeCountryCode', text: 'To Country' },
    { dataField: 'consigneeZipCode', text: 'To Pincode' },
    { dataField: 'numberOfPieces', text: 'No Pcs' },
    { dataField: 'remark', text: 'Remarks' },
    { dataField: 'RefNo', text: 'RefNo' },
    { dataField: 'weight', text: 'weight' },
    { dataField: 'volumetricWeight', text: 'Volume Weight' },
    { dataField: 'mailContent', text: 'Content' },


  ];
  //Table End  

  useEffect(() => {
    getMode();
    setinwardDateSearch((moment(new Date())).format('YYYY-MM-DD'))
  }, [])

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })


  const navigate = useNavigate();


  const filteredData = Array.isArray(allOutwardDetails)
    ? allOutwardDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];

  //------------excel import --------------

  const handleImport = (event: any) => {
    if (!event.target.files) return;
    const files = event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const newArr: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // var newArr :any = [...rows];
          // console.log(newArr)
          const awbObject = newArr.map((data: any, index: any) => (
            {
              eid: index,
              direct: 1,
              batchNo : '',
              
              AWB: data[Object.keys(data)[1]],
              vendorAWB: '',
              employeeName: data[Object.keys(data)[2]],
              employeeId:'',
              employeeTableId : 0,
              department: data[Object.keys(data)[3]],
              costCenter: data[Object.keys(data)[4]],
              
              consigneeId : 0,
              consigneeName: data[Object.keys(data)[5]],
              consigneePhoneNumber : '',
              consigneeEmailId: data[Object.keys(data)[6]],
              consigneeCityName: data[Object.keys(data)[7]],
              consigneeCountryCode: data[Object.keys(data)[8]],
              consigneeZipCode: data[Object.keys(data)[9]],
              consigneeAreaName : '',
              consigneeStateName : '',
              consigneeBranchId :0,
              outwarddetailStatus: 0,
              mailIntimation :0,

              numberOfPieces: data[Object.keys(data)[10]],
              remark: data[Object.keys(data)[11]],
              refNo: data[Object.keys(data)[12]],
              weight: data[Object.keys(data)[13]],
              volumetricWeight: data[Object.keys(data)[14]], 
              mailContent: data[Object.keys(data)[15]],               
              mailRoomId: mailRoomId,
              branch: branch,
              createdBy: createdBy,
              updatedBy: updatedBy,
            }
          ));

          setAllOutwardDetails(awbObject);
        }
      }
      reader.readAsArrayBuffer(file);

    }
  }

  const excelSerialToJSDate = (serial: number) => {
    console.log(serial)
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }
  //-------------excel import end here ---------
  const handleMode = (e: any) => {
    setModeId(e.value);
    setMode(e.label)
    if(e.value === 3){
      setoutwardType(1)
    } else {
      setoutwardType(0)
    }
  }
  const handleServiceProvider = (selectedOption: any) => {
    setserviceProviderId(selectedOption.value);
    setserviceProvider(selectedOption.label)
  }
  const handleproduct = (selectedOption: any) => {
    setproduct(selectedOption.value);
    setproductName(selectedOption.label)
  }

  const handleVendorAccount = (selectedOption :any) => {
    setVendoraccount(selectedOption.label)
    setaccountNo(selectedOption.value)
  }

  const handlefromBranch = (selectedOption: any) => {
    setfrombranchId(selectedOption.value);
    setfrombranch(selectedOption.label)
  }
  const getMode = () => {
    MasterDataService.GetshipmentMode().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          await setModeList(options)
        }
      }
    );
  }

useEffect(()=>{  
  getServiceProvider();
},[ModeId])
  const getServiceProvider = async () => {
    await MasterDataService.getBranch().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.branchName
          }))
          await setfromBranchList(options)
        }
      }
    )

    MasterDataService.GetserviceProvider().then(
      async (data) => {
        if (data) {
          const options = data
            .filter((d: any) => d.shipmentModeId === ModeId)
            .map((d: any) => (
              {
                "value": d.id,
                "label": d.serviceProviderName
              }))
          options.unshift({ value: 0, label: "None" });
          // console.log("options ", data)
          setserviceProviderList(options)
        }
      }
    )

  }

  useEffect(()=>{
    const getProducts =async ()=>{
      // console.log("serviceProviderId", serviceProviderId)
      await AccountService.getProductbyServiceProvider(serviceProviderId, branch).then(
        async (data) => {
          console.log("Products Data", data)
          let options = []
          if (data.length > 0) {
             options = data.map((d: any) => ({
              "value": d.id,
              "label": d.productName
            }))
    }
    
    options.unshift({ value: 0, label: "Select Product" });
    await setallProduct(options)
    setproduct(0)
    setproductName("Select Product")
    console.log("productName", productName)
  })
}
const getvendorAccount =async ()=>{
  await AccountService.getVendorAccount(serviceProviderId ).then(
    async (data) => { 
      let options = []
      if (data.length > 0) {
         options = data.map((d: any) => ({
          "value": d.id,
          "label": d.accountNo
        }))
}

options.unshift({ value: 0, label: "Select Vendor Account" });
await setAllVendorAccount(options) 
setaccountNo(0)
setVendoraccount("Select Account Number")
})
}
    getProducts()
    getvendorAccount();
  },[serviceProviderId])


  const handleSubmit = () => {
    if (allOutwardDetails.length > 0) { 
      const updatedAwbObject = allOutwardDetails.map((item :any) => ({
        ...item,
        
        outwardDate : Todate,
        outwardTime: moment().format('HH:mm:ss'),
        outwardType: outwardType,
        modeName: ModeId,
        courierType: courierType,
        product: product,
        productName :productName,
        serviceProvider: serviceProviderId,
        accountNo: accountNo,
        zoneName: 0,

      }));

      OutwardDetailService.importOutwards(updatedAwbObject).then(
        (data) => {
          if (data) {
            Swal.fire({
              title: "Outward Details Import in Progress",
              icon: 'success',
              text: "Outward Details has been Imported Please Wait....",
            }).then(function () {
              navigate("../outwarddetailslist")
            })
          }
        }
      )
    }
  }



  return (
    <>
      <OutwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Outward Data Import </p></div>
            <div className='col-12 inline'>
              <div className='col-4'>
                <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
              </div>
              <div className='col-3'>
                <label> Outward Date</label>
                <input className='margininutleft' type='Date' value={Todate} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setTodate(e.target.value) }} />
              </div>
              <div className='col-6 sec4-radio'>
                <div className='sec4-radio1 direct1'>
                  Destination : &nbsp; <label className='inline' ><input
                    name="connected"
                    id="home"
                    type="radio"
                    value="first"
                    className="sec1-radio radio-btn"
                    onChange={() => { setcourierType(0); setpincodeLable('PinCode') }}
                    checked={courierType === 0 && true}
                  />Domestic </label>
                </div>
                <div className='sec4-radio1 direct1'>
                  <label className='inline' ><input
                    name="connected"
                    id="home"
                    type="radio"
                    value="first"
                    onChange={() => { setcourierType(1); setpincodeLable('ZipCode') }}
                    checked={courierType === 1 && true}
                    className=" sec1-radio radio-btn "
                  />International</label>
                </div>
              </div>
            </div>
            <div className='col-12 inline' style={{ padding: '0px', paddingBottom: "10px", textAlign: 'left' }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%",  paddingTop: '1%' }} >
                  <div className='col-3' >
                    <Select name="ModeId"
                      onChange={handleMode}
                      options={ModeList}
                      value={{ value: ModeId, label: Mode }}
                    />

                  </div>

                  {ModeId === 3 && (<div className='col-3' > <Select name="fromb"
                    onChange={handlefromBranch}
                    options={fromBranchList}
                    value={{ value: frombranchId, label: frombranch }}
                  />
                  </div>)}

                  <div className='col-3' >
                    <Select name="serviceProvider"
                      onChange={handleServiceProvider}
                      options={serviceProviderList}
                      value={{ value: serviceProviderId, label: serviceProvider }}
                    />

                  </div>
                  {ModeId != 3 && (<><div className='col-3' >
                    <Select name="productName"
                      onChange={handleproduct}
                      options={allProduct}
                      value={{ value: product, label: productName }}
                    />

                  </div>
                  <div className='col-3' >
                    <Select name="vendorAccount"
                      onChange={handleVendorAccount}
                      options={allvendorAccount}
                      value={{ value: vendorAccount, label: vendorAccount }}
                    /> 
                  </div>
                    </>)}
                </div>
              </div> 
              <div className="table-responsive tableContainer">
                <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%' }}
                  keyField='eid'
                  striped
                  hover
                  condensed
                  // data={allServiceProvider}
                  data={filteredData}
                  columns={columns}
                  // pagination={pagination}
                  // selectRow={selectRow}
                  filter={filterFactory()}
                  filterPosition="top"
                />
              </div> 
          </div>
          <div className='row col-12'>
            <div className='row col-4 d-flex justify-content-center'>
              <Link to="../uploads/excelformat/outwardImport.xlsx" className="btn2 col-6" target='_blank' > <i className='fa fa-download'></i> Download Template</Link>
            </div>
            <div className='row col-8 d-flex justify-content-end'>
              <Link to="" className="btn2 col-2" onClick={handleSubmit} > <i className="fa fa-plus" aria-hidden="true"></i>Submit</Link>
              <button className="btn2 col-2" > <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OutwardDetailImport