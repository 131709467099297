import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import AuthService from '../../services/auth.service';
import Swal from 'sweetalert2';

function SettingUpdates() {
  const navigate = useNavigate();
  const [pageTitle, setpageTitle] = useState('User Profile');
  const [show, setshow] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleUpdatePassword = () => {
    if (password === confirmPassword) {
    AuthService.updatepassword(password).then(
      (data)=>{
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: "Password Updated Successfully" ,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            title: 'swal-fire-alert', // Add your custom CSS class here
          },
        }).then(function () {
         setshow(false);
         setpageTitle("User Profile")
        });
      }
    )
      setPassword(password);
    } else {
      setErrorMessage("Passwords do not match.");
    }
  };
  const onClose = () => {
    setshow(false)
    setpageTitle("User Profile")
  }
  return (
    <>
      <div className='row col-12'>
        <ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
          <li> <button className="navbar-item inwordbtn" onClick={()=>{setshow(true); setpageTitle("Update Password")}}  >Update Password </button></li>
          <li><button className="navbar-item inwordbtn" onClick={()=>{setshow(true); setpageTitle("Update Password")}}  >Update Password </button></li>
        </ul>
      </div>
      <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                    <div className="container col-11">
                        <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> {pageTitle} </p></div>

{show &&
  (<div className="col-12 card">
    <div className="card-content">
      <div className="card-header">
        <h5 className="card-title">Update Password</h5>       
      </div>
      <div className="card-body">
        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-control mb-2"
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="form-control"
        />
        {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
      </div>
      <div className="card-footer">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          Cancel
        </button>
        <button type="button" className="btn btn-primary" onClick={handleUpdatePassword}>
          Update Password
        </button>
      </div>
    </div>
</div>)
}
</div>
</div>
</div>

    </>

  );
} export default SettingUpdates;