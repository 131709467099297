import '../headerfooter/headerfootercss.css';
import { NavLink } from 'react-router-dom';
function MasterHeader() {
  return (
    <>
      <div className='row col-12'>
        <ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/masterhome" >Masters </NavLink>
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/deliverylist" > Delivery Boy </NavLink>
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/routelist" > Routes </NavLink>
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/employeelist" > Employee </NavLink>
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/shipmentmodelist" > Shipmentmode </NavLink>
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/regulatorslist" > Regulators </NavLink> 
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/serviceproviderlist" > Serviceprovider </NavLink>
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/departmentlist" > Department</NavLink>
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/costcenterlist" > Cost Center </NavLink>
          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/countrylist" > Location </NavLink>
        </ul>
      </div>
    </>

  );
} export default MasterHeader;