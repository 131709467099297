import React, { useEffect, useState } from 'react'
import SettingHeader from './settingHeader';
import MasterDataMailroomService from '../../services/MasterDataMailroom.service';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

interface ReceiverProps {
    id: any;
}

const Branch: React.FC<ReceiverProps> = ({ }) => {
    const { state } = useLocation(); 
  
    const [branchName, setbranchName] = useState('')
    const [branchCode, setbranchCode] = useState('');
    const [emailAddress, setemailAddress] = useState('');
    const [whatsAppNumber, setwhatsAppNumber] = useState('');
    const [whatsAppInstanceId, setwhatsAppInstanceId] = useState('');
    const [whatsAppLink, setwhatsAppLink] = useState('');
    const [smtpServer, setsmtpServer] = useState('');
    const [smtpPort, setsmtpPort] = useState('');
    const [smtpEmail, setsmtpEmail] = useState('');
    const [smtpPassword, setsmtpPassword] = useState('');
    const [smtpsecurityType, setsmtpsecurityType] = useState(0);
   
    const navigate = useNavigate();

    const HandleSubmit = () => {
      if(branchName !== ""){
        MasterDataMailroomService.PostBranch(branchName, branchCode,  emailAddress, whatsAppNumber, whatsAppInstanceId, whatsAppLink, smtpServer, smtpPort, smtpEmail, smtpPassword,smtpsecurityType).then(
            (data) => {
                if (data !== '') {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "Branch Created Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(()=>{
                    navigate('../branchlist'); 
                  });
                }
            },
        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
      }else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: "Please enter Branch Name",
          showConfirmButton: false,
          timer: 1500,
        }).then(()=>{
          navigate('../branchlist'); 
        });
      }
    }
    const HandleEdit = () => {
   if(branchName !== ""){
        MasterDataMailroomService.PutBranch(branchName, branchCode, emailAddress, whatsAppNumber, whatsAppInstanceId, whatsAppLink, smtpServer, smtpPort, smtpEmail, smtpPassword, state).then(
            (data) => {              
                if (data !== '') {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "Branch Updated Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(()=>{
                    navigate('../branchlist'); 
                  });
                }
            }

        ).catch((error) => {
            console.log(error);
            console.log("server Responding");
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: "Please enter Branch Name",
          showConfirmButton: false,
          timer: 1500,
        }).then(()=>{
          navigate('../branch', {state : state}); 
        });
      }
    }

    // get service provider by id
    const GetBranchId = async () => {
        if(state){            
        try {
          await MasterDataMailroomService.GetBranchId(state).then(
           async (data) => {
                await setbranchName(data.branchName)
                await setbranchCode(data.branchCode);
                await setemailAddress(data.emailAddress);
                await setwhatsAppNumber(data.whatsAppNumber);
                await setwhatsAppInstanceId(data.whatsAppInstanceId);
                await setwhatsAppLink(data.whatsAppLink);
                await setsmtpServer(data.smtpServer);
                await setsmtpPort(data.smtpPort);
                await setsmtpEmail(data.smtpEmail);
                await setsmtpPassword(data.smtpPassword);
                await setsmtpsecurityType(data.securityType);
               
           }
          );
          
        } catch (error) {     
          console.error(error);
        }
        
    } 
      };
      

      useEffect(()=>{      
        GetBranchId();   
      },[])
 // delete service
 const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
        const handleDelete = () => {
            if(state !== undefined){   
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  MasterDataMailroomService.DeleteBranch(state)
                  .catch((error) => {
                    console.log(error);
                    console.log("server responding");
              
                  })
                  Swal.fire({
                    title: "Deleted",
                    icon: 'success',
                    text: "Branch has been deleted",
                }).then(function() {
                  navigate("../branchlist")
                })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                )  {
                  swalWithBootstrapButtons.fire(
                    {
                      title: "Cancelled",
                      icon: 'error',
                      text: "Branch is safe :)",
                  }).then(function() {          
                navigate("../branchlist")
                  }
                  )
                }
              })
            }  
            GetBranchId();
        } 

        const HandleCancel = () =>{
            setbranchName("")
            setbranchCode("")
            
          }
    return (
        <>
            <SettingHeader />
            <div className='container col-11'style={{ paddingLeft: "0px" }} >
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Branch Register </p></div>

                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'> Branch Name</label>
                    <input type='text' className='col-8 col-sm-4' name='branchName' value={branchName} onChange={e => setbranchName(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'> Branch Code</label>
                    <input type='text' className='col-8 col-sm-4'  name='branchCode' value={branchCode} onChange={e => setbranchCode(e.target.value)} />
                </div>
                 <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'> WhatsApp Number </label>
                                <input type='text' className='col-8 col-sm-4' name='whatsAppNumber' value={whatsAppNumber} onChange={e => setwhatsAppNumber(e.target.value)} />
                            </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'> WhatsApp Instance Id </label>
                    <input type='text' className='col-8 col-sm-4' name='whatsAppInstanceId' value={whatsAppInstanceId} onChange={e => setwhatsAppInstanceId(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'> WhatsApp Link </label>
                    <input type='text' className='col-8 col-sm-4' name='whatsAppLink' value={whatsAppLink} onChange={e => setwhatsAppLink(e.target.value)} />
                </div>
                <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'>Email Address</label>
                                <input type='mail' className='col-8 col-sm-4' name='emailAddress' value={emailAddress} onChange={e => setemailAddress(e.target.value)} />
                            </div>
                            <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'>Sending From Email</label>
                                <input type='mail' className='col-8 col-sm-4' name='smtpEmail' value={smtpEmail} onChange={e => setsmtpEmail(e.target.value)} />
                            </div>
                            <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'>Email Password</label>
                                <input type='password' className='col-8 col-sm-4' name='smtpPassword' value={smtpPassword} onChange={e => setsmtpPassword(e.target.value)} />
                            </div>
                            
                            <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'> SMTP Server </label>
                                <input type='text' className='col-8 col-sm-4' name='smtpServer' value={smtpServer} onChange={e => setsmtpServer(e.target.value)} />
                            </div>
                            <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'> SMTP Port </label>
                                <input type='text' className='col-8 col-sm-4' name='smtpPort' value={smtpPort} onChange={e => setsmtpPort(e.target.value)} />
                            </div>
                            <div className=' col-sm-12 inputs'>
                    <label className='col-6 col-sm-2'> Security Type </label>
                                <select className='col-8 col-sm-4' name='securityType' value={smtpsecurityType} onChange={e => setsmtpsecurityType(parseInt(e.target.value, 10))} >
                                <option value={1} selected={smtpsecurityType === 1}>True</option>
                                <option value={0} selected={smtpsecurityType === 0}>False</option>
                                </select>
                            </div>

               
            </div>
            <div className='btn d-flex justify-content-center'>
            { (state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""><i className="fa fa-plus" aria-hidden="true"></i> Submit</Link> : <>
                <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to=""><i className="fa fa-edit"></i> Update </Link>
               <button className="btn2 col-2 col-sm-1" onClick={handleDelete} ><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> </> }
                <button className="btn2 col-2 col-sm-1" onClick={HandleCancel} ><i className="fa fa-close"></i> Cancel</button>
                <Link className="btn2 col-2 col-sm-1" to="/branchlist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
            </div>
        </>
    )
}

export default Branch