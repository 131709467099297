import { useEffect, useState } from 'react'
import Inwarddetailsercice from '../../services/inwarddetail.service';
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment';
import InwardContainerHeader from './InwardContainerHeader';
import { read, utils } from 'xlsx';

function InwardDetailImport() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [selectedAWBNumber, setSelectedAWBNumber] = useState();
  const [inwardDateSearch, setinwardDateSearch] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allInwardDetails, setAllInwardDetails] = useState([]);
  
  const createdBy =  localStorage.getItem('userId');
  const updatedBy =  localStorage.getItem('userId');
  const mailRoomId =  localStorage.getItem('mailRoomId');
 const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
  const dateformater = (data: any, row: any) => {
    return (moment(data)).format('DD/MM/YYYY')
  }
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
      setSelectedAWBNumber(row.AWB);
    }
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };
const timeFormatChange  = (data: any, row: any) => {
  if(data.length > 8){
  return (moment(data)).format('hh:mm:ss A')
  } else {
    return data;
  }
}

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'Sr. No.',  align: 'center'},
    { dataField: 'inwardDate', text: 'Inward Date', sort: true},
    { dataField: 'inwardTime', text: 'Inward Time', formatter :timeFormatChange },
    { dataField: 'isrNumber', text: 'ISR Number' },
    { dataField: 'AWB', text: 'AWB Number' },
    { dataField: 'consignerName', text: 'Consignor' },
    { dataField: 'employeeName', text: 'Employee' },
    { dataField: 'inwardQuantity', text: 'Pcs.' },
    { dataField: 'inwarddetailStatusDescription', text: 'Status' },
    { dataField: 'remark', text: 'Remark'},


  ];
  //Table End  

  useEffect(() => {
    setinwardDateSearch((moment(new Date())).format('YYYY-MM-DD'))
  }, [])

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })


  const navigate = useNavigate();
 

  const filteredData = Array.isArray(allInwardDetails)
    ? allInwardDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];

      //------------excel import --------------
  
  
      const handleImport = (event: any) => {
        if (!event.target.files) return;
        const files = event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event:any) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                  const newArr: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                  // var newArr :any = [...rows];
                  // console.log(newArr)
                  const awbObjects = newArr.map((data: any, index :any) => (
                   {
                    eid: index,
                    inwardDate: excelSerialToJSDate(data[Object.keys(data)[0]]).toLocaleDateString(),
                    inwardTime: data[Object.keys(data)[1]],
                    batchNo : data[Object.keys(data)[2]],
                    modeName : data[Object.keys(data)[3]],
                    serviceProvider : data[Object.keys(data)[4]],
                    refNo : data[Object.keys(data)[5]],
                    consignerName : data[Object.keys(data)[6]],
                    consignerPhoneNumber : data[Object.keys(data)[7]],
                    consignerCityName : data[Object.keys(data)[8]],
                    consignerZipCode : data[Object.keys(data)[9]],
                    consignerAreaName : data[Object.keys(data)[10]],
                    consignerStateName : data[Object.keys(data)[11]],
                    AWB : data[Object.keys(data)[12]],
                    inwardQuantity : data[Object.keys(data)[13]],

                    employeeId : data[Object.keys(data)[14]],
                    employeeName : data[Object.keys(data)[15]],
                    remark : data[Object.keys(data)[16]],
                    routeName : data[Object.keys(data)[17]],
                    receivedBy : data[Object.keys(data)[18]],
                    deliveryDateTime : data[Object.keys(data)[19]],
                    deliveryRemark:data[Object.keys(data)[20]],
                    mailRoomId : mailRoomId,
                    branch: branch,
                    createdBy : createdBy,
                    updatedBy : updatedBy ,
                 }
                 ));
               
                    setAllInwardDetails(awbObjects);
                }
            }
            reader.readAsArrayBuffer(file);
    
        }
    }

      const excelSerialToJSDate = (serial :number) => {
        console.log(serial)
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
  
      var fractional_day = serial - Math.floor(serial) + 0.0000001;
  
      var total_seconds = Math.floor(86400 * fractional_day);
  
      var seconds = total_seconds % 60;
  
      total_seconds -= seconds;
  
      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;
  
      return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }
      //-------------excel import end here ---------
      const handleSubmit = () => {
        if(allInwardDetails.length > 0) {
        Inwarddetailsercice.importInwards(allInwardDetails).then(
          (data)=>{
            if(data){
              Swal.fire({
                title: "Inward Details Import in Progress",
                icon: 'success',
                text: "Inward Details has been Imported Please Wait....",
            }).then(function() {
            navigate("../inworddetailslist")
            })  
            }
          }
        )
        }
      }
      
  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Inward Details </p></div>
            <div className='col-12 inline'>
            <div className='col-6'>
            <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
            </div>
                
              <div className='col-6' style={{ display: "flex", height: "50px", justifyContent: "end" }}>
                <input
                  className='searchbar col-6'
                  type='text'
                  value={searchQuery}
                  placeholder='Search Here ...'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive">
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='eid'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
</div>
          </div>
          <div className='row col-12'>
          <div className='row col-4 d-flex justify-content-center'>
          <Link to="../uploads/excelformat/inwardDetails.csv"  className="btn2 col-6"  target='_blank' > <i className='fa fa-download'></i> Download Template</Link>
       </div>
          <div className='row col-8 d-flex justify-content-end'>
           <Link to="" className="btn2 col-2" onClick={handleSubmit} > <i className="fa fa-plus" aria-hidden="true"></i>Submit</Link>
            <button className="btn2 col-2" > <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InwardDetailImport