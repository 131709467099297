import '../headerfooter/headerfootercss.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderTop from './HeaderTop';
import FooterMobileNav from './footerMobileNav';
import { ReactComponent as HelpDesk } from "../../assets/SVG/helpdesk.svg";
import { ReactComponent as MasterSVG } from "../../assets/SVG/Master.svg";
import { ReactComponent as ReportSVG } from "../../assets/SVG/Report.svg";
import { ReactComponent as AccountSVG } from "../../assets/SVG/Accounts.svg";
import { ReactComponent as DashboardSVG } from '../../assets/SVG/Dashboard.svg';
import { ReactComponent as Transection } from '../../assets/SVG/Transection.svg';
import { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';

function MailitNavbar() {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [skipHeader, setskipHeader] = useState("Skip Header");
    function hideHeader() {
        if (show === true) {
            setShow(false)
            setskipHeader("Open Header")
        } else {
            setShow(true)
            setskipHeader("Skip Header")
        }
    }

    useEffect(() => { 
      moment.tz.setDefault('Asia/Kolkata');
      const handleShortcut = (event :any) => {
        // event.preventDefault();
        if (event.altKey && event.key === 'i') { 
          navigate('../inwardloglist');
        }else if (event.altKey && event.key === 'l') { 
          navigate('../inwardlog');
        }else if (event.altKey && event.key === 'e') { 
          navigate('../inwardlog');
        }else if (event.altKey && event.key === 'n') { 
          navigate('../inworddetails');
        }else if (event.altKey && event.key === 'u') { 
          navigate('../inworddetailslist');
        }else if (event.altKey && event.key === 'd') { 
          navigate('../inwarddelivarysheet');
        }else if (event.altKey && event.key === 'o') { 
          navigate('../outwardloglist');
        }else if (event.altKey && event.key === 'j') { 
          navigate('../outwardlog');
        }else if (event.altKey && event.key === 'p') { 
          navigate('../outwarddetailslist');
        }else if (event.altKey && event.key === 'k') { 
          navigate('../outworddetails');
        }else if (event.altKey && event.key === 'm') { 
          navigate('../outwardmanifest');
        }
      };
    
      // Add event listener for keydown event
      document.addEventListener('keydown', handleShortcut);
    
      // Clean up the event listener when component unmounts
      return () => {
        document.removeEventListener('keydown', handleShortcut);
      };
    }, []); // Empty dependency array ensures the effect runs only once
  return (
    <>    
      <div className='sticky onTop'>
        <HeaderTop hideHeader={hideHeader} skipHeader = {skipHeader}/>
        {show && ( 
        <div className='navbar1' style={{marginBottom:'5px'}}>
          <div className='col-12 col-sm-6 header-image1'><NavLink to='/dashboard'><img className='header-image margin-top' src='./assets/upperside-image.png' /></NavLink></div>
          <div className='col-6 col-sm-6  d-none d-lg-block'>
            <div className='inline row right-icons margin-top'>
              <NavLink className={({ isActive }) =>
            [
              "nav-link",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/dashboard">
                  <DashboardSVG />
                  <p className='nav-icon-name'>Dashboard</p><span className="sr-only">(current)</span>
              </NavLink>
              <NavLink className={({ isActive }) =>
            [
              "nav-link",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="../transaction">
                 <Transection />
                <p className='nav-icon-name'>Transanction</p><span className="sr-only">(current)</span>
              </NavLink>
              <NavLink className={({ isActive }) =>
            [
              "nav-link",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="../masterhome">
                <MasterSVG />
                <p className='nav-icon-name' >Master</p><span className="sr-only">(current)</span>
              </NavLink>
              <NavLink className={({ isActive }) =>
            [
              "nav-link",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/accounthome">
                <AccountSVG />
                <p className='nav-icon-name'>Account</p><span className="sr-only">(current)</span>
              </NavLink>
              <NavLink className={({ isActive }) =>
            [
              "nav-link",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/inwardreport">
                <ReportSVG />
                <p className='nav-icon-name'>Report</p><span className="sr-only">(current)</span>
              </NavLink>
              {/* <NavLink className={({ isActive }) =>
            [
              "nav-link",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } style={{ margin: "" }} to="/">
                <MisSVG />
                <p className='nav-icon-name'>Mis</p><span className="sr-only">(current)</span>
              </NavLink> */}
              <NavLink className={({ isActive }) =>
            [
              "nav-link",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/helpdesklist">
                <HelpDesk />
                <p className='nav-icon-name'>Help Desk</p><span className="sr-only">(current)</span>
              </NavLink>
            </div>
          </div>

        </div>
        )}
        <FooterMobileNav />
      </div>
    </>

  );
} export default MailitNavbar;