import '../inward/inwarddetail.css';
import InwardContainerHeader from './InwardContainerHeader';
import { useEffect, useRef, useState } from "react";
import Inwarddetailsercice from "../../services/inwarddetail.service";
import InwardLogService from '../../services/inwardlog.service';
import MasterDataService from '../../services/masterData.service';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import locationService from '../../services/locationData.service';


function InwordDetails() {
    const navigate = useNavigate();
    const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [inwardTime, setinwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
    const [batchNo, setbatchNo] = useState("");
    const mailRoomName = localStorage.userDetails ? JSON.parse(localStorage.userDetails).mailRoomName : ""
    // const [consignorbranch, setConsignorBranch] = useState((localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : 0);
    const [allBranch, setallBranch] = useState([]);
    const [consignorBranchId, setconsignorBranchId] = useState(0);
    const [consignorbranchLabel, setconsignorBranchLabel] = useState('Please Select Branch');
    // const [inwardDate1, setinwardDate1] = useState("");
    const [serviceProviderId, setserviceProviderId] = useState(0);
    const [serviceProvider, setserviceProvider] = useState("");
    const [serviceProviderList, setserviceProviderList] = useState([]);
    const [modeName, setmodeName] = useState('Select');
    const [AWB, setAWB] = useState("");
    const [employeeId, setemployeeId] = useState(0);
    const [remark, setremark] = useState("");
    const [companyName, setcompanyName] = useState(""); 
    const [modeId, setmodeId] = useState(0)
    const [shipmentmode, setshipmentmode] = useState([])
    const [allInwardLog, setAllInwardLog] = useState([]);
    const [allRegulators, setAllRegulators] = useState([]);
    const [allEmployee, setAllEmployee] = useState([]);
    const [fromSource, setFromSource] = useState<Number>(0);
    const [consignor, setConsignor] = useState(0)
    const [consignorName, setConsignorName] = useState("Please Enter Consignor Details")
    const [consignerCityName, setconsignerCityName] = useState("")
    const [consignerpincode, setconsignerpincode] = useState("")
    const [refNo, setrefNo] = useState("")
    const [mailContent, setmailContent] = useState("")
    const [inwardQuantity, setinwardQuantity] = useState<any | null>(1)
    const [maxInwardQuantity, setmaxInwardQuantity] = useState<any | null>(1)
    const [entryDoneQty, setEntryDoneQty] = useState<any | null>(0)
    const [allSelections, setallSelections] = useState([])

    //employee details
    const [employeeName, setemployeeName] = useState("Please Select Employee")
    const [emailId, setemailId] = useState("");
    const [extension, setextension] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [department, setdepartment] = useState(0);
    const [departmentName, setdepartmentName] = useState('');
    const [division, setdivision] = useState("");
    const [floor, setfloor] = useState("");
    const [routeId, setrouteId] = useState(0);
    const [routeName, setrouteName] = useState("");
    const [branch, setbranch] = useState("");
    const [isrno, setisrno] = useState("");
    const [empcity, setempcity] = useState("");
    const [company, setcompany] = useState("");
    const [branchShow, setBranchShow] = useState('none');
    const { state } = useLocation();
    const [inwardlogtotalpcs, setinwardlogtotalpcs] = useState("");
    const [options, setOptions] = useState<any | []>([]);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [serviceProviderLabel, setserviceProviderLabel] = useState("Service Provider")
    const [allCountry, setAllCountry] = useState([])
    const [countryName, setcountryName] = useState("");
    const [countryId, setcountryId] = useState(0);
    const [zipcodeLabel, setzipcodeLabel] = useState("Pincode")
    const [isButtonDisabled,setisButtonDisabled]=useState(false)
    
    const AWBRef = useRef<any>(null);  
    const modeNameRef = useRef<any>(null);
    const serviceProviderRef = useRef<any>(null);
    const branchRef = useRef<any>(null);
    const consignorRef = useRef<any>(null);
    const consognorCompanyRef = useRef<any>(null);
    const consignorCountryRef = useRef<any>(null);
    const consignorPincodeRef = useRef<any>(null);
    const consignorCityRef = useRef<any>(null);
    const referenceNoRef = useRef<any>(null);
    const inwardQtyRef = useRef<any>(null);
    const mailcontentRef = useRef<any>(null);
    const remarkRef = useRef<any>(null);
    const consigneeRef = useRef<any>(null);
    const submitButtonRef = useRef(null);


    useEffect(() => {
        const  callBasicFUnction = async () => {
            try {
        await getShipmentMode();
       // await getServiceProvider(shipmentmode);
        await getInwardLogList();
        await getAllEmployee();
        onFromSourceChange(0)
        await  getInwardDetails();
        await  getAllRegulators()
        await  ShowTableFn()
    } catch (error) {
        console.error("Error in useEffect:", error);
        // Handle the error as needed (e.g., show an error message to the user)
    }
        }
         callBasicFUnction();
    }, [])

    // Load suggestions from local storage on component mount
    useEffect(() => {
        const storedOptions = localStorage.getItem("companyNameSuggestions");
        if (storedOptions) {
            setOptions(JSON.parse(storedOptions));
        }
        getCountry();
    }, []);
    const getBranchAWBNumber = () => {
        Inwarddetailsercice.getBranchAWBNumber().then((data) => {
            // console.log(data)
            setAWB(data)
        })
    }

    // Update suggestions in local storage whenever options change
    useEffect(() => { 
        if (options.length > 0) {
            localStorage.setItem("companyNameSuggestions", JSON.stringify(options));
        }
    }, [options]);
 

    // Handle option creation
    const handleCreateOption = async (companyName: any) => {
        const newOption: any = { label: companyName, value: companyName };
        setOptions([...options, newOption]);
        setSelectedOptions(newOption);
        await setcompanyName(companyName);
    };
 

    const [optionsMailContent, setoptionsMailContent] = useState<any | []>([]);
    const [selectedOptionsmailContent, setSelectedOptionsmailContent] = useState(null);

    // Load suggestions from local storage on component mount
    useEffect(() => {
        const storedOptionsmailContent = localStorage.getItem("mailContentSuggestions");
        if (storedOptionsmailContent) {
            setoptionsMailContent(JSON.parse(storedOptionsmailContent));
        }
    }, []);

    // Update suggestions in local storage whenever options change
    useEffect(() => {
        if (optionsMailContent.length > 0) {
            localStorage.setItem("mailContentSuggestions", JSON.stringify(optionsMailContent));
        }
    }, [optionsMailContent]);
 
    // Handle option creation
    const handleCreateOptionMailContent = (mailContents: any) => {
        //     console.log("Mail COntent OptionCreate",mailContents )
        setmailContent(mailContents);
        const newOption: any = { label: mailContents, value: mailContents };
        setoptionsMailContent([...optionsMailContent, newOption]);
        setSelectedOptionsmailContent(newOption); 
    };

 

    const [optionscityName, setoptionscityName] = useState<any | []>([]);
    const [optionspincode, setoptionspincode] = useState<any | []>([]);
    const [selectedOptionscityName, setSelectedOptionscityName] = useState(null); 

    // Load suggestions from local storage on component mount
    useEffect(() => {
        const storedOptionscityName = localStorage.getItem("cityNameSuggestions");
        if (storedOptionscityName) {
            setoptionscityName(JSON.parse(storedOptionscityName));
        }
    }, []);

    // Update suggestions in local storage whenever options change
    useEffect(() => {
        if (optionscityName.length > 0) {
            localStorage.setItem("cityNameSuggestions", JSON.stringify(optionscityName));
        }
    }, [optionscityName]);


    useEffect(() => {
        const storedOptionspincode = localStorage.getItem("pincodeSuggestions");
        if (storedOptionspincode) {
            setoptionspincode(JSON.parse(storedOptionspincode));
        }
    }, []);

    // Update suggestions in local storage whenever options change
    useEffect(() => {
        if (optionspincode.length > 0) {
            localStorage.setItem("pincodeSuggestions", JSON.stringify(optionspincode));
        }
    }, [optionspincode]);

     // Handle option creation
    const handleCreateOptioncityName = (cityName: any) => {
        setconsignerCityName(cityName);
        const newOption: any = { label: cityName, value: cityName };
        setoptionscityName([...optionscityName, newOption]);
        setSelectedOptionscityName(newOption);
    };
   

    const customStyles = {
        option: (provided: any, { isFocused, isSelected }: any) => ({
            ...provided,
            padding: 5,
            zIndex: 1,
            background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
        }),
        control: (prev: any) => ({
            ...prev,
            // none of react-select's styles are passed to <Control />
            padding: 0

        }),
        menu: (base: any, { isFocused, isSelected }: any) => ({
            ...base,
            zIndex: 100,
            background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
        }),
    }
    const [showtable, setShowTable] = useState(false)
    const [showform, setShowForm] = useState(true)
    const [selectedConsignee, setselectedConsignee] = useState(0);
    const [lockUnlock, setlockUnlock] = useState(false);
    const [consignorlockUnlock, setconsignorlockUnlock] = useState(false);
    const [consigneelockUnlock, setconsigneelockUnlock] = useState(false);

    const [lockdiv, setlockdiv] = useState("none");


    useEffect(() => {
        if (lockUnlock) {
            setlockdiv('block')
        } else if (!lockUnlock) {
            setlockdiv('none')
        }
    }, [lockUnlock]);

    const lockBatchNumber = () => {
        if (batchNo !== "") {
            setlockUnlock(!lockUnlock);
        }
    }

    const lockConsignor = () => {
        if (consignorName !== "") {
            setconsignorlockUnlock(!consignorlockUnlock);
        }
    }

    const lockConsignee = () => {
        if (employeeName !== "") {
            setconsigneelockUnlock(!consigneelockUnlock);
        }
    }
    // const options;
    const ShowTableFn = () => {
        if (lockUnlock === true) {
            setShowForm(true)
            setShowTable(false)
        }
        else if (state === null) {
            setShowForm(!showform)
            setShowTable(!showtable)
        } else {
            setShowTable(false)
            setShowForm(true)
        }
    }


    const getInwardDetails = () => {
        if (state) {
            Inwarddetailsercice.getInwardDetailsById(state).then(
                (data) => {
                    if (data) {
                        console.log("data on Edit", data)
                        setbatchNo(data.batchNo);
                        setAWB(data.AWB);
                        setserviceProviderId(data.serviceProvider);
                        setFromSource(data.inwardType)
                        setisrno(data.isrNumber)
                        setinwardDate(data.inwardDate);
                        setinwardTime(data.inwardTime)
                        setcompanyName(data.companyName)
                        setmodeId(data.modeName)
                        getServiceProvider(data.modeName)
                        setConsignor(data.consignerId)
                        setcountryName(data.consignerCountryCode)
                        setConsignorName(data.consignerName)
                        setrefNo(data.refNo)
                        setconsignerpincode(data.consignerZipCode)
                        setinwardQuantity(data.inwardQuantity)
                        setmaxInwardQuantity(data.inwardQuantity)
                        setemployeeId(data.employeeTableId)
                        setemployeeName(data.employeeName)
                        onFromSourceChange(data.inwardType)
                        setconsignorBranchId(data.fromBranch)
                        setselectedConsignee(data.employeeTableId)
                        setmailContent(data.mailContent)
                        setremark(data.remark)
                        if (data.inwardType === 2) {
                            getBranchEmployeeList(data.fromBranch)
                        }
                        setModeIdonChange(data.modeName);
                        setconsignerCityName(data.consignerCityName)
                        // setcountryIdonEdit();
                    }
                }
            )
        }
    }
    //  get branch options
    const getBranch = async () => {
        await MasterDataService.getBranch().then(
            async (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.branchName
                    }))
                    await setallBranch(options)
                    if (state === null) {
                        if (data[0]) {
                            await setconsignorBranchId(data[0]['id'])
                            await setconsignorBranchLabel(data[0]['branchName'])
                        }
                    } 
                }
            }
        )
    }

    useEffect(() => {
        let branchLists = allBranch.find((item: any) => {
            return item['value'] === consignorBranchId
        });
        if (branchLists !== undefined) {
            setconsignorBranchLabel(branchLists['label'])
        }
    }, [allBranch, consignorBranchId])

    const getShipmentMode = async () => {
        await MasterDataService.GetshipmentMode().then(
            async (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.modeName
                    })) 
                    await setshipmentmode(options)
                    // if (state === null) {
                    //     if (data[0]) {
                    //         await setmodeId(data[0]['id'])
                    //         await setmodeName(data[0]['modeName'])
                    //     }
                    // }
                }
                 
            }
        );

    }
    useEffect(() => {
        setModeIdonChange(modeId); 
    }, [modeId])

   

    const setModeIdonChange = async (modeVal :number) => {
        console.log("modeId on Edit", modeVal, shipmentmode)
        let modesNames = await shipmentmode.find((item: any) => {
            console.log("item['value']", item['value'])
            return item['value'] === modeVal
        });
        console.log("modesNames", modesNames)
        if (modesNames !== undefined) {
            setmodeName(modesNames['label'])
        }
    }
    const getServiceProvider = async (value: any) => {
        //if (value != 3) {
            setserviceProviderLabel("Service Provider")
            await MasterDataService.GetserviceProvider().then(
                async (data) => {
                    if (data) {
                        
                        const options = 
                        data.filter((d: any) => d.shipmentModeId === value)
                        .map((d: any) => ({
                            "value": d.id,
                            "label": d.serviceProviderName
                        }))
                        await setserviceProviderList(options)
                        if (serviceProviderId !== 0) {
                            const spname = options.find((spnm: any) => spnm.value === serviceProviderId);
                            if (spname) {
                              await setserviceProvider(spname.label);
                            }
                          }
                        // if (state === null) {
                        //     if (data[0]) {
                        //         await setserviceProviderId(data[0]['id'])
                        //         await setserviceProvider(data[0]['serviceProviderName'])
                        //     }
                        // }
                    }
                }
            ) 
    }

    useEffect(() => {
        let serviceProviderLists = serviceProviderList.find((item: any) => {
            return item['value'] === serviceProviderId
        });
        if (serviceProviderLists !== undefined) {
            setserviceProvider(serviceProviderLists['label'])
        }
    }, [serviceProviderList, serviceProviderId])

    const getInwardLogList = async () => {
        if (state === null) {
            await InwardLogService.getInwardLogPendingList().then(
                (data) => {
                    if (data) {
                        setAllInwardLog(data);
                    }
                }
            );
        }
    }
    const getAllRegulators = async () => {
        await MasterDataService.getRegulators().then(
            (data) => {
                if (data) {
                    const datopt = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.regulatorName
                    }))
                    setAllRegulators(datopt)
                }
            }
        );
    }
    const customStylesOption = {
        option: (provided :any, state :any) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'lightgray' : 'white',
          color: state.isSelected ? 'black' : 'inherit',
          fontSize: state.data.fontSize || 'inherit',
          '&:hover': {
            backgroundColor: 'lightgray', // Apply hover color
          },
          '&:focus': {
            backgroundColor: 'lightgray', // Apply focus color
            outline: 'none', // Remove default outline
            boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)', // Add boxShadow to indicate focus
          },
        }),
      };
      
      

      interface EmployeeOption {
        value: number;
        label: string;
        secondary?: string;
        tertiary?: string;
      }
      
      interface CustomOptionProps { 
        data: EmployeeOption; 
        isSelected : any;
      }
      
      const customOption: React.FC<CustomOptionProps> = ({ data, isSelected, ...props }) => (
        <div  
          {...props} 
          onClick={() => {setemployeeId(data.value);  setemployeeName(data.label);  if (consigneeRef.current) {
            consigneeRef.current.blur(); // Blur to close the dropdown
          }}} 
          style={{ 
            cursor: isSelected ? 'pointer' : 'default', // Show pointer cursor for selected option
            borderBottom: "1px solid #ccc",
            backgroundColor: isSelected ? '#f5f5f5' : 'white', // Apply background color when option is selected
            color: isSelected ? 'black' : 'inherit', // Apply text color when option is selected
          }}
        >
          <div>{data.label}</div>
          {data.secondary && <div style={{ fontSize: '12px', paddingLeft: '2px' }}>{data.secondary}</div>}
          {data.tertiary && <div style={{ fontSize: '10px', paddingLeft: '2px' }}>{data.tertiary}</div>}
        </div>
      );
      
      
      

    const getAllEmployee = async () => {
        await MasterDataService.getEmployeeList().then(
            (data) => {                
                if (data) {
                    const optionsConsignee = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.employeeName ,
                        "secondary": d.departmentName,
                        "tertiary": d.routeName,
                        // "fontSize": '16px', // Font size for the main label 
                    }))
                    setAllEmployee(optionsConsignee);
                    // setallSelections(options)
                }
            }
        );
    }

    const handlemodechange = async (e: any) => {
       // console.log("modeId on change function", modeId, e.value);
        setmodeId(e.value)
        setmodeName(e.label)
        if(e.value === 3)
        {
            setFromSource(2)
            onFromSourceChange(2)
        }
        else{
            setFromSource(0)
            onFromSourceChange(0)
        }
        setserviceProviderId(0);
        setserviceProvider("");
        getServiceProvider(e.value) 
        //  if(e.value === 3)
        //  {
        //   setserviceProviderId(22)
        //   setserviceProvider("Mumbai-BKC")
        //  }
    };

    // useEffect(()=>{
    //     onFromSourceChange(fromSource) 
    // },[fromSource])
    const onFromSourceChange = async (value: Number) => {
        setFromSource(value);
        console.log("value", value)
        if (value === 1) {
            if(state === null){
            setBranchShow('none');
            setcompanyName("");
            }
            await setallSelections(allRegulators)
        } else if (value === 2) {
            setBranchShow('block');
            getBranch();
            if(branchRef.current != null){
                branchRef.current.focus();
            }
            if(state === null){
            setcompanyName(mailRoomName);
            }
            //   await  getAllEmployee();
            //   await setallSelections(allEmployee)
        } else {
            setBranchShow('none');
            if(state === null){
            setcompanyName("")
            }
            await setallSelections([])
        }
    }

    const handleConsignor = async (e: any) => {
        if (e) {
            if (fromSource === 0) {
                await setConsignor(0);
            } else {
                await setConsignor(e.value);
            }
            await setConsignorName(e.label);
            await setconsignerCityName(e.cityName);
            if (e.pincode) {
            await getPincodeByCountry(e.pincode);
            await setconsignerpincode(e.pincode);
            }
            
        }
    }
    const handlemailcontent = async (e: any) => {
        if (e) {
            await setmailContent(e.value);
        }
    }
    const handleconcompanyname = async (e: any) => {
        if (e) {
            await setcompanyName(e.value);
        }
    }
    const handleconcity = async (e: any) => {
        if (e) {
            await setconsignerCityName(e.value);
        }
    } 
    const consigneeChange = async (e: any) => {
        await setemployeeId(e.value);
        await setemployeeName(e.label)
    }
    useEffect(() => {
        EmployeeDetails();
    }, [employeeId])
    const EmployeeDetails = async () => {
        console.log("employeeId on change", employeeId)
        if (employeeId !== 0  && employeeId != undefined) {
            await MasterDataService.GetemployeeId(employeeId).then(
                (empdata) => {
                    if(empdata){
                    setemployeeId(empdata.id);
                    setemailId(empdata.emailId);
                    setextension(empdata.extension);
                    setphoneNumber(empdata.phoneNumber);
                    setdepartment(empdata.department);
                    setdepartmentName(empdata.departmentName);
                    setdivision(empdata.division);
                    setfloor(empdata.floor);
                    setrouteId(empdata.routeId);
                    setrouteName(empdata.routeName);
                    setbranch(empdata.BranchName);
                    setcompany(empdata.company);
                    setempcity(empdata.cityName);
                    }
                }
            )
        }
    }
    const handleEdit = () => {
        if ((serviceProviderId !== null) && (AWB !== "") && (employeeId !== 0) && (inwardQuantity !== null) && (consignorName !== "")) {
            Inwarddetailsercice.inwarddetailUpdate(
                inwardDate,
                inwardTime,
                batchNo,
                modeId,
                AWB,
                serviceProviderId,
                fromSource,
                companyName,
                consignorBranchId,
                consignor,
                consignorName,
                consignerCityName,
                employeeId,
                employeeName,
                inwardQuantity,
                routeId,
                department,
                refNo,
                remark,
                mailContent,
                state
            ).then(
                (data) => {
                    if (data) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: "ISR No:- " + data.isrNumber + " Updated Successfully",
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                title: 'swal-fire-alert', // Add your custom CSS class here
                            },
                        }).then(function () {
                            navigate('../inworddetailslist');
                        });
                    }
                }
            )
        } else {
            let errorMessage = '';
            if ((inwardQuantity === null) && (employeeId === 0) && (consignorName !== "") && (AWB === "")) {
                errorMessage = "Please Enter No of Pcs. Received And Consignor /Consignee Details And AWB Number";
                AWBRef.current?.focus();
            } else if (employeeId === 0) {
                errorMessage = "Please Enter Consignee/ Employee Name";
            } else if (AWB === "") {
                errorMessage = "Please Enter Valid AWB Number";
                AWBRef.current?.focus();
            } else if (inwardQuantity === null) {
                errorMessage = "Please Enter No of Pcs. Received";
            } else if (consignorName === "") {
                errorMessage = "Please Enter Consignor Details";
            }

            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: errorMessage,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                    title: 'swal-fire-alert',
                },
            });
        }
    }
    const handleSubmit = () => {
        // console.log("consignorName", consignorName);
        // console.log("companyName", companyName);
        // console.log("mailContent",mailContent);
        if ((serviceProviderId !== null) && (AWB !== "") && (employeeId !== 0) && (inwardQuantity !== null) && (consignorName !== "")) {
           
            setisButtonDisabled(true);
            Inwarddetailsercice.inwarddetailCreate(
                inwardDate,
                inwardTime,
                batchNo,
                modeId,
                AWB,
                serviceProviderId,
                fromSource,
                companyName,
                consignorBranchId,
                consignor,
                consignorName,
                consignerCityName,
                employeeId,
                employeeName,
                inwardQuantity,
                routeId,
                department,
                refNo,
                remark,
                mailContent,
                consignerpincode,
                countryName
            ).then(
                (data) => {
                    if (data) {
                        // console.log("response Data : "+JSON.stringify(data))
                        let isrnum = JSON.stringify(data.inwardDetails.isrNumber);
                        if (!lockUnlock) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: "ISR NO : " + isrnum + " inserted Successfully.",
                                showConfirmButton: true,
                                // timer: 1500,
                                customClass: {
                                    title: 'swal-fire-alert', // Add your custom CSS class here
                                },
                            }).then(function () {
                                navigate('../inworddetailslist');
                            });
                        }
                        else {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: "ISR NO : " + isrnum + " inserted Successfully.",
                                showConfirmButton: true,
                                timer: 1500,
                                customClass: {
                                    title: 'swal-fire-alert', // Add your custom CSS class here
                                } ,
                                didClose: () => { 
                                    AWBRef.current.focus();
                                }
                            }) 
                            setAWB('')
                            if(modeId == 3){
                                getBranchAWBNumber()
                            }
                            if (!consignorlockUnlock) {
                                setConsignor(0)
                                setConsignorName('');
                                setconsignerpincode('');
                                setrefNo('');
                                setmailContent('');
                                setconsignerCityName('')
                            }
                            if (!consigneelockUnlock) {                                
                                setemployeeId(0)
                                setemployeeName('')
                                setdepartmentName(''); 
                                setrouteName('');
                                setrouteId(0)
                                setdepartment(0)
                                setemailId('')
                                setempcity('')
                                setbranch('')
                                setphoneNumber('')
                                setextension('')
                            }
                            setEntryDoneQty(parseFloat(entryDoneQty) + parseFloat(inwardQuantity))
                            setisButtonDisabled(false);
                            AWBRef.current.focus();
                        }
                    }
                }
            )
        } else {
            let errorMessage = '';
            if ((inwardQuantity === null) && (employeeId === 0) && (consignorName !== "") && (AWB === "")) {
                errorMessage = "Please Enter No of Pcs. Received And Consignor /Consignee Details And AWB Number";
                AWBRef.current?.focus();
            } else if (employeeId === 0) {
                errorMessage = "Please Enter Consignee/ Employee Name";
            } else if (AWB === "") {
                errorMessage = "Please Enter Valid AWB Number";
                AWBRef.current?.focus();
            } else if (inwardQuantity === null) {
                errorMessage = "Please Enter No of Pcs. Received";
            } else if (consignorName === "" || consignorName === "Please Enter Consignor Details") {
                errorMessage = "Please Enter Consignor Details";
            }

            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: errorMessage,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                    title: 'swal-fire-alert',
                },
            });
        }
    }
    const handleBranchChange = async (e: any) => {
        await setconsignorBranchId(e.value)
        await setconsignorBranchLabel(e.label)
        getBranchEmployeeList(e.value);
    };
    const getBranchEmployeeList = async (value: Number) => {
        await MasterDataService.getBranchEmployeeByBranchId(value).then(
            async (data) => {
                if (data) {
                    console.log("data employee", data)
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.employeeName,
                        "consigneeAddress": d.fullAddress,
                        "pincode": d.detailedZipCode,
                        "pincodeId": d.zipCode,
                        "cityName": d.cityName
                    }))
                    await setallSelections(options)
                    // if (state === null) {
                    //     if (data[0]) {
                    //         await setConsignor(data[0]['id'])
                    //         await setConsignorName(data[0]['employeeName'])
                    //         await setconsignerCityName(data[0]['cityName'])
                    //     }
                    // } 
                }
            }
        )
    }

    // useEffect(() => {
    //     if (fromSource === 2) {
    //         let branchwiseEmployeeLists = allSelections.find((item: any) => {
    //             return item['value'] === consignor
    //         });
    //         // if (branchwiseEmployeeLists !== undefined) {
    //         //     setConsignorName(branchwiseEmployeeLists['label'])
    //         //     // setconsignerCityName(branchwiseEmployeeLists['consigneeAddress'])
    //         //     //setconsignerpincode(branchwiseEmployeeLists['detailedZipCode'])
    //         // }
    //         //setcompanyName('ICICI PRU AMC LTD')
    //         // setcompanyName('')
    //     }
    // }, [consignor, allSelections])

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })
    const handleDelete = () => {
        if (state !== undefined) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    Inwarddetailsercice.deleteInwardDetails(state)
                        .catch((error) => {
                            console.log(error);
                            console.log("server responding");
                        })
                    Swal.fire({
                        title: "Deleted",
                        icon: 'success',
                        text: "Inward Details Entry has been deleted",
                    }).then(function () {
                        navigate('../inworddetailslist');
                    })
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        {
                            title: "Cancelled",
                            icon: 'error',
                            text: "Inward Details Entry is safe :)",
                        }).then(function () {
                            navigate('../inworddetailslist');
                        }
                        )
                }
            })
        }
    }
    

    const getCountry = async () => {
        await locationService.getCountry().then(
            async (data) => {
                const options = data.map((d: any) => ({
                    "value": d.id,
                    "label": d.countryName
                }))
                await setAllCountry(options);
              
                if (state === null) {
                    if (data[0]) {
                        await setcountryId(data[0]['id'])
                        await setcountryName(data[0]['countryName'])
                    }
                }  
            }
        )
    }
    useEffect(() => {
        const setcountryIdonEdit = () => { 
            allCountry.forEach((country :any) => {
                if (country.label === countryName) {
                  setcountryId(country.value);
                }
              });
         }
        setcountryIdonEdit(); 
    }, [countryName])

    const handleCountryChange = (e: any) => {
        if (e && e.value) {
            setcountryName(e.label)
            setcountryId(e.value)
            if (e.value != 1) {
                setzipcodeLabel("ZipCode")
            } else {
                setzipcodeLabel("PinCode")
            }
        }
    }

    const getPincodeByCountry = (input: any) => {
        if (input.length > 3) {
            return MasterDataService.getPincodeByCountry(countryId, input).then((data) => {
                const zipCodeList = data.map((d: any) => ({
                    "value": d.id,
                    "label": d.zipCode,
                    "city": d.cityName
                }))
                return zipCodeList;

            })
        }
    }

    const handleKeyDown = (e :any, nextRef:any) => {  
        console.log("nextRef", nextRef)
        if (e.key === 'Enter') {
          e.preventDefault();
          e.target.blur();
          if (nextRef.current !== null) {
          nextRef.current.focus();
          }
        }
      };



    return (
        <>
            <InwardContainerHeader />
            <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                    <div className="container col-11">
                        <div className="themeColor width" ><p style={{ marginLeft: "10px" }}> Inward Detail Entry </p></div>
                        <div style={{ paddingBottom: '10px' }}>
                            <div className='col-12 inline'>
                                <div className="col-4">
                                    <div className="inline">
                                        <label className="col-sm-4">Batch No</label>
                                        <input className=" col-sm-6 zeroPadding" type="text" value={batchNo} name='batchNo' onChange={e => setbatchNo(e.target.value)} onClick={ShowTableFn} /><button className={"col-sm-2" + (lockUnlock) ? "themeColor" : "greenBackground"} style={{ backgroundColor: (lockUnlock) ? '' : 'green', color: 'white', padding: 0, width: '100%' }} onClick={lockBatchNumber}>{(lockUnlock) ? <i className='fa fa-lock'></i> : <i className='fa fa-unlock'></i>}</button>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className='inline'>
                                        <label className="col-4 zeroPadding">Mode</label>
                                        <Select
                                            ref={modeNameRef}
                                            name="modeId"
                                            // onChange={(e: any) => { inputRef.modeId = e.value; setmodeName(e.label); setmodeId(e.value) }}
                                            onChange={handlemodechange}
                                            options={shipmentmode}
                                            className='col-8 select'
                                            styles={customStyles}
                                            value={{ value: modeId, label: modeName }}
                                            onKeyDown={(event:any) => {
                                                if (event.key === 'Enter' && modeNameRef.current) {
                                                  event.preventDefault();  
                                                  const selectedOption = modeNameRef.current.state.focusedOption ; 
                                                  if (selectedOption) {
                                                    handlemodechange(selectedOption);
                                                  }    
                                                  handleKeyDown(event, serviceProviderRef);                                            
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 inline" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <label className="col-4" style={{ paddingLeft: '0px', paddingRight: '0px' }} >Date & Time</label>
                                    <div className='col-7 inline' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <input className="col-7" style={{ paddingLeft: '0px', paddingRight: '0px' }} type="date" value={inwardDate} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
                                        <input className=" col-5" style={{ paddingLeft: '0px', paddingRight: '0px' }} type="time" value={inwardTime} name='inwardTime' onChange={e => setinwardTime(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 inline'>
                                <div className="col-4">
                                    <div className="inline">
                                        <label className="col-sm-4">AWB No</label>
                                        <input className="col-sm-8 zeroPadding" ref={AWBRef} type="text" value={AWB} onChange={(e) => { setAWB(e.target.value) }} name="AWB"  onKeyDown={(e) => handleKeyDown(e, consignorRef)} onFocus={(event) => { event.target.select(); }} />

                                    </div>
                                    {(batchNo != "") && (<div className='col-12 text-right' style={{ fontStyle: 'italic', fontSize: '10px', color: 'blue' }}> {entryDoneQty} out of {inwardlogtotalpcs}</div>)}
                                </div>
                                <div className="col-4">
                                    <div className='inline'>
                                        <label className="col-4" style={{ padding: 0 }}> <span style={{ fontSize: '15px' }}> {serviceProviderLabel} </span></label>
                                        <Select
                                            ref={serviceProviderRef}
                                            name="serviceProviderId"
                                            onChange={(e: any) => {
                                                setserviceProviderId(e.value);  setserviceProvider(e.label); 
                                                //  if(fromSource === 2) { handleCreateOptioncityName('IPRU ' + e.label)} 
                                            }}
                                            options={serviceProviderList}
                                            // styles={customStyles}
                                            value={{ value: serviceProviderId, label: serviceProvider }}
                                            className='col-8'
                                            onKeyDown={(event:any) => {
                                                if (event.key === 'Enter' && serviceProviderRef.current) {
                                                  event.preventDefault();  
                                                  const selectedOption = serviceProviderRef.current.state.focusedOption ; 
                                                  if (selectedOption) {
                                                    setserviceProviderId(selectedOption.value);
                                                    setserviceProvider(selectedOption.label)
                                                  }        
                                                if(modeId ===3){
                                                    console.log("go to Brach")
                                                    handleKeyDown(event, branchRef);   
                                                }    else {
                                                    console.log("Brach check null go to Consignor")
                                                    handleKeyDown(event, consignorRef);     
                                                }                              
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='col-4' style={{ paddingTop: '5px', fontWeight: 600 }}>
                                    <span className='col-4' >ISR No :- </span>
                                    <span className='col-8'>{isrno}</span>
                                </div>
                            </div>
                        </div>
                        {!lockUnlock && (<>
                            {showtable && (
                                <div style={{ height: '300px', overflowX: 'scroll' }}>
                                <table className="table font-size-14 text-left scroll-table"  >
                                    <thead >
                                        <tr style={{ textAlign: "center", border: "1px solid grey" }}>
                                            <th scope="col">Sr No</th>
                                            <th scope="col">Log Date</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Batch No</th>
                                            <th scope="col">Mode</th>
                                            <th scope="col" >Service Provider/Branch</th>
                                            <th scope="col">Pcs.</th>
                                            <th scope="col"> Entry Done</th>
                                            <th scope="col">Pending</th>
                                            <th scope="col">Remark</th>
                                            <th scope="col">Log Entry By</th>
                                        </tr>
                                    </thead>

                                    <tbody className="scroll-table">
                                        {Array.isArray(allInwardLog) && allInwardLog.map((inward: any, index) => {
                                            if (inward.pendingQuantity > 0) {
                                                return <>
                                                    <tr onClick={async () => {
                                                        setbatchNo(inward.batchNo);
                                                        setserviceProviderId(inward.serviceProvider);
                                                        setserviceProvider(inward.serviceProviderName);
                                                        setmodeName(inward.shipmentModeName);
                                                        setmodeId(inward.modeName);
                                                        await getServiceProvider(inward.modeName)
                                                        setShowTable(false)
                                                        setShowForm(true)
                                                        setmaxInwardQuantity(inward.pendingQuantity)
                                                        setEntryDoneQty(inward.totalEntriesDone)
                                                        setinwardlogtotalpcs(inward.noOfShipment)
                                                        if (inward.modeName === 3) {
                                                            setFromSource(2)
                                                            onFromSourceChange(2)
                                                            // setAWB(branchAWB)
                                                            getBranchAWBNumber()
                                                            setconsignorBranchId(inward.frombranchId)
                                                            setconsignorBranchLabel(inward.frombranch)
                                                            await getBranchEmployeeList(inward.frombranchId)
                                                            
                                                            // setcompanyName('ICICI PRU AMC LTD')

                                                        } else {
                                                            setFromSource(0)
                                                            setConsignor(0)
                                                            setConsignorName("")
                                                            onFromSourceChange(0)
                                                            setAWB('')
                                                        }
                                                        AWBRef.current.focus();
                                                    }}>
                                                        <th scope="row" >{index + 1}</th>
                                                        <td>{(moment(inward.inwardDate)).format('DD/MM/YYYY')}</td>
                                                        <td>{(inward.inwardTime.length > 8) ? (moment(inward.inwardTime)).format('hh:mm:ss A') : inward.inwardTime}</td>
                                                        <td>{inward.batchNo}</td>
                                                        <td>{inward.shipmentModeName}</td>
                                                        <td>{inward.modeName === 3  && inward.frombranch 
                                                                ? inward.frombranch  
                                                                : inward.serviceProviderName
                                                        }</td>
                                                        <td className='text-center'>{inward.noOfShipment}</td>
                                                        <td className='text-center'>{inward.totalEntriesDone}</td>
                                                        <td className='text-center'>{inward.pendingQuantity}</td>
                                                        <td>{inward.logRemarks}</td>
                                                        <td>{inward.deliveryStaff}</td>

                                                    </tr>

                                                </>
                                            }
                                        })}



                                    </tbody>
                                </table> 
                                </div>)
                            } </>)}
                        {showform && (
                            <div className='width' style={{ border: "1px solid #ccc" }}>
                                <div style={{ textAlign: 'left', height: 25 }}>
                                    <div style={{ backgroundColor: 'grey', height: 25, color: 'white' }} > <label className='themeColor' style={{ color: 'white', paddingLeft: '10px' }}> From Location &nbsp;</label> <label> <input type="radio" name="fromsource" style={{ height: '12px' }} value="2" onChange={() => { onFromSourceChange(2) }} checked={fromSource === 2} /> Inter Company  &nbsp;  &nbsp; </label>  <label> <input type="radio" name="fromsource" style={{ height: '12px' }} value="1" onChange={() => { onFromSourceChange(1) }} checked={fromSource === 1} />  Regulators  &nbsp;  &nbsp; </label>   <label> <input type="radio" name="fromsource" style={{ height: '12px' }} value="0" onChange={() => { onFromSourceChange(0) }} checked={fromSource === 0} /> Others </label>
                                    </div>
                                </div>
                                <div className='col-12 row'>
                                    <div className='col-7' style={{ paddingTop: '5px' }}>
                                        <div style={{ display: branchShow }}>
                                            <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                                <span className='col-3'>Branch</span>
                                                <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Select
                                                        ref={branchRef}
                                                        name="consignorBranchId"
                                                        onChange={handleBranchChange}
                                                        options={allBranch}
                                                        value={{ value: consignorBranchId, label: consignorbranchLabel }}
                                                        styles={customStyles}
                                                        onKeyDown={(event:any) => {
                                                            if (event.key === 'Enter' && branchRef.current) {
                                                              event.preventDefault();  
                                                              const selectedOption = branchRef.current.state.focusedOption ; 
                                                              if (selectedOption) {
                                                                handleBranchChange(selectedOption);
                                                              } 
                                                              handleKeyDown(event, consignorRef); 
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3'>Consignor</span>
                                            <div className={"select" + (lockUnlock) ? "col-8" : "col-9"} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <CreatableSelect
                                                    ref={consignorRef}
                                                    isClearable
                                                    options={allSelections}
                                                    onChange={handleConsignor}
                                                    value={{ value: consignor, label: consignorName }}
                                                    placeholder="Enter or select a Consignor"
                                                    onKeyDown={(event:any) => {
                                                        if (event.key === 'Enter' && consignorRef.current) {
                                                          event.preventDefault();  
                                                        //   console.log("consignorRef.current.state.focusedOption ", consignorRef.current.state.focusedOption.__isNew__)
                                                          const selectedOption = consignorRef.current.state.focusedOption ;  
                                                          if (selectedOption) {
                                                            if(selectedOption.__isNew__){
                                                                handleConsignor({value: selectedOption.value, label : selectedOption.value});
                                                            } else {
                                                                handleConsignor({value: selectedOption.value, label : selectedOption.label});
                                                            }
                                                          } 
                                                          handleKeyDown(event, consignorPincodeRef); 
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div style={{ display: lockdiv }} className='col-1'>
                                                <button className={"col-12" + (consignorlockUnlock) ? "themeColor" : "greenBackground"} style={{ backgroundColor: (consignorlockUnlock) ? '' : 'green', color: 'white', alignItems:'center', display: 'flex',  justifyContent: 'center', height:'100%' }} onClick={lockConsignor}> &nbsp;&nbsp;  {(consignorlockUnlock) ?   <i className='fa fa-lock'></i> : <i className='fa fa-unlock'></i>}</button>
                                            </div>
                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' >Company Name</span>

                                            <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <CreatableSelect
                                                    ref={consognorCompanyRef}
                                                    isClearable
                                                    options={options}
                                                    onChange={handleconcompanyname}
                                                    onCreateOption={handleCreateOption}
                                                    value={{ value: companyName, label: companyName }}
                                                    placeholder="Enter or select a Mail Content"
                                                    onKeyDown={(event:any) => {
                                                        if (event.key === 'Enter' && consognorCompanyRef.current) {
                                                          event.preventDefault();  
                                                          const selectedOption = consognorCompanyRef.current.state.focusedOption ;  
                                                          if (selectedOption) {
                                                            if(consognorCompanyRef.current.state.focusedOption.__isNew__){
                                                                handleconcompanyname({value: selectedOption.value, label : selectedOption.value});
                                                            } else {
                                                                handleconcompanyname({value: selectedOption.value, label : selectedOption.label});
                                                            }
                                                          } 
                                                          handleKeyDown(event, consignorCountryRef); 
                                                        }
                                                    }}
                                                />
                                               
                                            </div>
                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' >Country</span>

                                            <div className='col-3 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <CreatableSelect
                                                    ref={consignorCountryRef}
                                                    isClearable
                                                    options={allCountry}
                                                    onChange={handleCountryChange}
                                                    value={{ value: countryId, label: countryName }}
                                                    placeholder="Enter or select Country"
                                                    onKeyDown={(event:any) => {
                                                        if (event.key === 'Enter' && consignorCountryRef.current) {
                                                          event.preventDefault();  
                                                          const selectedOption = consignorCountryRef.current.state.focusedOption ;  
                                                          if (selectedOption) {
                                                            handleCountryChange(selectedOption);
                                                          } 
                                                          handleKeyDown(event, consignorPincodeRef); 
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <span className='col-2' >{zipcodeLabel}</span>
                                            <div className='col-4 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <AsyncSelect
                                                ref={consignorPincodeRef}
                                                    name="consignerpincode"
                                                    // onInputChange={(input) => setInputValue(input)}
                                                    onChange={(e: any) => { setconsignerpincode(e.label); setconsignerCityName(e.city) }}
                                                    loadOptions={getPincodeByCountry}
                                                    value={{ value: consignerpincode, label: consignerpincode }}
                                                    styles={customStyles}
                                                    onKeyDown={(event:any) => {
                                                        if (event.key === 'Enter' && consignorPincodeRef.current) {
                                                          event.preventDefault();  
                                                          const selectedOption = consignorPincodeRef.current.state.focusedOption ;  
                                                          if (selectedOption) {
                                                            // getPincodeByCountry(selectedOption);
                                                            setconsignerpincode(selectedOption.label); setconsignerCityName(selectedOption.city)
                                                          } 
                                                          handleKeyDown(event, referenceNoRef); 
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>

                                            <span className='col-3' >City</span>
                                            <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                                <CreatableSelect
                                                ref={consignorCityRef}
                                                    isClearable
                                                    options={optionscityName}
                                                    onChange={handleconcity}
                                                    onCreateOption={handleCreateOptioncityName}
                                                    value={{ value: consignerCityName, label: consignerCityName }}
                                                    placeholder="Enter or select a City"
                                                    onKeyDown={(event:any) => {
                                                        if (event.key === 'Enter' && consignorCityRef.current) {
                                                          event.preventDefault();  
                                                          const selectedOption = consignorCityRef.current.state.focusedOption ;  
                                                          if (selectedOption) {
                                                            getPincodeByCountry(selectedOption);
                                                          } 
                                                          handleKeyDown(event, referenceNoRef); 
                                                        }
                                                    }}
                                                />

                                            </div>
                                        </div>

                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                           <span className='col-3 '>Ref No</span>
                                            <div className='col-9 row inline'> 
                                            <input type="text" ref={referenceNoRef} value={refNo} onChange={(e) => setrefNo(e.target.value)} className='col-7' 
                                            onKeyDown={(e) => handleKeyDown(e, inwardQtyRef)} 
                                                     />
                                            
                                            <span className='col-3'>Pcs. /Qty. </span>
                                            <input
                                            ref={inwardQtyRef}
                                                type="text"
                                                value={inwardQuantity }
                                                onChange={(e: any) => {
                                                    const newValue = e.target.value;
                                                    if (newValue === "" || (/^\d*$/.test(e.target.value)) && parseInt(newValue) <= maxInwardQuantity) {
                                                        setinwardQuantity(newValue);
                                                    }
                                                }}
                                                className='col-2 text-left' 
                                                onKeyDown={(e) => handleKeyDown(e, mailcontentRef)}
                                                onFocus={(event) => { event.target.select(); }}
                                            />
                                            </div>
                                        </div>

                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                          
                                            {/* <input type="text" max={maxInwardQuantity} value={inwardQuantity} onChange={(e: any) => { if (/^\d*$/.test(e.target.value)) { setinwardQuantity(e.target.value) } }} className='col-8 text-right' /> */}
                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' > Mail Content</span>
                                            <div className='col-9 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <CreatableSelect
                                                ref={mailcontentRef}
                                                    isClearable
                                                    options={optionsMailContent}
                                                    onChange={handlemailcontent}
                                                    onCreateOption={handleCreateOptionMailContent}
                                                    // onChange={handleOptionChangeMailContent}
                                                    value={{ value: mailContent, label: mailContent }}
                                                    placeholder="Enter or select a Mail content"
                                                    onKeyDown={(event:any) => {
                                                        if (event.key === 'Enter' && mailcontentRef.current) {
                                                          event.preventDefault();  
                                                          const selectedOption = mailcontentRef.current.state.focusedOption ;  
                                                          if (selectedOption) {
                                                            if(mailcontentRef.current.state.focusedOption.__isNew__){
                                                                handlemailcontent({value: selectedOption.value, label : selectedOption.value});
                                                            } else {
                                                                handlemailcontent({value: selectedOption.value, label : selectedOption.label});
                                                            }
                                                          } 
                                                          handleKeyDown(event, remarkRef); 
                                                        }
                                                    }}
                                                />


                                                {/* <CreatableSelect
                                            inputValue={mailContent}
                                            options={optionsMailContent}
                                            onInputChange={handleInputChangeMailContent}
                                            onChange={handleOptionChangeMailContent}
                                            onCreateOption={handleCreateOptionMailContent}
                                            value={selectedOptionsmailContent}
                                            placeholder="Enter or select a Mail Content"
                                        /> */}
                                            </div>
                                            {/* <input type="text" value={mailContent} onChange={(e) => setmailContent(e.target.value)} className='col-9' /> */}
                                        </div>
                                        <div className='col-12 inline' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' > Remark </span>
                                            <input type="text"  ref={remarkRef} value={remark} onChange={(e) => setremark(e.target.value)} className='col-9'
                                            //   onKeyDown={(event:any) => {
                                            //     if (event.key === 'Enter' && remarkRef.current) {
                                            //       event.preventDefault();  
                                            //       const selectedOption = remarkRef.current.value ;  
                                            //       if (selectedOption) {
                                            //         setremark(selectedOption);
                                            //       } 
                                            //       handleKeyDown(event, consigneeRef); 
                                            //     }
                                            // }} 
                                            onKeyDown={(e) => handleKeyDown(e, consigneeRef)}
                                             />
                                        </div>

                                    </div>
                                    <div className='col-5' style={{ paddingTop: '5px' }}>
                                        <div className='inline'>
                                            <div className='col-12 row inline'>
                                                <span className='col-3'>Consignee</span>
                                                <div className='col-9 inline' style={{marginLeft : '-15px'}}>
                                                <Select
                                                        ref={consigneeRef}
                                                        onChange={(selectedOption) => {consigneeChange(selectedOption);
                                                           
                                                        }}
                                                        onFocus={consigneeChange}
                                                        options={allEmployee}
                                                        value={{ value: employeeId, label: employeeName }}
                                                        placeholder="Please Select a Consignee"
                                                        className='col-12'
                                                        components={{ Option: customOption }}
                                                        styles={customStylesOption}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter' && consigneeRef.current) {
                                                            event.preventDefault();
                                                            const selectedOption = consigneeRef.current.state.focusedOption;
                                                            if (selectedOption) {
                                                                consigneeChange(selectedOption);
                                                            }
                                                            handleKeyDown(event, submitButtonRef);
                                                            }
                                                        }}
                                                        />
                                                    <div className='col-1 inline'>
                                                        <a href="./employee" target='_blank'  > <i className='fa fa-plus'></i> </a>
                                                        <a onClick={getAllEmployee}  > <i className='fa fa-refresh'></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: lockdiv }} className='col-1'>
                                                <button className={"col-12" + (consigneelockUnlock) ? "themeColor" : "greenBackground"} style={{ backgroundColor: (consigneelockUnlock) ? '' : 'green', color: 'white', textAlign:'center', alignItems:'center', display:'flex',  height:'100%' }} onClick={lockConsignee}>&nbsp;&nbsp; {(consigneelockUnlock) ? <i className='fa fa-lock'></i> : <i className='fa fa-unlock'></i>}</button>
                                            </div>
                                        </div>
                                        {/* <div className='col-12 row' style={{ paddingTop: '5px' }}><span className='col-3' style={{ fontWeight: '500' }}> Emp. Name: </span> <span className='col-9'>{employeeName} </span></div> */}
                                        <div className='col-12 row' style={{ paddingTop: '5px' }}>
                                            <span className='col-3' style={{ fontWeight: '500' }}>Branch : </span> <span className='col-9'>{branch} <span> &nbsp; &nbsp; &nbsp; Department : {departmentName}</span> </span>
                                        </div>
                                        {/* <div className='col-12 row'><span className='col-4' style={{fontWeight: '500'}}> Employee Id: </span> <span   className='col-8'>{employeeId} </span> </div> */}
                                        <div className='col-12 row' style={{ paddingTop: '5px' }}> <span className='col-3' style={{ fontWeight: '500' }}> Email Id: </span> <span className='col-9'>{emailId} </span> </div>
                                        <div className='col-12 row' style={{ paddingTop: '5px' }}>  <span className='col-3' style={{ fontWeight: '500' }}> Phone / Ext: </span> <span className='col-9'>{phoneNumber} / {extension} </span> </div>
                                        <div className='col-12 row' style={{ paddingTop: '5px' }}><span className='col-3' style={{ fontWeight: '500' }}> Route : </span> <span className='col-9'>{routeName}  </span></div>
                                    </div>
                                </div>
                            </div>)}

                        <div className='width form' style={{ display: 'none' }}>
                            <div className='col-12 form-section-1'>
                                <div className='sec4-cols'>
                                    <div className='sec4-labels1 '><label className='inword-label'>Emp Id</label>
                                        <input className='col-8 paddingzero' type="text" value={employeeId}></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Email Id</label>
                                        <input className='col-8 paddingzero' type="email" value={emailId} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Extension</label>
                                        <input className='col-8 paddingzero' type="text" value={extension} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Mobile</label>
                                        <input className='col-8 paddingzero' type="text" value={phoneNumber} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Department</label>
                                        <input className='col-8 paddingzero' type="text" value={department}></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Division</label>
                                        <input className='col-8 paddingzero' type="text" value={division} ></input>
                                    </div>
                                </div>
                                <div className='sec4-cols' >
                                    <div className='sec4-labels1' style={{ marginTop: "-15px" }}><label className='inword-label'>City Name</label>
                                        <input className='col-8 col-sm-4 zeroPadding' type="text" value={empcity}></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Company</label>
                                        <input className='col-8 paddingzero' type="text" value={company} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Floor</label>
                                        <input className='col-8 paddingzero' type="text" value={floor} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Route Name</label>
                                        <input className='col-8 paddingzero' type="text" value={routeName} ></input>
                                    </div>
                                    <div className='sec4-labels1'><label className='inword-label'>Remark</label>
                                        <input className='col-8 paddingzero' type="text" value={remark} ></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btn d-flex justify-content-center'>
                            {(state === null) ? <>  
                              <button className="btn2 col-2 col-sm-1" ref={submitButtonRef} type="button"  disabled={isButtonDisabled} onClick={handleSubmit} ><i className="fa fa-save"></i> Save</button> </> : <>
                                <button className="btn2 col-2 col-sm-1" onClick={handleEdit} ><i className="fa fa-edit"></i> Update </button>
                                <button className="btn2 col-2 col-sm-1" onClick={handleDelete}> <i className="fa fa-trash"></i> Delete</button></>}
                            <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                            <Link className="btn2 col-2 col-sm-1" to="/inwardloglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>



    );
} export default InwordDetails;