import { NavLink } from 'react-router-dom';

const InwardContainerHeader = () => {
 
    return (
        <div className='row col-12' >
<ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
    <NavLink   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          }
          to="/inwardloglist">Inward Log Entry</NavLink>
    <NavLink to="/inworddetailslist" className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Inward Detail Entry</NavLink>
    <NavLink to="/inwardemail"   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Inward Email</NavLink>
    <NavLink to="/inwarddrslist"   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Inward DRS</NavLink>
    {/* <NavLink to="/"   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Inward Outscan</NavLink> */}
    <NavLink to="/inwarddbstatus"   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Update DRS Status</NavLink>
          <NavLink to="/inwardbulkupdate"   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Update Bulk Status </NavLink>

    <NavLink to="/uploadinwardstatus"   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Status Upload</NavLink>
    <NavLink to="/inwardtracking"   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Inward Tracking Status</NavLink>
    <NavLink to="/helpdesktracking"   className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } >Inward HelpDesk</NavLink>
</ul>
          
        </div>
    )
}

export default InwardContainerHeader