import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import MasterHeader from './masterHeader';
import Footer from '../headerfooter/Footer';
import '../Masters/master1.css'
function MasterHome() {
    const [modeName, setmodeName] = useState('');
    return (
        <>
            <MasterHeader />
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>          
          <div className="container col-11">
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Masters </p></div>
          </div>
            </div>
            <Footer />
        </>

    )
}

export default MasterHome