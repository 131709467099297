import { useEffect, useState } from 'react'
import MasterHeader from '../masterHeader'
import locationService from '../../../services/locationData.service'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Select from 'react-select'
import LocationHeader from './locationHeader'
import MasterDataService from '../../../services/masterData.service'

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    // none of react-select's styles are passed to <Control />
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}
const PincodeMaster = () => {

  const [zipCode, setzipCode] = useState("");
  const [cityTableId, setcityTableId] = useState(0);
  const [cityLabel, setcityLabel] = useState("");
  const [cityvalue, setcityvalue] = useState([]);
  const [stateTableId, setstateTableId] = useState(0);
  const [stateLabel, setstateLabel] = useState("");
  const [allCountry, setAllCountry] = useState([])
  const [countryTableId, setcountryTableId] = useState(0);
  const [countryName, setcountryName] = useState('');

  const [statevalue, setstatevalue] = useState([]);

  const { state } = useLocation();

  

  
  useEffect(() => {
    getCountry();
  }, [])
  const getCountry = async () => {
    await locationService.getCountry().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.countryName
          }))
          await setAllCountry(options)
          if (state === null) {
            await setcountryTableId(data[0]['id'])
            await setcountryName(data[0]['countryName'])
          } 
        }
      }
    )
  }
  const setCountry = async () => {
    let countryNames = allCountry.find((item: any) => {
      return item['value'] === countryTableId
    });
    if (countryNames !== undefined) {
      await setcountryName(countryNames['label'])
    }
  }
  useEffect(()=>{
    setCountry() 
  },[allCountry, countryTableId])
  useEffect(() => {   
    getState(countryTableId);
  }, [countryTableId])

 // get state values
 const getState = async (value: Number) => {
  if (value !== 0) {
    await MasterDataService.getState(value).then(
      (data) => {
        const statesList = data.map((d: any) => ({
          "value": d.id,
          "label": d.stateName
        }))
        setstatevalue(statesList)
        if (state === null) {
          if (data[0]) {
            setstateTableId(data[0]['id']);
            setstateLabel(data[0]['stateName'])
          }
        }
      }
    )
  }
}
const setState = () => {
  let stateNames = statevalue.find((item: any) => {
    return item['value'] === stateTableId
  });
  if (stateNames !== undefined) {
    setstateLabel(stateNames['label'])
  }
}
useEffect(()=>{
  setState()
},[stateTableId, statevalue])

  useEffect(() => {  
    getCity(stateTableId);
  }, [stateTableId])
  
  // get city values
  const getCity = async (value: Number) => {
    if (value !== 0) {
      await MasterDataService.getCity(value).then(
        (data) => {
          const cityList = data.map((d: any) => ({
            "value": d.id,
            "label": d.cityName
          }))
          setcityvalue(cityList)
          if (state === null) {
            if (data[0]) {
              setcityTableId(data[0]['id'])
              setcityLabel(data[0]['cityName'])
            }
          }
        }
      )
    }
  }

  useEffect(()=>{
 setCity();
  },[cityTableId, cityvalue])

  const setCity =() => {
    let cityName = cityvalue.find((item: any) => {
      return item['value'] === cityTableId
    });
    if (cityName !== undefined) {
      setcityLabel(cityName['label'])
    }
  }

  const navigate = useNavigate();

  const HandleSubmit = () => {
    if(zipCode !== ""){
    locationService.postPincode(zipCode, cityTableId, stateTableId, countryTableId).then(
      (data) => {
        if (data) {
          navigate('../pincodelist');
        }
      }
    ).catch((error) => {
      console.log(error);
    }) }
    else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: "Please Enter Pin Code",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: 'swal-fire-alert', // Add your custom CSS class here
        },
      }) ;
    }
  }
  // edit the selected data
  const HandleEdit = () => {
    if(zipCode !== "") {
    locationService.putPincode(zipCode, cityTableId, stateTableId, countryTableId, state).then(
      (data) => {
        if (data) {
          navigate('../pincodelist');
        }
      }
    ).catch((error) => {
      console.log(error);
    }) }
    else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: "Please Enter Pin Code",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: 'swal-fire-alert', // Add your custom CSS class here
        },
      }).then(
        ()=>{
          navigate('../pincodemaster', { state: state });
        }
      );
    }
  }
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          locationService.deletePincode(state).then(
            (data) => {
              if (data.statuscode.message === '200') {
              }
            }
          ).catch((error) => {
            console.log(error);
            // console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Pincode has been deleted",
          }).then(function () {
            navigate('../pincodelist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Pincode is safe :)",
            }).then(function () {
              navigate('../pincodemaster');
            }
            )
        }
      })
  }

  const HandleCancel = () => {
    setzipCode("");
    setstateTableId(0);
  }
  useEffect(() => {
    //  get pincode by Id
  const getPincodeById = async () => {
    if (state) {
      try {
        const data = await locationService.getPincodeById(state);
        if (data) {          
          await setzipCode(data.zipCode);
          await setstateTableId(data.stateTableId);
          await setcountryTableId(data.countryTableId);
          await setcityTableId(data.cityTableId);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

    getPincodeById();
  }, [state])

  const handleCountryChange = async (e: any) => {
    await setcountryTableId(e.value)
    await setcountryName(e.label)
  }
  const handleStateChange = async (e: any) => {
    await setstateLabel(e.label)
    await setstateTableId(e.value)
  };
  const handleCityChange = async (e: any) => {
    await setcityTableId(e.value)
    await setcityLabel(e.label)


  };
  return (
    <>
      <MasterHeader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>Pincode Details </p></div>
          <LocationHeader />
          <div className='col-sm-12 inputs'>
            <label className='col-6 col-sm-2'><b> Country </b></label>
            <Select
              name="countryTableId"
              onChange={handleCountryChange}
              options={allCountry}
              className='col-8 col-sm-4 select'
              styles={customStyles}
              value={{ value: countryTableId, label: countryName }}
            />
          </div>
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b> State </b></label>
            <Select
              name="stateName"
              onChange={handleStateChange}
              options={statevalue}
              className='col-8 col-sm-4 select'
              styles={customStyles}
              value={{ label: stateLabel, value: stateTableId }}
            />
          </div>
          <div className='col-sm-12 inputs'>
            <label className='col-6 col-sm-2'><b> City</b></label>
            <Select
              name="city"
              onChange={handleCityChange}
              options={cityvalue}
              className='col-8 col-sm-4 select'
              styles={customStyles}
              value={{ label: cityLabel, value: cityTableId }}
            />
          </div>
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b>Pin/Zip Code / Name (if international)</b></label>
            <input type='text' className='col-8 col-sm-4' value={zipCode} name='zipCode' onChange={e => setzipCode(e.target.value)} />
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to=""> <i className="fa fa-edit"> </i>   Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i>   Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/pincodelist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default PincodeMaster
