// import '../inward/inwarddetail.css';
// import '../outward/outwardStatus.css';
import OutwardContainerHeader from './Outcontainerheader';
import { useEffect, useState } from "react";
import Outwarddetailsercice from "../../services/outwarddetail.sercice";
import OutwardLogService from '../../services/outwardlog.service';
import MasterDataService from '../../services/masterData.service';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';

// import paginationFactory from 'react-bootstrap-table2-paginator';
// import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
// import filterFactory from 'react-bootstrap-table2-filter';
// import Branch from '../Settings/branch';
import Swal from 'sweetalert2';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';
import AsyncSelect from 'react-select/async';



function OutwardTracking() {
  const navigate = useNavigate();
  const [batchNo, setbatchNo] = useState("");
  const [logDateTime, setLogDateTime] = useState("");
  const [mode, setmodeId] = useState("");
  const [AwbNo, setAwbNo] = useState("");
  const [NumberOfPcs, setNumberOfPcs] = useState("");
  const [LogRemark, setLogRemark] = useState("");
  const [OsrNo, setOsrNo] = useState("");
  const [DetailUser, setDetailUser] = useState("");
  const [StatusUser, setStatusUser] = useState("");
  const [EntryBranch, setEntryBranch] = useState("");
  const [Consignor, setConsignor] = useState("");
  const [City, setCity] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Reference, setReference] = useState("");
  const [Employee, setEmployee] = useState("");
  const [Status, setStatus] = useState("");
  const [Email, setEmail] = useState("");
  const [Department, setDepartment] = useState("");
  const [statuscode, setstatuscode] = useState("");
  const [RecTime, setRecTime] = useState("");
  const [statusRemark, setstatusRemark] = useState("");
  const [manifetNumber, setmanifetNumber] = useState("");
  const [RecBy, setRecBy] = useState("");
  const [routeName, setRouteName] = useState("");
  const [Branch, setBranch] = useState("");
  const [travellingdetails, setTravellingdetails] = useState([])
  const [deliverySign, setdeliverySign] = useState("")
  const [statusupdatediv, setstatusupdatediv] = useState('none')

  const [costCenterName, setcostCenterName] = useState("");
  const [productName, setproductName] = useState("");
  const [zoneName, setzoneName] = useState("");
  const [volumetricWeight, setvolumetricWeight] = useState("");
  const [actualweight, setactualweight] = useState("");
  const [chargableWeight, setchargableWeight] = useState("");
  

  

  const [consigneePhoneNumber, setconsigneePhoneNumber] = useState("");
  const [consigneeAreaName, setconsigneeAreaName] = useState("");






  const [legUser, setLegUser] = useState('');


  const [searchQuery, setSearchQuery] = useState('');
  const [mailContent, setmailContent] = useState();
  const [selectedRecords, setSelectedRecords] = useState();
  const [inwardDateSearch, setinwardDateSearch] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allOutwardDetails, setAllOutwardDetails] = useState([]);
  const [statusdate, setstatusdate] = useState((moment(new Date())).format('YYYY-MM-DD'))
  const [statusTime, setstatusTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
  const [receivedByname, setReceivedByname] = useState(false);
  const [receivedBy, setreceivedBy] = useState("");
  const [statusremark, setstatusremark] = useState('')
 // const [outwarddetailStatus, setoutwarddetailStatus] = useState(0);
  const [statusReasons, setstatusReasons] = useState([]);
  const [ReasonLabel, setReasonLabel] = useState('');
  const [outwarddetailid, setoutwarddetailid] = useState(0);
  const [outwarddetailStatus, setoutwarddetailStatus] = useState(0);
  const [ReasonId, setReasonId] = useState(0); 
   const [manifestId, setmanifestId] = useState(0);
   const [manifestDate ,setmanifestDate] = useState('')

   const [redirectempId,setredirectempId] = useState(0)
  const [redirectempName,setredirectempName] = useState("")
  const [employeeList, setemployeeList] = useState([]);
  const [emptab,setemptab] = useState(false);
  const [redirectempdiv,setredirectempdiv] = useState(false);

  const [redirectEmployee,setredirectEmployee] = useState("");
  const [redirectDepartment,setredirectDepartment] = useState("");
  const [redirectBranch,setredirectBranch] = useState("");
  const [redirectRouteName,setredirectRouteName] = useState("");
  const [Redirectedemptext,setRedirectedemptext] = useState("");

  const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setoutwarddetailStatus(Number(event.target.value));
    if (event.target.value === '9') { setReasonId(0) }
    if (event.target.value === '8') {
      setReceivedByname(true)
    } else {
      setReceivedByname(false)
    }
    if (event.target.value === '13') {
      setemptab(true)
    } else {
      setemptab(false)
    }
  };

  const { state } = useLocation();

  //Table End  

  const getTrackingdetails = () => {
    travellinghistory(AwbNo)
  }
  // const getTrackingdetailsOnline = () => {
  //   Outwarddetailsercice.trackOnline(AwbNo).then(
  //     (data)=>{
  //       console.log(data);
  //     }
  //   )
  // }
  useEffect(() => {
    if (state) {
      travellinghistory(state)
    }
  }, [])
  const travellinghistory = async (AWB: any) => {
    if (AWB !== "" || AWB !== null) {
      await Outwarddetailsercice.travellinghistory(AWB).then(
        (data) => {
          if (data !== undefined) {
            setoutwarddetailid(data.id)
            setbatchNo(data.batchNo);
            if (data.logDateTime !== "") {
              setLogDateTime(moment(data.logDateTime).format("DD/MM/YYYY HH:MM:SS A"));
            }

            setcostCenterName(data.costCenterName);
            setproductName(data.productName);
            setzoneName(data.zoneName);
            setvolumetricWeight(data.volumetricWeight);
            setactualweight(data.actualWeight);
            setchargableWeight(data.chargableWeight);



            setmodeId(data.modeName);
            setmodeId(data.modeName);
            setAwbNo(data.AWB);
            setNumberOfPcs(data.numberOfPieces);
            setLogRemark(data.remark);
            setOsrNo(data.osrNumber);
            setmanifetNumber(data.manifestNumber)
            setmanifestId(data.manifestId)
            if(data.manifestDate !== ""){
              setmanifestDate((moment(data.manifestDate)).format('DD/MM/YYYY'))
            }
           
            // setTerminal(data.);
            setLegUser(data.createdBy);
            setDetailUser(data.createdBy);
            setStatusUser(data.createdBy);
            setEntryBranch(data.branch);
            setConsignor(data.consigneeName);
            setconsigneeAreaName(data.consigneeAreaName);
            setconsigneePhoneNumber(data.consigneePhoneNumber)
            setcompanyName(data.companyName);
            setCity(data.consigneeCityName);
            setPincode(data.consigneeZipCode);
            setReference(data.refNo);
            setEmployee(data.employeeName);
            setDepartment(data.employeeDepartment);
            setRouteName(data.employeeRoute)
            setBranch(data.employeeBranch)

            setmailContent(data.mailContent)
            //  setEmail(data.);
            // setCc(data);
            setStatus(data.outwarddetailStatus);
            setRecBy(data.receivedBy);
            setstatusRemark(data.statusRemark)
            if(data.deliveryDateTime !== "") {  setRecTime((data.deliveryDateTime));}
            setTravellingdetails(data.travellingDetails)
            setdeliverySign(data.deliverySign)
            console.log(data.outwarddetailStatus);
            if (data.outwarddetailStatus === 'Delivered' || data.outwarddetailStatus === 'Unclaimed' || data.outwarddetailStatus === 'On Hold' || data.outwarddetailStatus === 'Undelivered') {
              setstatusupdatediv('none')
            }
            else
            {
              setstatusupdatediv('block')
            }
           // console.log("data.travellingDetails", data.travellingDetails)
            //    setRecDtTm(data.);
            //setHfNo(data);
            //    setStatusRemark(data);
          } else {
            if (AwbNo !== "") {
              Swal.fire({
                title: "Not Found",
                icon: 'warning',
                text: "Searching AWB Details Not Found Please Check",
              })
            }
            // alert("AWB Details Not Found Please Check")
          }

        }
      );
    }
  }

  const filteredData = Array.isArray(allOutwardDetails)
    ? allOutwardDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];

  const UpdateStatus = () => {
    if (outwarddetailStatus !== null) {
      let deliverySignbase64 = "";
      let outwarddetailidArray = [];
      outwarddetailidArray.push(outwarddetailid)
      OutwardLogService.postupdateoutwarddetailstatus(outwarddetailidArray, outwarddetailStatus, receivedBy, statusremark, statusdate, statusTime, deliverySignbase64,  ReasonId, ReasonLabel, manifestId).then(
        (data) => {
          if (data) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "Outward Status Updated Successfully",
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                title: 'swal-fire-alert', // Add your custom CSS class here
              },
            }).then(function () {
              navigate('/outwarddetailslist', { state: AwbNo });
            });
          }
        }
      ).catch((error) => {
        console.log(error);
        console.log("server responding");
      })
    }
  }

  useEffect(() => {
    getRtoReasons()
  }, [outwarddetailStatus])
  const getRtoReasons = () => {
    InwardDeliverySheet.getRtoReasons(outwarddetailStatus, 1).then(
      (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.returnReason
          }))
          setstatusReasons(options)
        }
      }
    ).catch((error) => {
      console.log(error);
      console.log("server responding");
    })
  }
  const handleInputChangeAwb = async (input: any) => {
    if (input.length > 3) {
      return await Outwarddetailsercice.getAwbNumberbyAutoComplte(input).then(
        (data) => {
          const awbNumberList = data.map((d: any) => ({
            "value": d.AWB,
            "label": d.AWB
          }))
          return awbNumberList;
        }
      )
    }
  };

  useEffect(() => {
    getAllEmployee()
  }, [outwarddetailStatus])
  
  const getAllEmployee = async () => {
    await MasterDataService.getEmployeeList().then(
        (data) => {
            if (data) {
                const options = data.map((d: any) => ({
                    "value": d.id,
                    "label": d.employeeName
                }))
                setemployeeList(options);
                // setallSelections(options)
            }
        }
    );
}

  return (
    <>


      <div style={{ margin: "0px" }}>
        <div className='inline'>
          <div className='col-9'> <OutwardContainerHeader /></div>
          <div className='col-3 inline'>
          <AsyncSelect
                          name="AwbNo"
                          // onInputChange={(input) => setInputValue(input)}
                          onChange={(e: any) => { setAwbNo(e.value); travellinghistory(e.value) }}
                          loadOptions={handleInputChangeAwb}
                          value={{ value: AwbNo, label: AwbNo }}
                          className='col-9'
                        />
             {/* <input type="text"  style={{ padding: '4px', width : '60%' }} value={AwbNo} onChange={(e) => { setAwbNo(e.target.value) }} />  */}
             {/* <button className='inwordbtn col-3' onClick={getTrackingdetails}> <i className='fa fa-search'> Search </i> </button>  */}
          {/* <button className='inwordbtn' onClick={getTrackingdetailsOnline}> <i className='fa fa-search'>Online</i> </button> */}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Travelling Details </p></div>

              <div className='col-12 inline'>
                <div className='col-4'>
                  <table className='col-12'>
                    <thead>
                      <tr className='themeColor'>
                        <td colSpan={2}>
                          Shipper Details
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Batch No :  </td>
                        <td>  {batchNo}</td>
                      </tr>
                      <tr>
                        <td>Log Dt/Tm:  </td>
                        <td>  {logDateTime}</td>
                      </tr>
                      <tr>
                        <td>Awb No : </td>
                        <td>  {AwbNo}</td>
                      </tr>
                      <tr>
                        <td>Mode : </td>
                        <td> {mode}</td>
                      </tr>
                      <tr>
                        <td>NO of Pcs: </td>
                        <td>  {NumberOfPcs}</td>
                      </tr>
                      <tr>
                        <td>Actual Weight: </td>
                        <td>  {actualweight}</td>
                      </tr>
                      <tr>
                        <td>Volumetric Weight: </td>
                        <td>  {volumetricWeight}</td>
                      </tr>
                      <tr>
                        <td>Chargable Weight: </td>
                        <td>  {chargableWeight}</td>
                      </tr>
                      <tr>
                        <td>Zone:</td>
                        <td> {zoneName}</td>
                      </tr>
                      <tr>
                        <td>Cost Center:</td>
                        <td> {costCenterName}</td>
                      </tr>
                      <tr>
                        <td>Product:</td>
                        <td> {productName}</td>
                      </tr>                  
                      <tr>
                        <td>Log Remark:</td>
                        <td>{LogRemark}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className='col-12'>

                    <thead>
                      <tr className='themeColor'>  <td colSpan={2}> Entry User Details</td>  </tr>
                    </thead>
                    <tbody>
                      <tr> <td>OSR No :</td> <td>{OsrNo}</td></tr>
                      <tr> <td>Log User :</td> <td>{DetailUser}</td></tr>
                      <tr> <td>Entry Branch :</td> <td>{EntryBranch}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div className='col-4' style={{ borderLeft: '2px solid #ccc' }}>
                  <table>
                    <thead>
                      <tr className='themeColor'>
                        <td colSpan={2}> Employee Details</td>
                      </tr>
                    </thead>
                    <tbody>
                    <tr><td>Employee:</td><td>{Employee}</td></tr>
                      <tr><td>Depart:</td><td>{Department}</td></tr>
                      <tr><td>Branch:</td><td>{Branch}</td></tr>
                      <tr><td>Route:</td><td>{routeName}</td></tr>
                   </tbody>
                  </table>
                </div>
                <div className='col-4' style={{ borderLeft: '2px solid #ccc' }}>
                  <table className='col-12'>
                    <thead>
                      <tr className='themeColor'>
                        <td colSpan={2}>
                          Consignee Details
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                    <tr> <td>Consignee: </td> <td>{Consignor}</td></tr>
                      {/* <tr> <td>Company : </td> <td>{companyName}</td></tr> */}
                      <tr> <td>City: </td> <td>{City}-{Pincode}</td></tr>
                      <tr> <td>Area: </td> <td>{consigneeAreaName}</td></tr>
                      <tr> <td>Phone No: </td> <td>{consigneePhoneNumber}</td></tr>
                      <tr> <td>Reference: </td> <td>{Reference}</td></tr>
                      <tr>  <td>Mail Content:</td>  <td>{mailContent}</td> </tr>
                      <tr> <td>Remark: </td> <td>{LogRemark}</td></tr>
                    </tbody>
                  </table>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr className='themeColor'>
                        <td colSpan={3}>Status details</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td >Status:</td>
                        <td>{Status}</td>
                        <td rowSpan={5} style={{ border: "2px solid #ccc" }}>
                          {(deliverySign) && (<img src={process.env.REACT_APP_BASE_UPLOAD_API + "outwarddetail/deliverySign/" + deliverySign} width="140px" />
                          )}</td></tr>
                      <tr><td>Rec By:</td><td>{RecBy}</td></tr>
                      <tr><td>Rec Dt/Tm:</td><td>{RecTime}</td></tr>
                      <tr><td>Manifest No:</td><td>{manifetNumber} {manifestDate}</td></tr>
                      <tr><td>Status Remark:</td><td>{statusRemark}</td></tr>

                    </tbody>
                  </table>
                </div>

              </div>
              <div className='col-12 inline'>
                <div className='col-8 incolumn'>
                  <div className='data-bottom'>
                    <div className="">
                      <table className="table1 container">
                        <thead className="thead" style={{}}>

                          <tr style={{ textAlign: "left", border: "1px solid grey" }}>
                            <th scope="col">Sr. No</th>
                            <th scope="col"  >Date</th>
                            <th scope="col"  >Scan Details</th>
                            <th scope="col">Log Entry By</th>
                          </tr>

                        </thead>


                        <tbody className="width scroll-table " style={{ border: "1px solid grey" }}>
                          {travellingdetails?.map((travels, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{moment(travels['travellingDate']).format("DD/MM/YYYY")}</td>
                              <td>{travels['statusCodeDescription']}</td>
                              <td>{travels['createdByName']}</td>
                            </tr>
                          ))}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-4 incolumn' style={{ display: statusupdatediv }} >

                  <span className='themeColor'>Update Status Details</span>
                  <div style={{ marginTop: "2px" }}></div>
                  {/* <table>
                    <tbody>
                      <tr>
                        <td>
                          <label className='Radiobtn col-2 inline'>
                            <input type='radio' className='radio-btn'
                              value="8"
                              name="deliveryStatus"
                              checked={outwarddetailStatus === 8}
                              onChange={handleOptionChange1}
                            /> Delivered</label>
                        </td>
                        <td>
                          <label className='Radiobtn col-2 inline'><input type='radio' className='radio-btn'
                            value="11"
                            name="deliveryStatus"
                            checked={outwarddetailStatus === 11}
                            onChange={handleOptionChange1}

                          /> Undelivered</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                            value="16"
                            name="deliveryStatus"
                            checked={outwarddetailStatus === 16}
                            onChange={handleOptionChange1}
                          /> OnHold</label>
                        </td>
                        <td>
                          <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                            value="13"
                            name="deliveryStatus"
                            checked={outwarddetailStatus === 13}
                            onChange={handleOptionChange1}
                          /> Redirected</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='Radiobtn col-2'>
                            <input type='radio' className='radio-btn'
                              value="14"
                              name="deliveryStatus"
                              checked={outwarddetailStatus === 14}
                              onChange={handleOptionChange1}
                            /> Unclaimed</label>
                        </td>
                        <td>

                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="date" value={statusdate} onClick={(e: any) => { setstatusdate(e.value) }} />
                        </td>
                        <td>
                          <input type="time" value={statusTime} onClick={(e: any) => { setstatusTime(e.value) }} />
                        </td>

                      </tr>
                      {receivedByname && (<tr>
                        <td colSpan={2}>
                          <input type="text" value={receivedBy} onClick={(e: any) => { setreceivedBy(e.value) }} placeholder='Received by Name' />
                        </td>
                      </tr>)}

                      {(statusReasons.length !== 0) && (
                        <tr>
                          <td colSpan={2} className='select' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            <Select
                              name="ReasonId"
                              onChange={(e: any) => { setReasonId(e.value); setReasonLabel(e.label) }}
                              options={statusReasons}
                              value={{ value: ReasonId, label: ReasonLabel }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan={2}>
                          <input type="text" value={statusremark} onClick={(e: any) => { setstatusremark(e.value) }} placeholder='Remark if any' />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ textAlign: 'right' }}>
                          <button className='btn2' style={{ textAlign: 'right' }} onClick={UpdateStatus}> Update Status </button>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
 <table>
                    <tbody>
                      <tr>
                        <td>
                          <label className='Radiobtn col-2 inline'>
                            <input type='radio' className='radio-btn'
                              value="8"
                              name="deliveryStatus"
                              checked={outwarddetailStatus === 8}
                              onChange={handleOptionChange1}
                            /> Delivered</label>
                        </td>
                        <td>
                          <label className='Radiobtn col-2 inline'><input type='radio' className='radio-btn'
                            value="9"
                            name="deliveryStatus"
                            checked={outwarddetailStatus === 9}
                            onChange={handleOptionChange1}

                          /> Undelivered</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                            value="16"
                            name="deliveryStatus"
                            checked={outwarddetailStatus === 16}
                            onChange={handleOptionChange1}
                          /> OnHold</label>
                        </td>
                        {/* <td>
                          <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                            value="13"
                            name="deliveryStatus"
                            checked={outwarddetailStatus === 13}
                            onChange={handleOptionChange1}
                          /> Redirected</label>
                        </td> */}
                      </tr>
                      {/* <tr>
                        <td>
                          <label className='Radiobtn col-2'>
                            <input type='radio' className='radio-btn'
                              value="14"
                              name="deliveryStatus"
                              checked={outwarddetailStatus === 14}
                              onChange={handleOptionChange1}
                            /> Unclaimed</label>
                        </td>
                        <td>

                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <input type="date" max={moment().format("YYYY-MM-DD")} value={statusdate} onChange={(e: any) => { setstatusdate(e.value) }} />
                        </td>
                        <td>
                          <input type="time" value={statusTime} onChange={(e: any) => { setstatusTime(e.value) }} />
                        </td>

                      </tr>
                      {receivedByname && (<tr>
                        <td colSpan={2}>
                          <input type="text" value={receivedBy} onChange={(e: any) => {  setreceivedBy(e.target.value) }} placeholder='Received by Name' />
                        </td>
                      </tr>)}
                      {(statusReasons.length !== 0) && (
                        <tr>
                          <td colSpan={2} className='select' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            <Select
                              name="ReasonId"
                              onChange={(e: any) => { setReasonId(e.value); setReasonLabel(e.label) }}
                              options={statusReasons}
                              value={{ value: ReasonId, label: ReasonLabel }}
                            />
                          </td>
                        </tr>
                      )}

                  
                      <tr>
                        <td colSpan={2}>
                          <input type="text" value={statusremark} onChange={(e: any) => { setstatusremark(e.target.value) }} placeholder='Remark if any' />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ textAlign: 'right' }}>
                          <button className='btn2' style={{ textAlign: 'right' }} onClick={UpdateStatus}> Update Status </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
          </div>


        </div>


      </div>
    </>



  );
} export default OutwardTracking;
