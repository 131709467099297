import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
// const createdBy = localStorage.getItem('userId');
// const updatedBy = localStorage.getItem('userId');
const mailRoomId = localStorage.getItem('mailRoomId');
const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";

const config = {
    headers: {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
};
const validateAWB = () => {
    const bodyParamter = {
        mailRoomId,
        branch
    }
// console.log("bodyParamter", bodyParamter)
    return axios.post(API_URL + "ratecontract/getratesbyproductandzones", bodyParamter, config).then(
        (response) => {
            if (response.data.statuscode === "200") {
                // console.log("response.data.message", response.data.message)
                return response.data.message
            }
        }
    ).catch(error => { return "" }) 
}

const OutwardScanService = {
  
    validateAWB,

}

export default OutwardScanService;