import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const LOCAL_URL = process.env.REACT_APP_PUBLIC_URL;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy =  localStorage.getItem('userId');
// const updatedBy =  localStorage.getItem('userId');
const mailRoomId =  localStorage.getItem('mailRoomId');
const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const config = {
  headers: {
    'Authorization': token,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  }
};
const getInwardData = async (department : Number, routeId :Number, inwardDateSearch:any) =>{
  interface MyObject {
    mailRoomId:any,
    branch:Number,
    department?:Number,
    routeName?: Number,
    inwardDate?:any
   }

let bodyParamter: MyObject = {
  mailRoomId,
  branch,
}
if(department !== 0)
  {
    bodyParamter = {
      ...bodyParamter,
      department,
  }
}
if(routeId !== 0)
  {
    bodyParamter = {
      ...bodyParamter,
      routeName:routeId,
  }
}
if(inwardDateSearch !== ""){
  bodyParamter = {
    ...bodyParamter,
    inwardDate:inwardDateSearch,
}
}
  
// const bodyParamenters = {
//     mailRoomId,
//     branch,
//     department:department,
//     routeName: routeId
//   }
  //  console.log("body ",bodyParamter)

  return await axios.post(API_URL +"inwarddetail/inwarddatabyroute", bodyParamter, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const postDrsData = async (routeId :number, departmentId:number, deliveredBy:number, drsDetails :any, 
  drsDate :string) => {
 const tCode = Date.now().toString();
  const drsDetailsParams = await drsDetails.map((data :any)=>{
    return {inwardDetailsId : data, tCode , mailRoomId : mailRoomId, branch : branch, createdBy : createdBy}
  });
  // console.log("drsDetailsParams", drsDetailsParams)
  const bodyParamenters = {
    routeId, 
    departmentId,
    branchId :branch,
    deliveredBy,
    drsDetails :drsDetailsParams,
    tCode ,
    mailRoomId,
    drsDate,
    drsStatus : 0,
    createdBy,
    branch,
    baseUrl:LOCAL_URL
  }
  return await axios.post(API_URL +"drs/register", bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}
const getDrsList =async (drsDate :any) => {
  const bodyParamenters = {
    mailRoomId,
    branch,
    drsDate
  }
  // console.log("bodyParamenters", bodyParamenters)
  return await axios.post(API_URL +"drs/drsByDate", bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}
const deleteDrs =async (id :Number) => {
  return await axios.delete(API_URL +"drs/"+ id, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const getDrsDetails = async (id: Number) => {
  return await axios.get(API_URL +"drs/"+ id, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const sendEmailNotification = async (inwardIdsByEmployee :any) => {
  const bodyParamenters = {
    mailRoomId,
    branch,
    inwardIdsByEmployee,
    createdBy,
    baseUrl : process.env.REACT_APP_PUBLIC_URL
  } 
  return await axios.post(API_URL +"inwarddetail/getmail", bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const getPendingDrs = async () => {
  const bodyParamenters = {
    mailRoomId,
    branch,
  }
  // console.log("bodyParamenters", bodyParamenters)
  return await axios.post(API_URL +"drs/pendingdrs", bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const getDrsDetailsbyId = async (id:Number) => {
  // console.log("bodyParamenters", bodyParamenters)
  return await axios.get(API_URL +"drs/pendingdrsdetails/"+id, config).then(
    (data) => { 
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}

const getRtoReasons =async (statusCodeTableId:Number, returnType : Number) => {
  const bodyParamenters = {
    statusCodeTableId,
    returnType,
  }
  return await axios.post(API_URL +"rto/",bodyParamenters, config).then(
    (data) => {
      if(data.data.statuscode === '200'){
      return data.data.message;
      }
    }
  ).catch(error =>console.log(error))
}
const getAwbNumberbyAutoComplte = (input : any) => {
  const bodyParamter = {
    mailRoomId,      
    branch,
    input
    };
return axios.post(API_URL + "inwarddetail/getawbNumber/", bodyParamter, config).then(
    (response) => {
        return response.data.message
    }
).catch(error => {return ""})  
}

const InwardDeliverySheet = {
  getInwardData,
  postDrsData,
  getDrsList,
  deleteDrs,
  getDrsDetails,
  sendEmailNotification,

  getPendingDrs,
  getDrsDetailsbyId,
  getRtoReasons,
  getAwbNumberbyAutoComplte
  }
  
  export default InwardDeliverySheet;