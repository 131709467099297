import '../headerfooter/headerfootercss.css';
import { Link } from 'react-router-dom';

function AccountHeader() {

  return (
    <>
      <div className='row col-12'>
        <ul className="nav nav-pills col-12" id="pills-tab" role="tablist">
          <Link className="navbar-item inwordbtn" to="/account" >Account </Link>
          <Link className="navbar-item inwordbtn" to="/outwardreconciliation" > Outward Validation </Link> 
          <Link className="navbar-item inwordbtn" to="/productlist" > Vendor Contract </Link>        
        </ul>
      </div>
    </>

  );
}
 export default AccountHeader;