import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import Swal from 'sweetalert2';
import './master1.css'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'; 
import filterFactory from 'react-bootstrap-table2-filter';

interface ReceiverProps {
  id: any;
}

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    // none of react-select's styles are passed to <Control />
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}

const Serviceprovider: React.FC<ReceiverProps> = ({ }) => {
  const { state } = useLocation();

  const [shipmentmode, setshipmentmode] = useState([])
  const [modeName, setmodeName] = useState('');
  const [modeId, setmodeId] = useState(0);
  const [serviceProviderName, setserviceProviderName] = useState('')
  const [contactPerson, setcontactPerson] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [contactPersonEmail, setcontactPersonEmail] = useState('');
  const [address, setAddress] = useState('');
  const [allCountry, setAllCountry] = useState([]);
  const [country, setCountry] = useState(0);
  const [countryName, setCountryName] = useState('');
  const [statevalue, setstatevalue] = useState([]);
  const [vendorAccounts, setvendorAccounts] = useState([]);
  const [stateName, setStateName] = useState(0)
  const [stateLabel, setStateLabel] = useState('')
  const [internationalService, setinternationalService] = useState(0)

  const [cityvalue, setcityvalue] = useState([]);
  const [city, setCity] = useState(0);

  const [cityLabel, setCityLabel] = useState('');
  const [pinvalue, setpinvalue] = useState([]);
  const [pincode, setPincode] = useState(0);
  const [pincodeLabel, setPincodeLabel] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [deleteButton, setdeleteButton] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [accountNumber, setaccountNumber] = useState('')
  const [showtable, setShowTable] = useState(false)

  const navigate = useNavigate();

  const HandleSubmit = () => {
    let errorMessage = ""
    if(serviceProviderName !== "" || modeId !== null){
    MasterDataService.PostServiceProvider(modeId, serviceProviderName, contactPerson, phoneNumber, contactPersonEmail, address, pincode, city, stateName, country).then(
      (data) => {
        if (data) {
          navigate("/serviceproviderlist");
        }
      }

    ).catch((error) => {
      console.log(error);
      console.log("server Responding");
    })
  } else {
    
    if ((serviceProviderName === "") && (modeId === null)) {
      errorMessage = "Please Enter Service Provider Name and Shipping Mode";
    } else if (serviceProviderName === "") {
      errorMessage = "Please Enter Service Provider Name";
    } else if (modeId === "") {
      errorMessage = "Please Enter Shipping Mode";
    }
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
  }
  const HandleEdit = () => {
    let errorMessage = ""
    if(serviceProviderName !== "" || modeId !== null){
    MasterDataService.PutServiceProvider(modeId, serviceProviderName, contactPerson, phoneNumber, contactPersonEmail, address, pincode, city, stateName, country, state).then(
      (data) => {
        if (data.statuscode === 200) {
          navigate("/serviceproviderlist");
        }
      }

    ).catch((error) => {
      console.log(error);
      console.log("server Responding");
    })
  } else {
    
    if ((serviceProviderName === "") && (modeId === null)) {
      errorMessage = "Please Enter Service Provider Name and Shipping Mode";
    } else if (serviceProviderName === "") {
      errorMessage = "Please Enter Service Provider Name";
    } else if (modeId === "") {
      errorMessage = "Please Enter Shipping Mode";
    }
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    }).then(
      ()=>{
        navigate('../serviceprovider', { state: state });
      }
    );
  }
  }

  // get service provider by id
  const GetserviceProvider = async () => {
   
    if (state) {
      setShowTable(true)
      try {
        await MasterDataService.GetserviceProviderId(state).then(
          async (data :any) => {
            await setserviceProviderName(data.serviceProviderName)
            await setphoneNumber(data.phoneNumber);
            await setcontactPerson(data.contactPerson);
            await setAddress(data.address)
            await setcontactPersonEmail(data.contactPersonEmail)
            await setCountry(data.countryId)
            await setStateName(data.stateId)
            await setPincode(data.zipCode)
            await setCity(data.cityId)
            await getcountry();
            await setmodeId(data.shipmentModeId) 
            getVendorAccounts();

          }
        );

      } catch (error) {
        console.error(error);
      }

    }
  };


  // get country values


  useEffect(() => {
    GetserviceProvider();
    getShipmentMode();
    getcountry();
  }, [])
  useEffect(() => {
    getState(country);
  }, [country])
  useEffect(() => {
    getCity(stateName);
  }, [stateName])
  useEffect(() => {
    getPincode(city);
  }, [city])
  useEffect(() => {
    getShipmentMode();
  }, [modeId])

  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
        async (data :any) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          await setshipmentmode(options)
          if (state === null) {
            if (data[0]) {
              await setmodeId(data[0]['id'])
              await setmodeName(data[0]['modeName'])
            }
          } else {
            let modesNames = data.find((item: any) => {
              return item.id === modeId
            }); 
            if (modesNames !== undefined) {
              setmodeName(modesNames.modeName)
            }
          }
        }
        }
    );
  } 
  const getcountry = async () => {
    await MasterDataService.getcountry().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.countryName
          }))
          await setAllCountry(options)
          if (state === null) {
            await setCountry(data[0]['id'])
            setCountryName(data[0]['countryName'])
            //  await getState(data[0].id);
          } else {
            let countryName = data.find((item: any) => {
              return item.id === country
            });
            if (countryName !== undefined) {
              setCountryName(countryName.countryName)
            }
            //  await getState(country);
          }
        }
      }
    )
  }
  const handleCountryChange = async (e: any) => {
    await setCountry(e.value)
    await setCountryName(e.label)
    setStateName(0)
    setStateLabel('')
    setCity(0)
    setCityLabel('')
    setPincode(0)
    setPincodeLabel('')
    //  await getState(e.value);
  };

  // get state values
  const getState = async (value: Number) => {
    if (value !== 0) {
      await MasterDataService.getState(value).then(
        (data) => {
          if (data) {
            const statesList = data.map((d: any) => ({
              "value": d.id,
              "label": d.stateName
            }))
            setstatevalue(statesList)
            if (state === null || stateName === 0) {
              if (data[0]) {
                // id = data[0].id;
                setStateName(data[0]['id']);
                setStateLabel(data[0]['stateName'])
              }

              // getCity(data[0].id)
            } else {
              let stateNames = data.find((item: any) => {
                return item.id === stateName
              });
              if (stateNames !== undefined) {
                setStateLabel(stateNames.stateName)
              }
              // getCity(stateName)
            }
          }
        }
      )
    }
  }

  const handleStateChange = async (e: any) => {
    await setStateName(e.value)
    await setStateLabel(e.label)
    setCity(0)
    setCityLabel('')
    //  await getCity(e.value);      

  };

  // get city values
  const getCity = async (value: Number) => {
    if (value !== 0) {
      await MasterDataService.getCity(value).then(
        (data) => {
          if (data) {
            const cityList = data.map((d: any) => ({
              "value": d.id,
              "label": d.cityName
            }))
            setcityvalue(cityList)
            if (state === null || city === 0) {
              if (data[0]) {
                setCity(data[0]['id'])
                setCityLabel(data[0]['cityName'])
              }
            } else {
              let cityName = data.find((item: any) => {
                return item.id === city
              });
              if (cityName !== undefined) {
                setCityLabel(cityName.cityName)
              }
            }
          }
        }
      )
    }
  }

  const handleCityChange = async (e: any) => {
    await setCity(e.value)
    await setCityLabel(e.label)
    await setPincode(0)
    await setPincodeLabel('')
    // await getPincode(e.value);


  };
  const getPincode = async (value: Number) => {
    if (value !== 0) {
      setPincode(0)
      setPincodeLabel('')
      await MasterDataService.getPincode(value).then(
        async (data) => {
          if (data) {
            const zipCodeList = data.map((d: any) => ({
              "value": d.id,
              "label": d.zipCode
            }))
            await setpinvalue(zipCodeList)
            if (state === null || pincode === 0) {
              if (data[0]) {
                setPincode(data[0]['id']);
                setPincodeLabel(data[0]['zipCode'])
              }
            } else {
              let pinCodes = data.find((item: any) => {
                return item.id === pincode
              });
              if (pinCodes !== undefined) {
                setPincodeLabel(pinCodes.zipCode)
              }
            }
          }
        }
      )
    }
  }

  const handlePinChange = async (e: any) => {
    await setPincode(e.value);
    await setPincodeLabel(e.label)
  };
  // delete service
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const handleDelete = () => {
    if (state !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          MasterDataService.DeleteServiceProvider(state)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Service Provider has been deleted",
          }).then(function () {
            navigate("/serviceproviderlist");
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Service Provider is safe :)",
            }).then(function () {
              navigate("/serviceproviderlist");
            }
            )
        }
      })
      GetserviceProvider();
    }
  }

  const HandleCancel = () => {
    setserviceProviderName("")
    setAddress('')
    setphoneNumber("")
    setcontactPerson("")
    setcontactPersonEmail("")
  }


  
interface selectRowType {
  mode: any;
  clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
  style: any;
  onSelect: any;
}

const selectRow: selectRowType = {
  mode: "radio",
  clickToSelect: true,
  style: { backgroundColor: '#c8e6c9' },
  onSelect: (row: any) => {
    setSelectedRecords(row.id);
    setdeleteButton(true)
    setaccountNumber(row.accountNo)
  }
}
// ...

  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };
 
  // (property) columns: ColumnDescription<any, any>[]
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'accountNo', text: 'Account Number',  align: 'left' } 
  ];

  //Table End

  const filteredData = Array.isArray(vendorAccounts) ? vendorAccounts.filter((selectedRecords) => {
    const values = Object.values(selectedRecords);
    return values.some((value: unknown): value is string =>
  typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
);}) : [];


const handleAccountNumbersubmit = async () => {
  if(accountNumber !== ""){
    if(selectedRecords === undefined){

      await MasterDataService.submitVendorAccount(state, accountNumber).then(
        (data) => {
          if(data){
            Swal.fire({
              title: "Success",
              icon: 'success',
              text: "Vendor Account created successfully",
          }).then(function() {
            getVendorAccounts();
            setaccountNumber('')
          })
          }
        }
      )
    } else {
      await MasterDataService.updateVendorAccount(state, accountNumber, selectedRecords).then(
        (data) => {
          if(data){
            Swal.fire({
              title: "Success",
              icon: 'success',
              text: "Vendor Account Updated successfully",
          }).then(function() {
            getVendorAccounts();
            setaccountNumber('')
          })
          }
        }
      )  
    }
  } else {
    Swal.fire({
      title: "Opps!",
      icon: 'warning',
      text: "Please Enter Account Number",
  })
  }
}

const getVendorAccounts = async () => {
  if (state) {
    try {
      // console.log("Vendor Account Call ")
  await MasterDataService.getVendorAccounts(state).then(
    (data) => {
      if(data){ 
        setvendorAccounts(data);
      }
    }
  )
} catch (error) {
  console.error(error);
}
}
}

const deletevendorAccount =  async () => {
if((selectedRecords != undefined) ){
  await MasterDataService.deleteVendorAccount(selectedRecords).then(
   ()=>{
    Swal.fire({
      title: "Deleted !",
      icon: 'success',
      text: "Selected Account Deleted",
  }).then(function() {
    getVendorAccounts();
    setaccountNumber('')
    setSelectedRecords(undefined)
  })
   }
  )
} else {
  Swal.fire({
    title: "Opps!",
    icon: 'warning',
    text: "Please Select Account Number",
}) 
}
}
  return (
    <>
      <MasterHeader />
      <div className='container col-11' >
        <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}>Service Provider </p></div>
        <div className='row inline'>
         <div className='col-8 pr-0'>
        <div className='col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> Name</b></label>
          <input type='text' className='col-8 col-sm-4' name='serviceProviderName' value={serviceProviderName} onChange={e => setserviceProviderName(e.target.value)} />
        </div>
        <div className='col-sm-12 inputs'>
            <label className="col-6 col-sm-3"><b>Mode</b></label>
            <div className='col-8 col-sm-4 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
            <Select
                name="modeId"
                onChange={(e: any) => { setmodeName(e.label); setmodeId(e.value) }}
                options={shipmentmode}
                styles={customStyles}
                value={{ value: modeId, label: modeName }}
            />
            </div>
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> Phone No</b></label>
          <input type='text' className='col-8 col-sm-4' name='phoneNumber' value={phoneNumber} onChange={e => setphoneNumber(e.target.value)} />
        </div>

        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> Contact Person</b></label>
          <input type='text' className='col-8 col-sm-4' name='contactPerson' value={contactPerson} onChange={e => setcontactPerson(e.target.value)} />
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> Email</b></label>
          <input type='text' className='col-8 col-sm-4' name='contactPersonEmail' value={contactPersonEmail} onChange={e => setcontactPersonEmail(e.target.value)} />
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> Address</b></label>
          <input type='text' className='col-8 col-sm-4' name="address" value={address} onChange={e => setAddress(e.target.value)} />
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> Country </b></label>
          <div className='col-8 col-sm-4 select' style={{paddingLeft:"0px",paddingRight:"0px"}}> 
          <Select
            name="country"
            onChange={handleCountryChange}
            options={allCountry}
            styles={customStyles}
            value={{ value: country, label: countryName }}
          />
          </div>
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> State</b></label>
          <div className='col-8 col-sm-4 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select
            name="stateName"
            onChange={handleStateChange}
            options={statevalue}
            styles={customStyles}
            value={{ label: stateLabel, value: stateName }}
          />
          </div>
        </div>
        <div className='col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> City</b></label>
          <div className='col-8 col-sm-4 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select
            name="city"
            onChange={handleCityChange}
            options={cityvalue}
            styles={customStyles}
            value={{ label: cityLabel, value: city }}
          />
          </div>
        </div>
        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-3'><b> Pincode</b></label>
          <div className='col-8 col-sm-4 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select
            name="pincode"
            onChange={handlePinChange}
            options={pinvalue}
            styles={customStyles}
            value={{ label: pincodeLabel, value: pincode }}
          />
          </div>
        </div>
        
        <div className=' col-sm-12 inputs'>
          <label className='col-6 col-sm-6'><b> International Serviceable </b></label>
          <div className='col-sm-4' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <input
              type="checkbox"
              checked={internationalService === 1 ? true : false}
              onChange={(e) => {
                setinternationalService(internationalService === 1 ? 0 : 1);
              }}
            />

          </div>
        </div>
      </div>
      <div className='col-4 pr-0'>
        {showtable && (
          <>
        
        <div style={{paddingBottom : '10px'}}> Account Details </div>
        <div className='col-sm-12 row'>
          <label className='col-sm-3'> Acc No.  </label>
          <div className='col-sm-9 row'>
            <input type='text' className='col-sm-8' name='accountNumber' value={accountNumber} onChange={e => setaccountNumber(e.target.value)} />
       <button className='col-sm-1' onClick={handleAccountNumbersubmit}> <i className='fa fa-plus'> </i></button>
       {deleteButton &&(<button className='col-sm-1' onClick={deletevendorAccount}> <i className='fa fa-trash'> </i></button>)}
        </div>
        </div>
        <div style={{paddingBottom : '10px'}}></div>
        <div className='col-sm-12'>
        <BootstrapTable
              rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign: "center" }}
              keyField='id'
              striped
              hover
              condensed
              data={filteredData}
              columns={columns} 
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
            </div>
        </>
          
          )}
        </div>
      </div>
      </div>
      <div className='btn d-flex justify-content-center'>
        {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""><i className="fa fa-plus" aria-hidden="true"></i> Submit</Link> : <>
          <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="/serviceproviderlist"><i className="fa fa-edit"></i> Update </Link>
          <button className="btn2 col-2 col-sm-1" onClick={handleDelete} ><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> </>}
        <button className="btn2 col-2 col-sm-1" onClick={HandleCancel} ><i className="fa fa-close"></i> Cancel</button>
        <Link className="btn2 col-2 col-sm-1" to="/serviceproviderlist"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
      </div>
    </>
  )
}

export default Serviceprovider