import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter';
import { read, utils, writeFile } from 'xlsx';
import Outcontainerheader from './InwardContainerHeader';
import InwardDetailService from '../../services/inwarddetail.service';
import moment from 'moment';

// const customStyles = {
//   option: (provided: any, { isFocused, isSelected }: any) => ({
//     ...provided,
//     padding: 5,
//     zIndex: 1,
//     background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
//   }),
//   control: (prev: any) => ({
//     ...prev,
//     padding: 0,
//     width: "100%"

//   }),
//   menu: (base: any, { isFocused, isSelected }: any) => ({
//     ...base,
//     zIndex: 100,
//     background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
//   }),
// }
const UploadStatus = () => {


  // const [productName, setproductName] = useState("");
  // const [productId, setproductId] = useState(0);
  // const [zoneName, setzoneName] = useState("");
  // const [shipmentmode, setshipmentmode] = useState([]);
  // const [modeName, setmodeName] = useState('')
  // const [modeId, setmodeId] = useState(0);
  // const [serviceProvider, setserviceProvider] = useState('');
  // const [serviceProviderId, setserviceProviderId] = useState(0);
  // const [serviceProviderList, setserviceProviderList] = useState([]);
  // const [zoneZipCodes, setZoneZipCodes] = useState<string>('');
  

  // const { state } = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [uploadAWBStatus, setUploadAWBStatus] = useState([])
  const [importedZonepincode, setimportedZonepincode] = useState<any | []>([])
  
  
   //Table Start
   interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
}
const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
        setSelectedRecords(row.id);
    }
}
interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
}
const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
}: Article) => (
    <div className="btn-group" role="group">
        {
            options.map((option: any) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);

interface optionsType {
    sizePerPageRenderer: any
}
const options: optionsType = {
    sizePerPageRenderer
};

const pagination = paginationFactory(options);
const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'AWB', formatter: (cell, row, rowIndex, formatExtraData) => {  console.log(row);  return rowIndex + 1;  }, text: 'id', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'AWB', text: 'AWB Number', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'inwarddetailStatus', text: 'Status', align: 'left', headerClasses: 'left-aligned-header' },
    { dataField: 'deliveryDateTime', text: 'Delivery Date', align: 'left', headerClasses: 'left-aligned-header' },  
    { dataField: 'remark', text: 'Remark', align: 'left', headerClasses: 'left-aligned-header' }  
];
//Table End


  const HandleSubmit = () => {
    InwardDetailService.postStatus(uploadAWBStatus).then(
      (data) => {
        if (data) {
          navigate('../inworddetailslist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }


// setimportedZonepincode(filteredData)
  //------------excel import --------------
  
  

     const handleImport = (event: any) => {
    if (!event.target.files) return;
    const files = event.target.files;
    if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event:any) => {
            const wb = read(event.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
                const rows: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
               var newArr :any = [...uploadAWBStatus, ...rows];
               console.log("rows", rows)
               const awbObjects = newArr.map((data: any) => (
                {
                AWB: data[Object.keys(data)[0]],
                inwarddetailStatus: data[Object.keys(data)[1]],
                deliveryDateTime : moment((data[Object.keys(data)[2]]- 2) * 24 * 60 * 60 * 1000 + Date.UTC(1900, 0, 1)).format('YYYY-MM-DD HH:MM:SS'),
                remark : data[Object.keys(data)[3]],
              }
              ));
              setUploadAWBStatus(awbObjects);
              setimportedZonepincode(awbObjects);
            }
        }
        reader.readAsArrayBuffer(file);

    }
}
  //-------------excel import end here ---------
  
useEffect(() => {
setFilterdData();
},[searchQuery])

const setFilterdData = async () => {
  await setimportedZonepincode(Array.isArray(uploadAWBStatus) ? uploadAWBStatus.filter((searchRecords) => {
    const values = Object.values(searchRecords);
    return values.some((value: unknown): value is string =>
    typeof value === 'string' ? value.toLowerCase().includes(searchQuery.toLowerCase())
    : typeof value === 'number' ? value.toString().includes(searchQuery.toLowerCase()) : false
    )
  }): [])
}
  return (
    <>
      <Outcontainerheader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>Status Details </p></div>
          
          <div className='col-12 inline'>
          <div className='col-12'>
          <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent:"space-between", alignItems: "center" }}>
     <div className='col-6'> 
     <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
     </div>
                            <input
                                className='searchbar col-3'
                                type='text'
                                value={searchQuery}
                                placeholder='Search Here ...'
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
          <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign: "center" }}
                            keyField='id'
                            striped
                            hover
                            condensed
                            data={importedZonepincode}
                            columns={columns}
                            pagination={pagination}
                            selectRow={selectRow}
                            filter={filterFactory()}
                            filterPosition="top"
                        />
          </div>
          </div>
        </div>
        <div className='row col-12'>
          <div className='row col-4 d-flex justify-content-center'>
          <Link to="../uploads/excelformat/Upload Status.xlsx"  className="btn2 col-6"  target='_blank' > <i className='fa fa-download'></i> Download Template</Link>
       </div>
          <div className='row col-8 d-flex justify-content-end'>

        {/* <div className='btn d-flex justify-content-center'> */}
        <Link className="btn2 col-2"
            onClick={HandleSubmit} to="/inworddetailslist"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link>
          <button className="btn2 col-2"><i className="fa fa-close"></i>Cancel</button>
          <Link className="btn2 col-2" to="/uploadstatus"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
            </div>
    </>
  )
}

export default UploadStatus
