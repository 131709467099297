import { useEffect, useState } from 'react'
import Inwarddetailsercice from '../../services/inwarddetail.service';
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment';
import InwardContainerHeader from './InwardContainerHeader';
import MasterDataService from '../../services/masterData.service';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

function Helpdesktracking() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [selectedAWBNumber, setSelectedAWBNumber] = useState();
  const [Fromdate, setFromdate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [Todate, setTodate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allInwardDetails, setAllInwardDetails] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [Employee, setEmployee] = useState('Employee Name');
  const [EmployeeId, setEmployeeId] = useState(0);
  const [AWBNo, setAWBNo] = useState('AWB No');
  const [AWBNoId, setAWBNoId] = useState(0);
  const [StatusList, setStatusList] = useState<any | []>([]);
  const [StatusId, setStatusId] = useState(0);
  const [Status, setStatus] = useState('Pending');
  const [ISRNo, setISRNo] = useState('ISR No');

const [ModeList, setModeList] = useState([]);
const [Mode, setMode] = useState('Select Mode');
const [ModeId, setModeId] = useState(0);
const [RouteList, setRouteList] = useState([]);
const [Route, setRoute] = useState('Select Route');
const [RouteId, setRouteId] = useState(0);
const [DepartmentList, setDepartmentList] = useState([]);
const [Department, setDepartment] = useState('Select Department');
const [DepartmentId, setDepartmentId] = useState(0);


  
  const dateformater = (data: any, row: any) => {
    return (moment(data)).format('DD/MM/YYYY')
  }
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
      setSelectedAWBNumber(row.AWB);
    }
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };
const timeFormatChange  = (data: any, row: any) => {
  if(data.length > 8){
  return (moment(data)).format('hh:mm:ss A')
  } else {
    return data;
  }
}

const allreasons = (data: any, row: any) => {
 
}

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'Sr. No.',  align: 'center'},
    { dataField: 'inwardDate', text: 'Inward Date', sort: true, formatter: dateformater },
    { dataField: 'inwardTime', text: 'Inward Time', formatter :timeFormatChange },
    { dataField: 'isrNumber', text: 'ISR Number' },
    { dataField: 'AWB', text: 'AWB Number' },
    { dataField: 'consignerName', text: 'Consignor' },
    { dataField: 'employeeName', text: 'Employee' },
    { dataField: 'inwardQuantity', text: 'Pcs.' },
    { dataField: 'inwarddetailStatusDescription', text: 'Status' },
    { dataField: 'remark', text: 'Remark', 
    formatter: (cell, row :any) => {
      let remarksLabel = "";
      if (row.receivedBy !== null) {
        remarksLabel = row.receivedBy;
      }
      
  
      if (row.missRouteReason !== null) {
        remarksLabel = remarksLabel + ' ' + row.missRouteReason;
      }
  
      if (row.deliveryRemark !== null) {
        remarksLabel = remarksLabel + ' ' + row.deliveryRemark;
      }
      if (row.remark !== null) {
        remarksLabel = remarksLabel + ' ' + row.remark;
      }
  
      return <div>{remarksLabel}</div>;
    }},


  ];
  //Table End  
  const getInwardDetailList = async () => {
    await Inwarddetailsercice.getdataforhdtracking(Fromdate,Todate,EmployeeId,StatusId,AWBNo,ModeId,DepartmentId,RouteId).then(
      (data) => {
        if (data) {
          setAllInwardDetails(data);
        }
      }
    );
  }
  useEffect(()=>{
    getEmployee();
    getReportType();
    getMode();
    getDepartment();
    getRoute();
  },[]);

  const getReportType = () => {
           
    const options =  [
    {
        "value": 0,
        "label": "Pending"
    },
    {
      "value": 8,
      "label": "Delivered"
    },
    {
      "value": 9,
      "label": "Undelivered"
    },
    {
      "value": 13,
      "label": "Redirected"
    },
    {
      "value": 14,
      "label": "Unclaimed"
    },
    {
      "value": 16,
      "label": "OnHold"
    },
  ]
      setStatusList(options)
      setStatusId(options[0]['value'])
      setStatus(options[0]['label'])
}

const getRoute = async () => {
  await MasterDataService.getRouteId().then(
    async (data) => {
      const options = data.map((d: any) => ({
        "value": d.id,
        "label": d.routeName
      }))
      options.unshift({ value: 0, label: "Select Mode" });
      await setRouteList(options)
   }
  )
}

const getDepartment = async () => {
  await MasterDataService.getDepartment().then(
    async (data) => {
      if(data){
      const options = data.map((d: any) => ({
        "value": d.id,
        "label": d.name
      }))
      options.unshift({ value: 0, label: "Select Department" });
      await setDepartmentList(options)
     }
   }
  )
}


 const getMode = () => {
  MasterDataService.GetshipmentMode().then(
      async (data) => {
        if(data) {            
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.modeName
        }))
        options.unshift({ value: 0, label: "Select Mode" });
        await setModeList(options)
      }
      }
  );
}

  useEffect(() => {
   getInwardDetailList();
  }, [Fromdate,Todate,EmployeeId,StatusId,AWBNo,ModeId,RouteId,DepartmentId])
  useEffect(() => {
    setFromdate((moment(new Date())).format('YYYY-MM-DD'))
  }, [])

  const getEmployee = () => {
    MasterDataService.getEmployeeList().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName
          }))
          setEmployeeList(options)
          setEmployeeId(0)
          setEmployee("All Employee")
        }
        }
    );
  }

  const handleInputChangeAwb = async (input: any) => {
    if (input.length > 3) {
      return await InwardDeliverySheet.getAwbNumberbyAutoComplte(input).then(
        (data) => {
          const awbNumberList = data.map((d: any) => ({
            "value": d.AWB,
            "label": d.AWB
          }))
          return awbNumberList;
        }
      )
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (selectedRecords !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Inwarddetailsercice.deleteInwardDetails(selectedRecords)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Inward Details Entry has been deleted",
          }).then(function () {
            window.location.reload()
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Inward Details Entry is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
    }
  }

  const navigate = useNavigate();
  const EditDelivery = () => {
    if (selectedRecords !== undefined) {
      navigate('../inworddetails', { state: selectedRecords });
    }
  }

  const handleTracking = () => {
    if (selectedRecords !== undefined) {
      navigate('../inwardtracking', { state: selectedAWBNumber });
    } 
  }
  const handleEmployee = (e:any) => {
    console.log(JSON.stringify(e));
    setEmployeeId(e.value);
    setEmployee(e.label)
  }
  const handleStatus = (e:any) => {
    setStatusId(e.value);
    setStatus(e.label)
  }
  
const handleMode = (e:any) => {
  setModeId(e.value);
  setMode(e.label)
}

const handleDepartment = (e:any) => {
  setDepartmentId(e.value);
  setDepartment(e.label)
}

const handleRoute = (e:any) => {
  setRouteId(e.value);
  setRoute(e.label)
}

  const handleAWB = (e:any) => {
    setAWBNo(e.value);
  }
  const handleISR = (e:any) => {
    setISRNo(e.value);
  }
  

  const filteredData = Array.isArray(allInwardDetails)
    ? allInwardDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];
  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Inward Details </p></div>
            {/* <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }} >
              <div className='col-5'>
                <label> From Date</label>
                <input className='margininutleft' type='Date' value={Fromdate} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setFromdate(e.target.value) }} />
                <label> &nbsp;To Date</label>
                <input className='margininutleft' type='Date' value={Todate} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setTodate(e.target.value) }} />
              </div>
              <div className='col-3' >
              <Select name="EmployeeId"
                      onChange={handleEmployee}
                      options={EmployeeList}
                      value={{ value: EmployeeId, label: Employee }}
                    />
              </div>
              <div className='col-2' >
              <Select name="StatusId"
                      onChange={handleStatus}
                      options={StatusList}
                      value={{ value: StatusId, label: Status }}
                   />
              </div>
              <div className='col-2' >
              <AsyncSelect name="AwbNo"
                          onChange={(e: any) => {setAWBNo(e.value)}}
                          loadOptions={handleInputChangeAwb}
                          value={{ value: AWBNo, label: AWBNo }}
                          className='col-12'
                        />
              </div>
            </div> */}
<div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }} >
              <div className='col-6'>
                <label> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;From Date</label>
                <input className='margininutleft' type='Date' value={Fromdate} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setFromdate(e.target.value) }} />
                <label> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;To Date</label>
                <input className='margininutleft' type='Date' value={Todate} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setTodate(e.target.value) }} />
              </div>
              <div className='col-3' >
              <Select name="ModeId"
                      onChange={handleMode}
                      options={ModeList}
                      value={{ value: ModeId, label: Mode }}
                    />
             
              </div>
              <div className='col-3' >
              <Select name="StatusId"
                      onChange={handleStatus}
                      options={StatusList}
                      value={{ value: StatusId, label: Status }}
                   />
              </div>
              
            </div> <div className='col-12 inline' style={{padding:'15px'}}>
            <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }} >
            <div className='col-3' >
              <AsyncSelect name="AwbNo"
                          onChange={(e: any) => {setAWBNo(e.value)}}
                          loadOptions={handleInputChangeAwb}
                          value={{ value: AWBNo, label: AWBNo }}
                          className='col-12'
                        />
              </div>
             <div className='col-3' >
             <Select name="EmployeeId"
                      onChange={handleEmployee}
                      options={EmployeeList}
                      value={{ value: EmployeeId, label: Employee }}
                    />
              </div>
              <div className='col-3' >
              <Select name="departmentId"
                      onChange={handleDepartment}
                      options={DepartmentList}
                      value={{ value: DepartmentId, label: Department }}
                   />
              </div>
              <div className='col-3' >
              <Select name="routeId"
                      onChange={handleRoute}
                      options={RouteList}
                      value={{ value: RouteId, label: Route }}
                   />
              </div>
            </div></div>
            <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }} >
              <div className='col-5' style={{ marginLeft: "10px" }}>
                
              </div>
            </div>
           
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />

          </div>
          <div className='row col-12 d-flex justify-content-center'>
            {/* <Link to="../inworddetails" className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link> */}
            <button className="btn2 col-2 col-sm-1" onClick={EditDelivery}><i className="fa fa-edit"></i> Edit</button>
            <button className="btn2 col-2 col-sm-1" onClick={handleTracking} ><i className="fa fa-map-marker"></i>ShipmentTrack</button>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} ><i className="fa fa-trash"></i> Delete</button>
            {/* <Link to="/inwardimport" className="btn2 col-2 col-sm-1"> <i className="fa fa-upload" aria-hidden="true"></i>Import</Link> */}
            <button className="btn2 col-2 col-sm-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Helpdesktracking