
import { useEffect, useState } from 'react';
import '../login/login.css'
import InputField from "../../components/InputField";
import AuthService from '../../services/auth.service';


const Login = () => {
  const [inputValue, setInputValue] = useState({username : "", password : "", email :""})
  const {username, password, email} = inputValue;
  const [message, setMessage] = useState('')
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showForgotPasswordButton, setshowForgotPasswordButton] = useState("block");
  
  // const navigate = useNavigate();
  useEffect(()=>{
    if(localStorage.getItem('userId')){
      window.location.href = process.env.REACT_APP_PUBLIC_URL +"dashboard"
    }
  })
  const handleResetPassword = async () => {
    // Implement the logic to send a reset password request
    // For example, you can use a service like AuthService to send the request
    try {
      await AuthService.forgotPassword(email);
      setMessage('Password reset instructions sent to your email.');
      setshowForgotPasswordButton("none")
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
      setshowForgotPasswordButton("block")
    }
  };
  const routeChange = () =>{ 
   AuthService.login(username, password).then(
      ()=>{
        if(localStorage.getItem('userId')){
          window.location.href = process.env.REACT_APP_PUBLIC_URL +"dashboard"
          // navigate('../dashboard');
        }else {
          setMessage("Please Enter Valid Username And Password");
        }
      }
    )
  }
  var sectionStyle = {
    width: "100%",
    height: "100vh",
    backgroundImage: "url(/assets/bg-login.jpg )"
  };
 
  const ClickFunction = async (e:any) => {
const {name, value} = e.target;
await setInputValue((prev)=> ({
  ...prev,
  [name] : value
}));
setMessage("");
  }
  return (
   
    <>
<section className="vh-80" style={ sectionStyle }>
  <div className="container h-100" style={{border:'none'}}>
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-xl-10 center">
        <div className="card" >
          <div className="row col-12" style={{paddingRight: '0px'}} >
            <div className="col-6 leftdiv" >
            <img src= {process.env.REACT_APP_PUBLIC_URL + "assets/upperside-image.png"}   alt="Mailit Mailroom Managemnt Services Pvt. Ltd." />
            </div>
            <div className="col-6 d-flex align-items-center" style={{backgroundColor :'#da1920'}} >
            {!showForgotPasswordModal && (<div className="p-lg-5">
                  <h5 className="fw-normal mb-2 pb-1 instruction text-center" style={{color :'#fff'}}>Sign into your account</h5>
                  <div className="mb-1">
                   <InputField type="text" label="username" placeholder="User Name" value={inputValue.username} name="username" onClickFunction={ClickFunction}  className=''   />
                  </div>
                  <div className="mb-1">
                   <InputField type="password" label="username" placeholder="Password" value={inputValue.password} name="password" onClickFunction={ClickFunction}  className='' />
                  </div>
                 <h5 className="fw-normal mb-1 pb-1 instruction" style={{color:"white"}} >{message}</h5>
                  <div className="mb-1">
                   <button color="primary" className="btn btn-dark btn-block"  onClick={routeChange} > Login  </button>
                  </div>
                  <button
              className="btn btn-link text-white"
              onClick={() => setShowForgotPasswordModal(true)}
            >  Forgot Password?
            </button>             
      </div> )}
      {showForgotPasswordModal && (
                <>
                  <div className="p-lg-5">
                  <div className="mb-1 text-white">
        <h4>Forgot Password</h4>
        <p style={{display : showForgotPasswordButton}}>Enter your email to receive reset instructions.</p>
        <div style={{display : showForgotPasswordButton}}>
        <InputField type="email" label="email" placeholder="Enter your email" value={inputValue.email} name="email" onClickFunction={ClickFunction}  className=''  />
        </div>
        <div className="message">{message}</div>
        <button className='btn btn-dark btn-block' onClick={handleResetPassword} style={{display : showForgotPasswordButton}}>Reset Password</button>
        <button className='btn btn-dark btn-block' onClick={() => {setShowForgotPasswordModal(false) ; setshowForgotPasswordButton("block"); setMessage("")}} >Close</button>
       
      </div>
    </div>
                </>
      )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </>
  )
}

export default Login