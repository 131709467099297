import React, { useEffect, useState } from 'react'
import AccountHeader from '../accountHeader';
import OutwardScanService from '../../../services/outwardScanCheck.service';
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import { read, utils } from 'xlsx';

function OutwardReconciliation() {
  const createdBy =  localStorage.getItem('userId');
  const updatedBy =  localStorage.getItem('userId');
  const mailRoomId =  localStorage.getItem('mailRoomId');


  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [uniqueBranch, setuniqueBranch] = useState([])
  const [uniqueBranchCostCenterDepartmentRoute, setuniqueBranchCostCenterDepartmentRoute] = useState([])
  const [uniqueBranchRoute, setuniqueBranchRoute] = useState([])
  const [uniqueCityZipCode, setuniqueCityZipCode] = useState([])

const [allAWBNumber , setallAWBNumber]= useState([]);


interface selectRowType {
  mode: any;
  clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
  style: any;
  onSelect: any;
}

const selectRow: selectRowType = {
  mode: "radio",
  clickToSelect: false,
  style: { backgroundColor: '#c8e6c9' },
  onSelect: (row: any) => {
    setSelectedRecords(row.id);
  }
}
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [

    { dataField: 'AWB', text: 'AWB Number' },
    { dataField: 'ourWeight', text: 'Our Weight ' },
    { dataField: 'vendorWeight', text: 'Vendor Weight' },
    { dataField: 'ourRate', text: 'Our Rate' },
    { dataField: 'vendorRate', text: 'Vendor Rate' },
    { dataField: 'ourServiceCharge', text: 'Our Service Charge' },
    { dataField: 'vendorServiceCharge', text: 'Vendor Service Charge' },
    { dataField: 'ourAmount', text: 'Our Amount' },
    { dataField: 'vendorAmount', text: 'Vendor Amount' },
    { dataField: 'amountDifference', text: 'Difference Amount' },

  ];
  //Table End

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  

  const navigate = useNavigate();
   
  const filteredData = Array.isArray(allAWBNumber)
  ? allAWBNumber.filter((selectedRecords) => {
    const values = Object.values(selectedRecords);
    return values.some((value: unknown): value is string =>
      typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }) : [];

  //------------excel import --------------
  
  
     const handleImport = (event: any) => {
    if (!event.target.files) return;
    const files = event.target.files;
    if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event:any) => {
            const wb = read(event.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
              const rows: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
              // var newArr :any = [...allAWBNumber, ...rows];
              const awbObjects = rows.map((data: any, index :any) => (
               {
                eid: index,
                AWB: (data[Object.keys(data)[0]]).toString(),
                vendorWeight: data[Object.keys(data)[1]],
                vendorRate : data[Object.keys(data)[2]],
                vendorServiceCharge : data[Object.keys(data)[3]],
                vendorAmount : data[Object.keys(data)[4]],
                mailRoomId : mailRoomId,
                createdBy : createdBy,
                updatedBy : updatedBy ,
             }
             ));
                setallAWBNumber(awbObjects);            
              
            }
        }
        reader.readAsArrayBuffer(file);

    }
}
  //-------------excel import end here ---------

  const handleSubmit = () => {
    if(allAWBNumber.length > 0) {
      OutwardScanService.validateAWB().then(
      (data)=>{
        if(data){
          Swal.fire({
            title: "Employee Import in Progress",
            icon: 'success',
            text: "Employee has Imported Please Wait....",
        }).then(function() {
        navigate("../employeelist")
        })  
        }
      }
    )
    }
  }
  return (
    <>
      <AccountHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11">
          <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}> Outward Reconciliation</p></div>
<div className='col-12 inline'>

        <div className='col-6'>
        <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
    
          {/* <Link className='btn2' to="/employeelist" ><i className='fa fa-list'></i>  Employee List</Link> */}
        </div>
          <div className='col-6' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "end",alignItems:"center" }}>
              <input
                className='searchbar col-3'
                type='text'
                value={searchQuery}
                placeholder='Search Here ...'
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            </div>
            <div className="table-responsive">
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%' }} 
              keyField='eid'
              striped
              hover
              condensed
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
             </div>
          </div>
          <div className='row col-12 d-flex justify-content-center'>
          <Link to="" onClick={handleSubmit} className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Validate All</Link>
          <button className="btn2 col-2 col-sm-1" > <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
          
        </div>
        </div> 
      </div>
    </>
  );
}

export default OutwardReconciliation