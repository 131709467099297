import  { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
// import '../inward/inwardloglist.css';
import { useNavigate } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import { read, utils } from 'xlsx';

function RegulatorsImport() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [allRegulators, setallRegulators] = useState([])
  const createdBy =  localStorage.getItem('userId');
  const updatedBy =  localStorage.getItem('userId');
  const mailRoomId =  localStorage.getItem('mailRoomId');
  const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
  //Table Start


  interface selectRowType {
    mode: any;
    clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
    style: any;
    onSelect: any;
  }
  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
    },

  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [

    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'id',  align: 'center', classes: 'rowWidth10'},
    { dataField: 'regulatorName', text: 'Regulator Name',  align: 'left' , classes: 'rowWidth70'},
    { dataField: 'deliveryTime', text: 'Delivery Time', align:'left', classes: 'rowWidth20' },
   
  ];
  //Table End

 
  const navigate = useNavigate();
  
  const filteredData = Array.isArray(allRegulators) ?  allRegulators.filter((selectedRecords) => {
    const values = Object.values(selectedRecords);
    return values.some((value: unknown): value is string =>
  typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
);

  }) : [];
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

 //------------excel import --------------
  
  
 const handleImport = (event: any) => {
  if (!event.target.files) return;
  const files = event.target.files;
  if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event:any) => {
          const wb = read(event.target.result);
          const sheets = wb.SheetNames;
          if (sheets.length) {
            const rows: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
            // var newArr :any = [...allRegulators, ...rows];
            const awbObjects = rows.map((data: any, index :any) => (
             {
              eid: index,
              regulatorName: (data[Object.keys(data)[0]]).toString(),
              deliveryTime: data[Object.keys(data)[1]],
              mailRoomId : mailRoomId,
              createdBy : createdBy,
              branch : branch,
              updatedBy : updatedBy ,
           }
           ));
              setallRegulators(awbObjects);
          }
      }
      reader.readAsArrayBuffer(file);

  }
}
//-------------excel import end here ---------

const handleSubmit = () => {
  if(allRegulators.length > 0) {
  MasterDataService.importRegulators(allRegulators).then(
    (data)=>{
      if(data){
        Swal.fire({
          title: "Regulators Import in Progress",
          icon: 'success',
          text: "Regulators has Imported Please Wait....",
      }).then(function() {
      navigate("../regulatorslist")
      })  
      }
    }
  )
  }
}
  
  return (
    <>

      <MasterHeader />
        <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>          
          <div className="container col-11">
            <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}>Regulators List </p></div>
            <div className='col-12 inline'>
          <div className='col-6'>
          <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
          </div>
          <div className='col-6' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "end",alignItems:"center" }}>
              <input
                className='searchbar col-3'
                type='text'
                value={searchQuery}
                placeholder='Search Here ...'
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            </div>
            <div className='table-responsive'>
              <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%',textAlign:"center" }} 
                keyField='id'
                striped
                hover
                condensed
                data={filteredData}
                columns={columns}
                pagination={pagination}
                selectRow={selectRow}
                filter={filterFactory()}
                filterPosition="top"
            />
</div>

          </div>
          <div className='row col-12'>
                <div className='row col-4 d-flex justify-content-center'>
                <a href="../uploads/excelformat/importRegulators.csv" download className="btn2 col-6">
            <i className='fa fa-download'></i> Download Template
          </a> 
          </div>
          <div className='row col-8 d-flex justify-content-end'> 
              <Link to="" className="btn2 col-2 col-sm-1" onClick={handleSubmit}  > <i className="fa fa-upload" aria-hidden="true"></i>  Upload</Link>
              <Link className="btn2 col-2 col-sm-1" to="../dashboard"><i className="fa fa-sign-out"></i>  Close</Link>
              
            </div>
            </div>
        </div>
      </div>
    
    </>
  );
}
export default RegulatorsImport
