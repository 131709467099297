import React, { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'
// import '../Masters/master1.css'
import AccountHeader from '../accountHeader';
import VendorHeader from './vendorHeader';
import MasterDataService from '../../../services/masterData.service';
import Select from 'react-select';
import AccountService from '../../../services/accountData.service';
import { Table } from 'react-bootstrap';
const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    // none of react-select's styles are passed to <Control />
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}
interface TableRow {
  fromWeight: number;
  toWeight: number;
  rate: number;
}
function RateContract() {
  const [allBranch, setallBranch] = useState([]);
  const [branch, setBranch] = useState((localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : 0);
  const [branchLabel, setBranchLabel] = useState('');
  const [allServiceprovider, setallServiceprovider] = useState([]);
  const [serviceprovider, setServiceprovider] = useState(0);
  const [serviceproviderLabel, setServiceproviderLabel] = useState('');
  const [allProduct, setallProduct] = useState([]);
  const [product, setProduct] = useState(0);
  const [productLabel, setProductLabel] = useState('');
  const [shipmentModeId, setShipmentModeId] = useState(0)
  const [shipmentModeName, setShipmentModeName] = useState('')
  const [allZone, setallZone] = useState([]);
  const [zone, setZone] = useState(0);
  const [zoneLabel, setZoneLabel] = useState('');
  const [tableData, setTableData] = useState<{ id: number ,slabRate: number; slabStart: number; slabEnd: number }[]>([]);
  const [editingRowIndex, setEditingRowIndex] = useState(100);

  const [fromWeight, setfromWeight] = useState(0);
  const [uptoWeight, setuptoWeight] = useState(0);
  const [slabRate, setSlabRate] = useState(0);

  const [additionalRate, setadditionalRate] = useState(0);
  const [additionalWeight, setadditionalWeight] = useState(0);
  const [surcharge, setsurcharge] = useState(0);
  const [fuelSurcharge, setfuelSurcharge] = useState(0);
  const [loadingCharge, setloadingCharge] = useState(0);
  const [unloadingCharge, setunloadingCharge] = useState(0);
  const [contractId, setContractId] = useState(0);
  
  const { state } = useLocation();
  const navigate = useNavigate();


  //  get branch options
  const getBranch = async () => {
    await MasterDataService.getBranch().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.branchName
          }))
          await setallBranch(options)
        }
      }
    )
  }
  useEffect(() => {
    getBranch()
    getContractDetailsById()
  }, [])
  
  useEffect(() => {
    setBranchFn();
  }, [branch, allBranch])
  const setBranchFn =() => {
    let branchLists = allBranch.find((item: any) => {
      return item['value'] === branch
    });
    if (branchLists !== undefined) {
      setBranchLabel(branchLists['label'])
    }
  }
  const handleBranchChange = async (e: any) => {
    await setBranch(e.value)
    await setBranchLabel(e.label)
  };
  useEffect(() => {
    getServiceprovider();
  }, [branch])
  useEffect(() => {      
    // console.log("Service Provider Change", serviceprovider, "   state")
    setServiceProviderFn();
  }, [serviceprovider, allServiceprovider])
  useEffect(() => {
    getProduct(serviceprovider);
    getZone(serviceprovider);   
  }, [serviceprovider])

  const getContractDetailsById = () => {
    if(state !== "") {
AccountService.getRateContractById(state).then(
  (data) => {
    if(data) {
      // console.log("data rate Contract Data", data)
    setadditionalRate(data.additionalRate)
    setadditionalWeight(data.additionalWeight)
    setsurcharge(data.surcharge)
    setfuelSurcharge(data.fuelSurcharge)
    setloadingCharge(data.loadingCharge)
    setunloadingCharge(data.unloadingCharge)
    setBranch(data.branch)
    setServiceprovider(data.serviceProviderId)
    setProduct(data.productId)
    setZone(data.zoneId)
    setShipmentModeId(data.shipmentModeId)
    // console.log("service Provider in Get", serviceprovider,"    --- ", data.serviceProviderId)
    }
  }
)
    }
  }
  //  get Serviceprovider options
  const getServiceprovider = async () => {
    await MasterDataService.GetserviceProviderbybranch(branch).then(
      async (data) => {     
        if (data !="") {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.serviceProviderName
          }))
          await setallServiceprovider(options)
          if(state === null){
          if (data[0]) {
            await setServiceprovider(data[0]['id'])
            await setServiceproviderLabel(data[0]['serviceProviderName'])
          } 
        }
        }else {
          await setServiceprovider(0)
          await setServiceproviderLabel('')
          setallServiceprovider([])
        }
      }
    )
  }
const setServiceProviderFn = () => {
  let serviceproviderLists = allServiceprovider.find((item: any) => {
    return item['value'] === serviceprovider
  });
  if (serviceproviderLists !== undefined) {
    setServiceproviderLabel(serviceproviderLists['label'])
  }
}

  const handleServiceproviderChange = async (e: any) => {
    await setServiceprovider(e.value)
    await setServiceproviderLabel(e.label)
    setProduct(0)
    setProductLabel('')
    setZone(0)
    setZoneLabel('')
   
  };
  //  get Product options
  const getProduct = async (value:number) => {
    if (value !== 0) {
    await AccountService.getProductbyServiceProvider(value, branch).then(
      async (data) => {
        if (data !== "") {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.productName,
            "shipmentModeId" :d.shipmentModeId,
            "shipmentModeName" : d.shipmentModeName,
          }))
          await setallProduct(options)
          if(state === null){
          if (data[0]) {
            await setProduct(data[0]['id'])
            await setProductLabel(data[0]['productName'])
            await setShipmentModeId(data[0]['shipmentModeId'])
            await setShipmentModeName(data[0]['shipmentModeName'])
          }
        }
        } else {
            await setProduct(0)
            await setProductLabel('') 
            setShipmentModeId(0)
            setShipmentModeName('');
            setallProduct([])
        }
      }
    )
  } else {
    await setProduct(0)
    await setProductLabel('') 
    setallProduct([]) 
  }}

  useEffect(()=>{
    setProductsFn()
  },[product, allProduct])

const setProductsFn = () => {
  let prodNameList = allProduct.find((item: any) => {
    return item['value'] === product
  });
  if (prodNameList !== undefined) {
    setProductLabel(prodNameList['label'])
  }
}

  const handleProductChange = async (e: any) => {
    await setProduct(e.value)
    await setProductLabel(e.label)
    setZone(0)
    setZoneLabel('')
  };
  //  get Zone options
  const getZone = async (value:number) => {
    if (value !== 0) {
    await AccountService.getZoneByServiceProvider(value).then(
      async (data) => {
        if (data !=="") {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.zoneName
          }))
          await setallZone(options)
          if(state === null){
          if (data[0]) {
            await setZone(data[0]['id'])
            await setZoneLabel(data[0]['zoneName'])
          }
        }
          }else {
            await setZone(0)
            await setZoneLabel('')
            await setallZone([])
          }
      }
    )
  } else {
    await setZone(0)
    await setZoneLabel('')
    await setallZone([])
  }}

  useEffect(()=>{
    setZoneFn()
  },[zone, allZone])
  const setZoneFn =() => {
    let zoneNameList = allZone.find((item: any) => {
      return item['value'] === zone
    });
    if (zoneNameList !== undefined) {
      setZoneLabel(zoneNameList['label'])
    }
  }

  useEffect(()=>{
  const getPreviousRate = () => {
    if((branch !== 0) && (serviceprovider !== 0) && (product !== 0) && (zone !== 0))
    AccountService.getPreviousRate(branch, serviceprovider, product, zone).then(
      (data) => {
          if(data !==""){
          setTableData(data.rateContractDetail)
          setContractId(data.id)
          } else {
            setTableData([])
            setContractId(0)
          }
      }
    )
  }
  getPreviousRate();
},[branch, serviceprovider, product, zone])
  
  const handleZoneChange = async (e: any) => {
    await setZone(e.value)
    await setZoneLabel(e.label)
  };
  async function HandleSubmit() {
    try {
      var id: any | null = contractId;
      if(contractId === 0){
        id = 0
      }
      // console.log("id", id, " contractId"  ,contractId)
      const data = await AccountService.postRateContract(id, branch, serviceprovider, shipmentModeId, zone, product, additionalWeight, additionalRate,surcharge, fuelSurcharge, loadingCharge, unloadingCharge, tableData);
      if (data) {
        navigate('/ratecontractlist');
      }
    } catch (error) {
      console.error(error);
    }
   
  }
  // function handleSaveRow(index: number) {
  //   setEditingRowIndex(null); // Reset the editingRowIndex to indicate that editing is finished.
  //   // You can perform any additional logic here before saving the row if needed.
  // }
  
  // function handleCancelEdit(index: number) {
  //   setEditingRowIndex(null); // Reset the editingRowIndex to indicate that editing is canceled.
  //   // You can revert any changes made during editing here if needed.
  // }
  
  const setTableDataValues = () => {
    console.log("editingRowIndex", editingRowIndex)
    if(editingRowIndex !== 100){
      const updatedTableData = tableData.map((item, index) => {
  if(index === editingRowIndex){

    console.log(item, index)
    return {...item, slabRate: slabRate, slabStart : fromWeight, slabEnd: uptoWeight}
  }else {
    return item; // For other items, return them as they are
  }
})
setTableData(updatedTableData)
    } else {
    const addintoTable :any = {id:0, slabRate: slabRate, slabStart : fromWeight, slabEnd: uptoWeight}
    setTableData([...tableData, addintoTable ]);
    }
    setSlabRate(0)
    setfromWeight(0)
    setuptoWeight(0)
    setEditingRowIndex(100)
  }
  
  const setDatatoEdit = (row :any, index:any) => {
    setEditingRowIndex(index)
    setSlabRate(row['slabRate'])
    setfromWeight(row['slabStart'])
    setuptoWeight(row['slabEnd'])
  }

  return (
    <>
      <AccountHeader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>Vendor Rate Contract Details </p></div>
          <VendorHeader />
          <div className=' col-sm-6 inline'>
          <div className=' col-sm-12 inline'>
            <label className='col-4'> Branch</label>
            <Select
              name="branch"
              onChange={handleBranchChange}
              options={allBranch}
              value={{ value: branch, label: branchLabel }}
              className='col-8 '
              styles={customStyles}
            />
          </div>
          <div className=' col-sm-12 inline'>
            <label className='col-4 '> Service Provider</label>
            <Select
              name="serviceprovider"
              onChange={handleServiceproviderChange}
              options={allServiceprovider}
              value={{ value: serviceprovider, label: serviceproviderLabel }}
              className='col-8'
              styles={customStyles}
            />
           
          </div>
          
          </div>
          <div className=' col-sm-6 inline'>
        

          <div className=' col-sm-12 inline'>
            <label className='col-4'>Product</label>
            <Select
              name="product"
              onChange={handleProductChange}
              options={allProduct}
              value={{ value: product, label: productLabel }}
              className='col-8'
              styles={customStyles}
            />
          </div>
          <div className=' col-sm-12 inline'>
            <label className='col-4'> Zone</label>
            <Select
              name="zone"
              onChange={handleZoneChange}
              options={allZone}
              value={{ value: zone, label: zoneLabel }}
              className='col-8'
              styles={customStyles}
            />
          </div>
          </div>
          <div className=' col-sm-6 inline'>
          <div className=' col-sm-12 inline'>
            <label className='col-4 '> Shipment Mode </label>
           <label> {shipmentModeName}</label>
           
          </div>
          </div>
          
        </div>
      </div>


      <div className='container col-11' >
        <div style={{ display: "flex", flexDirection: "row",   marginTop: "10px", paddingBottom: "30px", alignItems: "center" }}>
          <div className='col-10 inline'>
          <div className='col-8 inline'>
            <label className='col-6'>From Weight <input className='margininutleft text-right col-4' type='text' placeholder='0.100' name="fromWeight" value={fromWeight} onChange={(e :any) => {if (/^\d*\.?\d{0,3}$/.test(e.target.value)) {setfromWeight(e.target.value)}}} />
              &nbsp;  in kg.</label>
          
          <label className='col-6'>To Weight<input className='margininutleft text-right col-4' type='text' placeholder='0.500' name='uptoWeight' value={uptoWeight} onChange={(e :any) => {if (/^\d*\.?\d{0,3}$/.test(e.target.value)) { setuptoWeight(e.target.value)}}}/>
          &nbsp;   in kg.</label>
          </div>

          <div className='col-4 inline'>
            <label >Rate</label>
            <input className='margininutleft text-right' type='text' name='slabRate' value={slabRate} onChange={(e :any) => {if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {setSlabRate(e.target.value)}}} />
          </div>

          <div className='inline' >
            <Link className=' btn2' to="" style={{ width: "80px", zIndex: "0" }} onClick={setTableDataValues} >Add</Link>
            <Link className=' btn2' to="" style={{ width: "80px", zIndex: "0" }}>Cancel</Link>

          </div>
          </div>
        </div>


        <div className='container width' style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "86%", paddingBottom: "15%", marginBottom: "10px" }}>
        <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Sr. No. </th>
            <th>From Weight</th>
            <th>To Weight</th>
            <th>Rate</th>
          </tr>
        </thead>
        <tbody>
  {tableData.map((row, index) => (<>
  <tr onClick={() => setDatatoEdit(row, index)}>
    <td> {index + 1}</td>
    <td> {row['slabStart']}</td>
    <td> {row['slabEnd']}</td>
    <td> {row['slabRate']}</td>
  </tr>
  </>
  ))}

</tbody>

      </Table>

        </div>

        <div className='container col-11' style={{ display: "flex", justifyContent: "space-evenly",padding:"5px" }}>
          <div className='col-4' >
            <div className='col-12 inline'>
              <label className='col-8' style={{ marginRight: "10px" }}>Additional Weight</label>
              <input className='col-4' type='text' name='additionalWeight' value={additionalWeight} onChange={(e:any) => {if (/^\d*\.?\d{0,3}$/.test(e.target.value)) {setadditionalWeight(e.target.value)}}} ></input>
            </div>
            <div className='col-12 inline'>
              <label className='col-8' style={{ marginRight: "10px" }}>Additional Rate</label>
              <input className='col-4' type='text' name='additionalRate' value={additionalRate} onChange={(e:any) => {if (/^\d*\.?\d{0,3}$/.test(e.target.value)) {setadditionalRate(e.target.value)}}}></input>
            </div>
          </div>

          <div className='col-4'>
            <div className='col-12 inline'>
              <label className='col-8' style={{ marginRight: "10px" }}>IDC %</label>
              <input className='col-4' type='text' name='surcharge' value={surcharge} onChange={(e:any) => {if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {setsurcharge(e.target.value)}}}></input>
            </div>
            <div className='col-12 inline'>
              <label className='col-8' style={{ marginRight: "10px" }}>Fuel Surcharge %</label>
              <input className='col-4' type='text' name='fuelSurcharge' value={fuelSurcharge} onChange={(e:any) => {if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {setfuelSurcharge(e.target.value)}}}></input>
            </div>
          </div>
          <div className='col-4'>
            <div className='col-12 inline'>
              <label className='col-8' style={{ marginRight: "10px" }}>Docket </label>
              <input className='col-4' type='text' name='loadingCharge' value={loadingCharge} onChange={(e:any) => {if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {setloadingCharge(e.target.value)}}}></input>
            </div>
            <div className='col-12 inline'>
              <label className='col-8' style={{ marginRight: "10px" }}>ROV</label>
              <input className='col-4' type='text' name='unloadingCharge' value={unloadingCharge} onChange={(e:any) => {if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {setunloadingCharge(e.target.value)}}}></input>
            </div>
          </div>


        </div>
      </div>
      <div className='btn d-flex justify-content-center'>
        <Link className="btn2 col-2 col-sm-1"onClick={HandleSubmit} to="../ratecontract"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link>
        <button className="btn2 col-2 col-sm-1" ><i className="fa fa-close"></i>   Cancel</button>
        <Link className="btn2 col-2 col-sm-1" to="/ratecontractlist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
      </div>
    </>

  )
}

export default RateContract