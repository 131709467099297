import { useEffect, useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import InwardLogService from '../../services/inwardlog.service';
import {  useNavigate } from 'react-router-dom'
import ReportContainerHeader from'../reports/ReportContainerHeader';
import ReportService from '../../services/report.service';
import Swal from 'sweetalert2';
import moment from 'moment';
// import e from 'express';
// import MasterDataService from '../../services/masterData.service';


const AdminMaterialReport = () => {
    const [fromDate, setfromDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [toDate, settoDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
   
    const [to, setto] = useState<any | null>((localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'');
    // const [adminReportField, setadminReportField] = useState<any | []>(localStorage.getItem('AdminReportfields'));
    const [cc, setcc] = useState<any | null>((localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'');
    const [title, settitle] = useState<any | null>((localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'');
    const [receiver, setreceiver] = useState<any | null>((localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'');
    const [subject, setsubject] = useState<any | null>((localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'');
    const [content, setcontent] = useState<any | null>((localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'');
    const [signature, setsignature] = useState<any | null>((localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :'');

    const [prevvalues,setprevvalues]=useState<any | null>([]);
   

  const mailRoomId = localStorage.getItem('mailRoomId');
  const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
  const API_URL = process.env.REACT_APP_BASE_URL_API;

//     const mailProvider = [{
//        fromDate: "",
//         toDate: "",
//         to: to,
//         title: title,
//         receiver:receiver ,
//         subject: subject,
//         content:content ,
//         signature:signature 
// }]




    const navigate = useNavigate();

    // function sendMail() {

    //     InwardLogService.PostInwardMail(title, signature, receiver, subject, content, to, fromDate, toDate).then(
    //         (data) => {
    //             console.log("Resistration Successful");
    //             console.log(data.statuscode)
    //             if (data.statuscode === 200) {
    //                 settitle(data.message[0].title);
    //                 navigate('/dashboard');
    //             }
    //         }

    //     ).catch((error) => {
    //         console.log(error);
    //         console.log("Server Responding");
    //     }
    //     )
    // }

     const generateadminmaterialreport = async () => {
       
        const bodyParamter = {
            reportType:0,
            fromDate: fromDate,
            toDate: toDate,
            to: to,
            title: title,
            receiver:receiver ,
            subject: subject,
            content:content ,
            signature:signature,
            mailRoomId:mailRoomId,
            branch:branch,
        }
     await ReportService.generateadminmaterialreport(
          bodyParamter
        ).then(
           (data) => {
              const link = document.createElement('a');
              link.href = API_URL+"uploads/"+data; // Replace with the actual file path
              link.download = 'adminmaterial.xlsx';
              link.target = '_blank'; // Optional: Specify a different filename for the downloaded file
              link.click();
          }
        ).catch((error) => {
          console.log(error)
        })
      } 
      const sendadminmaterialreport = async () => {
        if ((to !== "") && (subject !== "") && (content !== "")) {
        const bodyParamter = {
            reportType:1,
            fromDate: fromDate,
            toDate: toDate,
            to: to,
            title: title,
            receiver:receiver,
            subject: subject,
            content:content ,
            signature:signature,
            mailRoomId:mailRoomId,
            branch:branch,
        }
     await ReportService.generateadminmaterialreport(
          bodyParamter
        ).then(
           (data) => {
            console.log(data)
            if (data === "200") {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "Admin Material Report send Succesfully!!",
                  showConfirmButton: false,
                  timer: 1500,
                  customClass: {
                    title: 'swal-fire-alert', // Add your custom CSS class here
                  },
                }).then(function () {
                  navigate('../adminmaterialoutreport');
                });
              }else{
                 Swal.fire({
                  position: 'center',
                  title: "Mail not send, please check the email Id",
                  showConfirmButton: false,
                  timer: 1500,
                  customClass: {
                    title: 'swal-fire-alert', // Add your custom CSS class here
                  },
                }).then(function () {
                  navigate('../adminmaterialoutreport');
                });
              }
          }
        )
        }
        else {
            let errorMessage = '';
            if ((to === "")) {
                errorMessage = "Please Enter email address";
            } else if (title === "") {
                errorMessage = "Please Enter Title";
            }   else if (subject === "") {
                errorMessage = "Please Enter Subject";
            } else if (content === "") {
                errorMessage = "Please Enter content";
            }

            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: errorMessage,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                    title: 'swal-fire-alert',
                },
            });
        }
      } 
     
      
      useEffect(()=>{

        interface MyObject {
          sendTo: any,
          receiverName: any,
          mailcc:any,
          mailtitle:any,
          mailsubject:any,
          mailcontent:any,
          mailsignature:any
         }
      
      let adminFields: MyObject = {
        "sendTo" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'',
         "receiverName": (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'',
         "mailcc" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'',
         "mailtitle" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'',
         "mailsubject" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'',
         "mailcontent" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'',
         "mailsignature" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :''
      }



    // const adminFields : {
    //   sendTo: any,
    //   receiverName: any,
    //   mailcc:any,
    //   mailtitle:any,
    //   mailsubject:any,
    //   mailcontent:any,
    //   mailsignature:any
    // } = 
    // {
    //  "sendTo" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'',
    //  "receiverName": (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'',
    //  "mailcc" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'',
    //  "mailtitle" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'',
    //  "mailsubject" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'',
    //  "mailcontent" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'',
    //  "mailsignature" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :''
    //  }
    
                   localStorage.setItem('AdminReportfields',JSON.stringify(adminFields))   
                  },[])

                const  onInputChange = (e :any) =>{
                    interface MyObject {
                        sendTo: any,
                        receiverName: any,
                        mailcc:any,
                        mailtitle:any,
                        mailsubject:any,
                        mailcontent:any,
                        mailsignature:any
                       }
                      
                    const { name, value } = e.target; 
                    switch (name) {
                        case 'to':
                        setto(value);
                        let adminFields: MyObject = {
                           "sendTo" : value,
                           "receiverName": (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'',
                           "mailcc" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'',
                           "mailtitle" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'',
                           "mailsubject" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'',
                           "mailcontent" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'',
                           "mailsignature" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :''
                        }
                        localStorage.removeItem('AdminReportfields');
                        localStorage.setItem('AdminReportfields',JSON.stringify(adminFields))
                         break;
                         case 'cc':
                            setcc(value);
                            let adminFieldscc: MyObject = {
                               "sendTo" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'',
                               "receiverName": (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'',
                               "mailcc" : value,
                               "mailtitle" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'',
                               "mailsubject" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'',
                               "mailcontent" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'',
                               "mailsignature" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :''
                            }
                            localStorage.removeItem('AdminReportfields');
                            localStorage.setItem('AdminReportfields',JSON.stringify(adminFieldscc))
                             break;
                         case 'title':
                         settitle(value);
                         let adminFields1: MyObject = {
                            "sendTo" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'',
                            "receiverName": (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'',
                            "mailcc" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'',
                            "mailtitle" : value,
                            "mailsubject" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'',
                            "mailcontent" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'',
                            "mailsignature" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :''
                         }
                         localStorage.removeItem('AdminReportfields');
                         localStorage.setItem('AdminReportfields',JSON.stringify(adminFields1))
                        break;
                        case 'receiver':
                        setreceiver(value);
                        let adminFields2: MyObject = {
                            "sendTo" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'',
                            "receiverName": value,
                            "mailcc" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'',
                            "mailtitle" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'',
                            "mailsubject" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'',
                            "mailcontent" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'',
                            "mailsignature" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :''
                         }
                         localStorage.removeItem('AdminReportfields');
                         localStorage.setItem('AdminReportfields',JSON.stringify(adminFields2))
                        break;
                        case 'subject':
                        setsubject(value);
                        let adminFields3: MyObject = {
                            "sendTo" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'',
                            "receiverName": (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'',
                            "mailcc" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'',
                            "mailtitle" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'',
                            "mailsubject" : value,
                            "mailcontent" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'',
                            "mailsignature" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :''
                         }
                         localStorage.removeItem('AdminReportfields');
                        localStorage.setItem('AdminReportfields',JSON.stringify(adminFields3))
                        break;
                        case 'content':
                        setcontent(value);
                        let adminFields4: MyObject = {
                            "sendTo" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'',
                            "receiverName": (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'',
                            "mailcc" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'',
                            "mailtitle" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'',
                            "mailsubject" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'',
                            "mailcontent" : value,
                            "mailsignature" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsignature :''
                         }
                         localStorage.removeItem('AdminReportfields');
                         localStorage.setItem('AdminReportfields',JSON.stringify(adminFields4))
                        break;
                        case 'signature':
                        setsignature(value);
                        let adminFields5: MyObject = {
                            "sendTo" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).sendTo :'',
                            "receiverName": (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).receiverName :'',
                            "mailcc" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcc :'',
                            "mailtitle" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailtitle :'',
                            "mailsubject" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailsubject :'',
                            "mailcontent" : (localStorage.AdminReportfields) ? JSON.parse(localStorage.AdminReportfields).mailcontent :'',
                            "mailsignature" : value
                         }
                         localStorage.removeItem('AdminReportfields');
                         localStorage.setItem('AdminReportfields',JSON.stringify(adminFields5))
                        break;
                        default:
                        break;
                    }
                //    const savedValues = localStorage.getItem('AdminReportfields');
                //     if (savedValues) {
                //       setprevvalues(savedValues);
                //     }
                //     console.log("setprevvalues : "+JSON.stringify(prevvalues));
                //   setsubject(e.target.value)
                //   let arr :any =(..._prevvalues: any[],"mailsubject" : {e.target.value});
                //   localStorage.setItem('AdminReportfields', arr); 
                }
                 
    return (
        <div>
            <ReportContainerHeader />

            <div style={{margin: "0px" }}>
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
                <div className='container col-11'>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="themeColor width"><p className='m-0' style={{ marginLeft: "10px", alignItems: "center" }}>Admin Material Report </p></div>
                    </div>
                    <div className="form card container col-11" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                        {/* <div className=" "> */}



                        <div className="inline width mail-filters">
                           <div className="col-2 fromdate"><label>From Date</label></div>
                            <div className="filters"><input className="select" type="date" name='fromDate' value={fromDate} onChange={e => setfromDate(e.target.value)}></input></div>
                            <div className="col-2 fromdate"><label>To Date</label></div>
                            <div className="filters"><input className="select" type="date" name='toDate' value={toDate} onChange={e => settoDate(e.target.value)}></input></div>
                            <div className="col-2 fromdate">
                    <button className="btn col-4 col-sm-4" type="button" onClick={generateadminmaterialreport} 
                      rel="noreferrer" ><i className="fa fa-download"></i></button>  
                  </div></div>
                        {/* </div> */}
                        <div className="width container card padding"style={{marginBottom:"10px"}}>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8 '> Title / Receiver</label></div>

                                <input type='text' style={{ marginBottom: "3vh" }} className='col-8 col-sm-2' name='title' value={title} onChange = {onInputChange} />
                                <input type='text' style={{ marginBottom: "3vh" }} className='col-8 col-sm-6' name='receiver' value={receiver} onChange = {onInputChange} />
                            
                            </div>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8'> To Mail Id(s)</label></div>
                                <input type='text' style={{ marginBottom: "3vh" }} className='col-8 col-sm-8' name='to' value={to} onChange = {onInputChange}/>
                            </div>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'> <label className='col-8' >CC Mail Id(s) </label></div>
                                <input style={{ marginBottom: "3vh" }} type='text' className='col-8 col-sm-8' name='cc' value={cc} onChange = {onInputChange} />
                            </div>
                            
                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'><label className='col-8' >Subject </label></div>
                                <input style={{ marginBottom: "3vh" }} type='text' className='col-8 col-sm-8' name='subject' value={subject} 
                                    // onChange = {e=>{
                                    // setsubject(e.target.value)
                                    // localStorage.setItem('AdminReportfields', JSON.stringify({"mailsubject" : e.target.value}))    
                                    //   }}
                                    onChange={onInputChange}
                                 />
                            </div>

                            <div className='mail-inputs col-12 inline'>
                                <div className='col-3 mail-label'><label className='col-8' >Content </label></div>
                                <textarea style={{ marginBottom: "3vh" }} className='col-8 col-sm-8' name='content' value={content} onChange = {onInputChange}/>
                            </div>
                            <div className='mail-inputs col-12 inline signature'>
                                <div className='col-3 mail-label'><label className='col-8' >Signature </label></div>
                                <textarea style={{marginTop:"2px"}} rows={5} className='col-8 col-sm-5' name='signature' value={signature} onChange = {onInputChange} />
                                <div className='col-4 mail-btns'>
                                    <button className='btn2  mail-btn' onClick={sendadminmaterialreport}>Send</button>
                                    <button className='btn2 mail-btn'>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
          
        </div>
    )
}

export default AdminMaterialReport