import '../headerfooter/headerfootercss.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterMobileNav from './footerMobileNav';
import Footer from './Footer';

import { useState } from 'react';
import { Link } from 'react-router-dom';

function Headermaillink() {
const [show, setShow] = useState(true);
  

  return (
    <>    
      <div className='sticky onTop'>
        {show && ( 
        <div className='' style={{marginBottom:'15px',marginTop:'10px'}}>
          <div className='col-12 col-sm-6 header-image1'><Link to=""><img className='header-image margin-top' src='../../assets/upperside-image.png' /></Link></div>
        </div>
        )}
        <FooterMobileNav />
        <Footer/>
      </div>
    </>

  );
} export default Headermaillink;