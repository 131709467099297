import React, { useEffect, useState } from 'react'
import MasterDataMailroomService from '../../services/MasterDataMailroom.service';
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import SettingHeader from './settingHeader';
import Swal from 'sweetalert2'



const UserList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [allUsers, setallusers] = useState([]);

  //Table Start

interface selectRowType {
  mode: any;
  clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
  style: any;
  onSelect: any;
}

const selectRow: selectRowType = {
  mode: "radio",
  clickToSelect: true,
  style: { backgroundColor: '#c8e6c9' },
  onSelect: (row: any) => {
    setSelectedRecords(row.id);
  }
}
// ...

  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
   const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'firstName', text: 'First Name', align: 'left'},   
    { dataField: 'lastName', text: 'Last Name',  align: 'left'},
    { dataField: 'email', text: 'Email',  align: 'left'},
    { dataField: 'username', text: 'User Name',  align: 'left'},
  ];

  //-----------------------Table End---------------
  
  const GetUsers = async () => {
    await MasterDataMailroomService.getUsers().then(
      (data) => {
        setallusers(data);
      }
    )
  }
  useEffect(() => {
    GetUsers();
  }, [])

  const navigate = useNavigate();
  const EditData = () => {
    navigate('/users', { state: selectedRecords });
  }
  const filteredData = Array.isArray(allUsers) ? allUsers.filter((selectedRecords) => {
    const values = Object.values(selectedRecords);
    return values.some((value: unknown): value is string =>
  typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
);
  }) : [];

  return (
    <>
      <SettingHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
         
          <div className="container col-11" style={{ paddingLeft: "0px" }}>         
          <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}> Branch List </p></div>

            <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "end",alignItems:"center" }}>
              <input
                className='searchbar col-3'
                type='text'
                value={searchQuery}
                placeholder='Search Here ...'
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <BootstrapTable
              rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign: "center" }}
              keyField='id'
              striped
              hover
              condensed
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />

          </div>
        </div>
      </div>
      <div className='btn d-flex justify-content-center'>
        <Link to="/users" className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
         <button className="btn2 col-2 col-sm-1" onClick={EditData}><i className="fa fa-edit"></i> Edit</button>
       <button className="btn2 col-2 col-sm-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
     </div>

    </>
  );
      }


export default UserList