import axios from "axios";


const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy =  localStorage.getItem('userId');
const updatedBy =  localStorage.getItem('userId');
const mailRoomId =  localStorage.getItem('mailRoomId');
const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const config = {
    headers: {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
};
// BranchSubmit---------------------------------------------------------------------------------------------------------------------------

const PostBranch = async (branchName:any, branchCode:any, emailAddress:any, whatsAppNumber :any, whatsAppInstanceId:any, whatsAppLink:any, smtpServer:any, smtpPort:any, smtpEmail:any, smtpPassword:any,smtpsecurityType:any) => {
    const bodyParamter = {
        branchName:branchName,      
        branchCode:branchCode,
        mailRoomId,
        emailAddress,
        branchStatus : 1,
        whatsAppNumber, 
        whatsAppInstanceId,
        whatsAppLink,
        smtpServer,
        smtpPort,
        smtpEmail,smtpPassword,securityType:smtpsecurityType,
        createdBy
    };
    return axios
        .post(API_URL + "branch/register", bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}

// Branchedit---------------------------------------------------------------------------------------------------------------------------

const PutBranch = async (branchName:any, branchCode:any, emailAddress:any, whatsAppNumber :any, whatsAppInstanceId:any, whatsAppLink:any, smtpServer:any, smtpPort:any, smtpEmail:any, smtpPassword:any, id:any) => {
       const bodyParamter = {
        branchName:branchName,      
        branchCode:branchCode,
        branchStatus : 1,
        whatsAppNumber, 
        emailAddress,
        whatsAppInstanceId,
        whatsAppLink,
        smtpServer,
        smtpPort,
        smtpEmail,smtpPassword,updatedBy
        };
        
    return axios
        .put(API_URL + "branch/"+id, bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}
// Branchdelete---------------------------------------------------------------------------------------------------------------------------

const DeleteBranch = async (id:any) => {    
    return axios
        .delete(API_URL + "branch/"+id, config).then(
            (response) => {
                return response.data.message;
            }).catch(error => console.log(error));
}

// BranchGet--------------------------------------------------------------------------------------------------------------

const GetBranch = async () => {
    const bodyParamter = {
        mailRoomId:mailRoomId
        };
    return axios.post(API_URL + "branch/", bodyParamter, config).then(
        (response) => {          
            if(response.data.statuscode === "200"){
                return response.data.message
            }

        }
    )
        .catch(error => console.log(error))

}

// BranchGet by id--------------------------------------------------------------------------------------------------------------

const GetBranchId = async (id:any) => {
  

    return axios.get(API_URL + "branch/"+id, config).then(
        (response) => {
            return response.data.message;
           
        }
    )
        .catch(error => console.log(error))

}
// CostCenterSubmit---------------------------------------------------------------------------------------------------------------------------

const PostCostCenter = async (costCenter:any, billingDept:any) => {
    const bodyParamter = {
        costCenter:costCenter,      
        billingDept:billingDept,
        mailRoomId,
        branch,
        createdBy
    };
    return axios
        .post(API_URL + "costcenter/register", bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}

// CostCenteredit---------------------------------------------------------------------------------------------------------------------------

const PutCostCenter = async (costCenter:any, billingDept:any,id:any) => {
       const bodyParamter = {
        costCenter,      
        billingDept,
        updatedBy
        };
        
    return axios
        .put(API_URL + "costcenter/"+id, bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}
// CostCenterdelete---------------------------------------------------------------------------------------------------------------------------

const DeleteCostCenter = async (id:any) => {    
    return axios
        .delete(API_URL + "costcenter/"+id, config).then(
            (response) => {
                return response.data.message;
            }).catch(error => console.log(error));
}

//CostCenterGet--------------------------------------------------------------------------------------------------------------

const GetCostCenter = async () => {
    const bodyParamter = {
        mailRoomId:mailRoomId,      
        branch:branch
        };
    return axios.post(API_URL + "costcenter/", bodyParamter, config).then(
        (response) => {           
            if(response.data.statuscode === "200"){
                return response.data.message
            }
        }
    ).catch(error => {return ""})

}

// CostCenterGet by id--------------------------------------------------------------------------------------------------------------

const GetCostCenterId = async (id:any) => {
  

    return axios.get(API_URL + "costcenter/"+id, config).then(
        (response) => {
            return response.data.message;
           
        }
    )
        .catch(error => console.log(error))

}
// DepartmentSubmit---------------------------------------------------------------------------------------------------------------------------

const PostDepartment = async (name:string, routeId:any) => {
    const bodyParamter = {
        name,   
        routeId, 
        tCode :  Date.now().toString(),
        mailRoomId,
        branch,
        departmentStatus: 1,
        createdBy
    };
    return axios
        .post(API_URL + "department/register", bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}

// Departmentedit---------------------------------------------------------------------------------------------------------------------------

const PutDepartment = async (name:string,  routeId:Number, id:any) => {
       const bodyParamter = {
        name,   
        routeId,
        tCode :  Date.now().toString(),
        mailRoomId,
        branch,
        departmentStatus: 1,
        updatedBy
        };
        
    return axios
        .put(API_URL + "department/"+id, bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}
// Departmentdelete---------------------------------------------------------------------------------------------------------------------------

const DeleteDepartment = async (id:any) => {    
    return axios
        .delete(API_URL + "department/"+id, config).then(
            (response) => {
                return response.data.message;
            }).catch(error => console.log(error));
}

//DepartmentGet--------------------------------------------------------------------------------------------------------------

const GetDepartment = async () => {
    const bodyParamter = {
        mailRoomId:mailRoomId,      
        branch:branch
        };
    return axios.post(API_URL + "department/", bodyParamter, config).then(
        (response) => {           
            if(response.data.statuscode === "200"){
                return response.data.message
            }
        }
    ).catch(error => {return ""})

}

// Department Get by id--------------------------------------------------------------------------------------------------------------

const GetDepartmentId = async (id:any) => { 
    return axios.get(API_URL + "department/"+id, config).then(
        (response) => {
            return response.data.message;
           
        }
    )
        .catch(error => console.log(error))

}
// RouteSubmit---------------------------------------------------------------------------------------------------------------------------

const PostRoute = async (routeTime:any, routeName:string,sunday:number,monday:number,tuesday:number,wednesday:number,thursday:number,friday:number,saturday:number) => {
    const bodyParamter = {
        routeTime, routeName,sunday,monday,tuesday,wednesday,thursday,friday,saturday,  mailRoomId,  branch, createdBy
    };
    return axios
        .post(API_URL + "deliveryroute/register", bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}

// Routeedit---------------------------------------------------------------------------------------------------------------------------

const PutRoute = async (routeTime:any, routeName:string,sunday:number,monday:number,tuesday:number,wednesday:number,thursday:number,friday:number,saturday:number,id:any) => {
       const bodyParamter = {
        routeTime, routeName,sunday,monday,tuesday,wednesday,thursday,friday,saturday, updatedBy
        };
        
    return axios
        .put(API_URL + "deliveryroute/"+id, bodyParamter, config).then(
            (response) => {
                return response.data.message;
            })
        .catch(error => console.log(error));

}
//----------------- Route delete---------------------------------------------------------------------------------------------------------------------------

const DeleteRoute = async (id:any) => {    
    return axios
        .delete(API_URL + "deliveryroute/"+id, config).then(
            (response) => {
                return response.data.message;
            }).catch(error => console.log(error));
}

//-----------------------RouteGet--------------------------------------------------------------------------------------------------------------

const GetRoute = async () => {
    const bodyParamter = {
        mailRoomId,      
        branch
        };
    return axios.post(API_URL + "deliveryroute/", bodyParamter, config).then(
        (response) => {           
            if(response.data.statuscode === "200"){
                return response.data.message
            }
        }
    ).catch(error => {return ""})

}

// Route Get by id--------------------------------------------------------------------------------------------------------------

const GetRouteId = async (id:any) => { 

    return axios.get(API_URL + "deliveryroute/"+id, config).then(
        (response) => {
            return response.data.message;
           
        }
    )
        .catch(error => console.log(error))

}
//----------------------- Routes End Here --------------------------

//------------------------UserS Start Here --------------------------

const getUsers = () => {
    const bodyParamter = {
        mailRoomId,      
        branch
        };
    return axios.post(API_URL + "login/" , bodyParamter, config).then(
        (response) => {
            return response.data.message;
           
        }
    )
        .catch(error => console.log(error))  
}

const GetUserById = (id:Number) => {
    return axios.get(API_URL + "login/" + id , config).then(
        (response) => {
            return response.data.message;
           
        }
    )
        .catch(error => console.log(error))  
}

const postUsers = (firstName :string, lastName:string, fullAddress: string, phoneNumber :string, email :string, username :string, password : string, branch: number, roles :Number) => {
    const bodyParamter = {
        firstName,
        lastName,
        fullAddress,
        phoneNumber,
        email,
        username,
        password,
        branch,
        mailRoomId,        
        loginStatus :1,
        roles
    }
    // console.log(bodyParamter)
    return axios.post(API_URL + "login/register/" ,bodyParamter , config).then(
        (response) => {
            return response.data.message;           
        }
    )
        .catch(error => console.log(error))    
}

const putUsers = (firstName :string, lastName:string, fullAddress: string, phoneNumber :string, email :string, username :string, password : string, branch: number,roles :Number, id: Number) => {
    const bodyParamter = {
        firstName,
        lastName,
        fullAddress,
        phoneNumber,
        email,
        username,
        password,
        branch,
        mailRoomId,
        loginStatus :1,
    }
    return axios.put(API_URL + "login/"+ id ,bodyParamter , config).then(
        (response) => {
            return response.data.message;           
        }
    )
        .catch(error => console.log(error))    
}

//-------------------------- Users End Here -------------------------
const MasterDataMailroomService = {
    GetBranch,
    GetBranchId,
    DeleteBranch,
    PutBranch,
    PostBranch,
    GetCostCenter,
    GetCostCenterId,
    DeleteCostCenter,
    PutCostCenter,
    PostCostCenter,
    GetDepartment,
    GetDepartmentId,
    DeleteDepartment,
    PutDepartment,
    PostDepartment,
    GetRoute,
    GetRouteId,
    DeleteRoute,
    PutRoute,
    PostRoute,
    getUsers,
    putUsers,
    postUsers,
    GetUserById,
}
export default MasterDataMailroomService