import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
// const createdBy = localStorage.getItem('userId');
// const updatedBy = localStorage.getItem('userId');
const mailRoomId = localStorage.getItem('mailRoomId');
// const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";

const config = {
    headers: {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
};
// --------------------Product -------------
const putmailroom = async (mailRoomName:string,mailRoomOwner:string,logo:any,emailAddress:string,whatsAppNumber:string,whatsAppInstanceId:string,whatsAppLink:string,smtpServer:string,smtpPort:string,smtpEmail:string,smtpPassword:string) => {
    const bodyParamter = {
        mailRoomName,mailRoomOwner,filename : logo,emailAddress,whatsAppNumber,whatsAppInstanceId,whatsAppLink,smtpServer,smtpPort,smtpEmail,smtpPassword,
        mailRoomId
        };

    return axios.put(API_URL + "mailroom/"+mailRoomId, bodyParamter, config).then(
        (response) => {    
            return response.data.message;
        }
    )
        .catch(error => console.log(error));

}
const getMailroom = async () => {
    return axios.get(API_URL + "mailroom/", config).then(
        (response) => {
            return response.data.message
            
        })
        .catch(error => console.log(error))
}

const putprimarysettings = async (IntimationMail:any, intimationtoConsignor:any, intimationtoConsignee:any, DrsMail:any, DrsMailtoemployee:any, DrsMailtodeliverystaff:any,UpdationMail:any,UpdationMailtoconsignor:any,UpdationMailtoconsignee:any,outIntimationMail:any,
    outintimationtoConsignor:any,outintimationtoConsignee:any,ManifestMail:any,ManifestMailtoemployee:any,ManifestMailtodeliverystaff:any,outUpdationMail:any,outUpdationMailtoconsignor:any,outUpdationMailtoconsignee:any,selectedratechart:any) => {
    const bodyParamter = {
        inwardSendMail:IntimationMail, intimationtoConsignor, intimationtoConsignee, drsSendMail:DrsMail, DrsMailtoemployee, DrsMailtodeliverystaff,statusUpdateSendMail:UpdationMail,UpdationMailtoconsignor,UpdationMailtoconsignee,outwardSendMail:outIntimationMail,
        outintimationtoConsignor,outintimationtoConsignee,manifestSendMail:ManifestMail,ManifestMailtoemployee,ManifestMailtodeliverystaff,outUpdationMail,outUpdationMailtoconsignor,outUpdationMailtoconsignee,selectedratechart,
        mailRoomId
        };

    return axios.post(API_URL + "login/mailsetting", bodyParamter, config).then(
        (response) => {    
            return response.data.message;
        }
    )
        .catch(error => console.log(error));

}

const getuserDetails = async () => {
    return axios.get(API_URL + "login/"+localStorage.getItem('userId'), config).then(
        (response) => {
            return response.data.message
            
        })
        .catch(error => console.log(error))
}

const MailroomService = {
    putmailroom,
    getMailroom,
    putprimarysettings,
    getuserDetails

}

export default MailroomService;