import { useEffect, useRef, useState } from 'react'
import Inwarddetailsercice from '../../services/inwarddetail.service';
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment-timezone';
import InwardContainerHeader from './InwardContainerHeader';

function InwardDetaillist() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [selectedAWBNumber, setSelectedAWBNumber] = useState();
  const [inwardDateSearch, setinwardDateSearch] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allInwardDetails, setAllInwardDetails] = useState([]);
  const addButtonRef = useRef<any>(null);
  
 const dateformater = (data: any, row: any) => {
    return  (moment(data)).format('DD/MM/YYYY')
  }
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
      setSelectedAWBNumber(row.AWB);
    }
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };
const timeFormatChange  = (data: any, row: any) => {
  if(data.length > 8){
    const dataAsDate = new Date(data);
    if (!isNaN(dataAsDate.getTime())) {
      // Convert Date object to ISO string
      return  dataAsDate.toISOString().replace("1970-01-01T", "").substring(0, 8);
      // console.log('ISO String:', isoString);
    } else {
      console.error('Invalid date format:', data);
    } 
  } else {
    return data;
  }
}
 

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'Sr. No.',  align: 'center'},
    { dataField: 'inwardDate', text: 'Inward Date', sort: true },
    { dataField: 'inwardTime', text: 'Inward Time', formatter :timeFormatChange },
    { dataField: 'isrNumber', text: 'ISR Number' },
    { dataField: 'AWB', text: 'AWB Number' },
    { dataField: 'consignerName', text: 'Consignor' },
    { dataField: 'employeeName', text: 'Employee' },
    { dataField: 'inwardQuantity', text: 'Pcs.' },
    { dataField: 'inwarddetailStatusDescription', text: 'Status' },
    { dataField: 'remark', text: 'Remark', 
    // formatter: (cell, row :any) => {
    //   let remarksLabel = "";
    //   if (row.receivedBy !== null) {
    //     remarksLabel = "Receiver : "+row.receivedBy;
    //   }
      
  
    //   if (row.missRouteReason !== null) {
    //     remarksLabel = remarksLabel + ', ' + row.missRouteReason;
    //   }
  
    //   if (row.deliveryRemark !== null) {
    //     remarksLabel = remarksLabel + ', ' + row.deliveryRemark;
    //   }
    //   if (row.remark !== null) {
    //     remarksLabel = remarksLabel + ', ' + row.remark;
    //   }
  
    //   return <div>{remarksLabel}</div>;
    // }
    formatter: (cell, row :any) => {
      let remarksLabel = "";
      if (row.remark !== null && row.remark !="") {
        remarksLabel = `<span style="font-size: smaller;">Log :${row.remark}</span><br/>`;
      }
      if (row.missRouteReason !== null && row.missRouteReason !="") {
        remarksLabel += `<span style="font-size: smaller;">MissRoute : ${row.missRouteReason}</span><br/>`;
      }

      if (row.deliveryRemark !== null && row.deliveryRemark != "") {
        remarksLabel += `<span style="font-size: smaller;">Delivery : ${row.deliveryRemark}</span><br/>`;
      }     
      if (row.receivedBy !== null) {
        remarksLabel += `<span style="font-size: smaller;"> Receiver : ${row.receivedBy}</span>`;
      }

      return <div dangerouslySetInnerHTML={{ __html: remarksLabel }} />;
    }
  },


  ];
  //Table End  
  const getInwardDetailList = async (inwardDateSearch:any) => {
    await Inwarddetailsercice.getInwardDetailsList(inwardDateSearch).then(
      (data) => {
        if (data) {
          setAllInwardDetails(data);
        }
      }
    );
  }
  useEffect(() => {
    getInwardDetailList(inwardDateSearch);
  }, [inwardDateSearch])
  useEffect(() => {
    setinwardDateSearch((moment(new Date())).format('YYYY-MM-DD'));
    if (addButtonRef.current !== null) {
      addButtonRef.current.focus();
    }
  }, [])

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (selectedRecords !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Inwarddetailsercice.deleteInwardDetails(selectedRecords)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Inward Details Entry has been deleted",
          }).then(function () {
            window.location.reload()
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Inward Details Entry is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
    }
  }

  const navigate = useNavigate();
  const EditDelivery = () => {
    if (selectedRecords !== undefined) {
      navigate('../inworddetails', { state: selectedRecords });
    }
  }

  const handleTracking = () => {
    if (selectedRecords !== undefined) {
      navigate('../inwardtracking', { state: selectedAWBNumber });
    } 
  }

  const filteredData = Array.isArray(allInwardDetails)
    ? allInwardDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];
  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Inward Details </p></div>
            <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }}>
              <div className='margincard1itams' style={{ marginLeft: "10px" }}>
                <label> Inward Date</label>
                <input className='margininutleft' type='Date' value={inwardDateSearch} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setinwardDateSearch(e.target.value) }} />
              </div>
              <div className='margincard1itams'>
                <label >Branch Name :  {(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branchName : ""} </label>
              </div>
              <div className='col-6' style={{ display: "flex", height: "50px", justifyContent: "end" }}>
                <input
                  className='searchbar col-6'
                  type='text'
                  value={searchQuery}
                  placeholder = 'Search Here ...'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive tableContainer">
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              // pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
</div>
          </div>
          <div className='row col-12 d-flex justify-content-center'>
            <Link to="../inworddetails" ref={addButtonRef} className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
            <button className="btn2 col-2 col-sm-1" onClick={EditDelivery}><i className="fa fa-edit"></i> Edit</button>
            <button className="btn2 col-2 col-sm-1" onClick={handleTracking} ><i className="fa fa-map-marker"></i>ShipmentTrack</button>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} ><i className="fa fa-trash"></i> Delete</button>
            <Link to="/inwardimport" className="btn2 col-2 col-sm-1"> <i className="fa fa-upload" aria-hidden="true"></i>Import</Link>
            <button className="btn2 col-2 col-sm-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default InwardDetaillist