import moment from 'moment';
function DatewiseTable(props:any) {
  return (        
      <table className="table1 width ">
        <thead className="thead" style={{border : '1px solid #ccc'}}>
        <tr>
            <th> Inward Count for   </th>
            <th colSpan={props.lastDate} style={{textAlign : "left", color: "#000", backgroundColor : '#FFF', border : 'none'}}>{ moment(props.selectedYear +'-'+ props.selectedMonth +'-01').format('MMM-YYYY') }</th>
          </tr>
          <tr  style={{ textAlign: "center", border: "1px solid #ccc" }}>
            <th scope="col" style={{border : '1px solid #ccc'}}>Mode</th>             
            { (props.lastDate)  ? Array(props.lastDate).fill(0).map((e, i) => ( <th key={i} style={{border : '1px solid #ccc'}}> {i + 1}</th>)) : '' }       

          </tr>
        </thead>


        <tbody className="width scroll-table " style={{ border: "1px solid grey" }}>
{ Array.isArray(props.dateWiseData)
  ? props.dateWiseData.map( (d:any, index:any) =>{
  return <tr key={index + 500} style={{textAlign: "center", border : '1px solid #ccc'}}>
<td style={{border : '1px solid #ccc', textAlign :"left"}}>
  {d.mode}
</td>
{
  d.dates.map((dates:any, ind:any) => {
   return <td key={ind + 1000} style={{border : '1px solid #ccc'}}>{dates} </td>
  })
}
  </tr>
} ) : []}       
        </tbody>

      </table>
  )
}

export default DatewiseTable