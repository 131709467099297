import { useEffect, useState } from 'react'
import MasterHeader from '../masterHeader'
import locationService from '../../../services/locationData.service'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Select from 'react-select'
import LocationHeader from './locationHeader'
import MasterDataService from '../../../services/masterData.service'

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    // none of react-select's styles are passed to <Control />
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}
const CityMaster = () => {

  const [cityName, setcityName] = useState("");
  const [stateTableId, setstateTableId] = useState(0);
  const [stateLabel, setstateLabel] = useState("");
  const [allCountry, setAllCountry] = useState([])
  const [countryTableId, setcountryTableId] = useState(0);
  const [countryName, setcountryName] = useState('');

  const [statevalue, setstatevalue] = useState([]);

  const { state } = useLocation();
  const getCountry = async () => {
    await locationService.getCountry().then(
      async (data) => {
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.countryName
        }))
        await setAllCountry(options)
        if (state === null) {
          await setcountryTableId(data[0]['id'])
          setcountryName(data[0]['countryName'])
        } else {
          let countryName = data.find((item: any) => {
            return item.id === countryTableId
          });
          if (countryName !== undefined) {
            setcountryName(countryName.countryName)
          }
        }

      }
    )
  }
  // get state values
  const getState = async (value: Number) => {
    if (value !== 0) {
      await MasterDataService.getState(value).then(
        (data) => {
          const statesList = data.map((d: any) => ({
            "value": d.id,
            "label": d.stateName
          }))
          setstatevalue(statesList)
          if (state === null || stateTableId === 0) {
            if (data[0]) {
              setstateTableId(data[0]['id']);
              setstateLabel(data[0]['stateName'])
            }
          } else {
            let stateNames = data.find((item: any) => {
              return item.id === stateTableId
            });
            if (stateNames !== undefined) {
              setstateLabel(stateNames.stateName)
            }
          }
        }
      )
    }
  }
  useEffect(() => {
    getCountry();
  }, [])
  useEffect(() => {
    getState(countryTableId);
  }, [countryTableId])




  const navigate = useNavigate();

  const HandleSubmit = () => {
    if(cityName !==""){
    locationService.postCity(cityName, stateTableId, countryTableId).then(
      (data) => {
        if (data) {
          navigate('../citylist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })} else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: "Please Enter City Name",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: 'swal-fire-alert', // Add your custom CSS class here
        },
      })
    }
  }
  // edit the selected data
  const HandleEdit = () => {
    if(cityName !== ""){
    locationService.putCity(cityName, stateTableId, countryTableId, state).then(
      (data) => {
        if (data) {
          navigate('../citylist');
        }
      }
    ).catch((error) => {
      console.log(error);
    }) }
    else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: "Please Enter City Name",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: 'swal-fire-alert', // Add your custom CSS class here
        },
      }).then(
        ()=>{
          navigate('../city', { state: state });
        }
      );
    }
  }
  //  get city by Id
  const getCityById = async () => {
    if (state) {
      try {
        const data = await locationService.getCityById(state);
        if (data) {
          setcityName(data.cityName);
          setstateTableId(data.stateTableId);
          setcountryTableId(data.countryTableId);
        } else {
          setcityName('');
          setstateTableId(0);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          locationService.deleteCity(state).then(
            (data) => {
              if (data.statuscode.message === '200') {
              }
            }
          ).catch((error) => {
            console.log(error);
            // console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "City has been deleted",
          }).then(function () {
            navigate('/citylist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "City is safe :)",
            }).then(function () {
              navigate('/citymaster');
            }
            )
        }
      })
  }

  const HandleCancel = () => {
    setcityName("");
    setstateTableId(0);
    setcountryTableId(0)
  }
  useEffect(() => {
    if (state !== null) {
      getCityById();
    }

  }, [])

  const handleCountryChange = async (e: any) => {
    await setcountryTableId(e.value)
    await setcountryName(e.label)
  }
  const handleStateChange = async (e: any) => {
    await setstateLabel(e.label)
    await setstateTableId(e.value)
  };
  return (
    <>
      <MasterHeader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>City Details </p></div>
          <LocationHeader />
          <div className='col-sm-12 inputs'>
            <label className='col-6 col-sm-2'><b> Country </b></label>
            <Select
              name="countryTableId"
              onChange={handleCountryChange}
              options={allCountry}
              className='col-8 col-sm-4 select'
              styles={customStyles}
              value={{ value: countryTableId, label: countryName }}
            />
          </div>
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b> State </b></label>
            <Select
              name="stateName"
              onChange={handleStateChange}
              options={statevalue}
              className='col-8 col-sm-4 select'
              styles={customStyles}
              value={{ label: stateLabel, value: stateTableId }}
            />
          </div>
          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b>City Name</b></label>
            <input type='text' className='col-8 col-sm-4' value={cityName} name='cityName' onChange={e => setcityName(e.target.value)} />
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to="../citylist"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="../citylist"> <i className="fa fa-edit"> </i>   Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i>   Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/citylist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default CityMaster
