import React, { useState, useCallback } from "react";
import { BarChart, Bar, Cell, LabelList, ResponsiveContainer } from "recharts";
import '../dashboard.css';

export default function OutwardStatusBarChart(props: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const data = props.dataforBarChart
  .filter((d: any) => d.value !== null && d.value !== 0 && d.value !== "")
  .map((d: any) => ({
    name: d.name,
    value: d.value
  }));
  const activeItem = data ? data[activeIndex] : {};
  const handleClick = useCallback(
    (entry: any, index: number) => {
      setActiveIndex(index);
    },
    []
  );

  return (
    <div className="chart-container">
      <p className="chart-title">Outward Status Of {props.todaysDate}</p>
      <ResponsiveContainer width="100%" height={280}>
        <BarChart data={data}  barSize={100}>
          <Bar dataKey="value" onClick={handleClick}>
            {data.map((_entry: any, index: number) => (
              <Cell
                cursor="pointer"
                fill={index === activeIndex ? "rgb(94, 83, 83)" : "#db1c29"}
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {(activeItem && activeItem.value !== null && activeItem.value !== 0 && activeItem.value !== "") && (
        <p className="content dash-head1">
          Active Item: <span>{activeItem.name}</span>
          <span className="value">Value: {activeItem.value}</span>
        </p>
      )}
    </div>
  );
}
