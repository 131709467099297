import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
// const createdBy = localStorage.getItem('userId');
// const updatedBy = localStorage.getItem('userId');
// const mailRoomId = localStorage.getItem('mailRoomId');
// const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";

const config = {
    headers: {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
};

const postadminMaterial = async (adminMaterialData : any )=>{
    const chunkSize = 100; // Set the desired chunk size for each iteration
    const totalChunks = Math.ceil(adminMaterialData.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
    const startIndex = i * chunkSize;
    const endIndex = Math.min((i + 1) * chunkSize, adminMaterialData.length);
    const chunkData = adminMaterialData.slice(startIndex, endIndex);

    // Create a request payload with the current chunk data
    const requestData = {
         value:chunkData,
    };
    try {
        // Send the POST request to the server using Axios
         await axios.post(API_URL + "adminmaterial/register", requestData, config );
        // Handle the response from the server if needed
    } catch (error) {
        // Handle errors if the request fails
        console.error(error);
    }
    }   
return 
}

const getallmaterialadminData = async ()=>{
      return axios.get(API_URL + "adminmaterial/",  config).then(
        (response) => {
            return response.data.message           
        }
    )
        .catch(error => console.log(error))
}

const OutwardAdminMaterialService = {
    postadminMaterial,
    getallmaterialadminData

}

export default OutwardAdminMaterialService